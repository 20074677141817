/** @jsx jsx */
import { jsx } from '@emotion/core'
import styled from '@emotion/styled'
import { FunctionComponent } from 'react'
import { IconButton } from '../../../components/Icon'
import { Info } from '../../../components/Info'
import { COLOR_PALETTE, mqMax } from '../../../GlobalStyle'

const GreyLabel = styled.label`
    font-size: 12px;
    color: ${COLOR_PALETTE.grey_dark};
`

const InfoWrapper = styled.div`
    display: grid;
    position: relative;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 0;
    grid-row-gap: 24px;
    padding: 6px;
    ${mqMax[1]} {
        display: flex;
        flex-direction: column;
    }
`

const AbsoluteButton = styled.span`
    position: absolute;
    top: -16px;
    right: -22px;
    ${mqMax[1]} {
        display: none;
    }
`

const MarginWrapper = styled.div`
    margin-bottom: 24px;
`

type ContentTextProps = {
    color?: string
    bold?: boolean
}
const ContentText = styled.div<ContentTextProps>`
    font-size: 14px;
    color: ${props => (props.color ? props.color : COLOR_PALETTE.black)};
    margin-top: 2px;
    font-weight: ${props => (props.bold ? 500 : 400)};
    ${mqMax[1]} {
        font-weight: 500;
    }
`

type GridItemProps = {
    gridArea: string
    isHiddenOnMobile?: boolean
}
const GridItem = styled.div<GridItemProps>`
    display: flex;
    flex-direction: column;
    grid-area: ${props => props.gridArea};
`

const EmployeeInfoBox: FunctionComponent = () => {
    return (
        <MarginWrapper>
            <Info>
                <InfoWrapper>
                    <GridItem gridArea={'1 / 1 / 2 / 2'}>
                        <GreyLabel>Current Role</GreyLabel>
                        <ContentText>Junior Frontend Developer</ContentText>
                    </GridItem>
                    <GridItem gridArea={'1 / 2 / 2 / 3'}>
                        <GreyLabel>Seniority</GreyLabel>
                        <ContentText>Junior</ContentText>
                    </GridItem>
                    <GridItem gridArea={'1 / 3 / 2 / 4'}>
                        <GreyLabel>Type</GreyLabel>
                        <ContentText>Candidate</ContentText>
                    </GridItem>
                    <GridItem gridArea={'1 / 4 / 2 / 5'}>
                        <GreyLabel>Country</GreyLabel>
                        <ContentText>USA</ContentText>
                    </GridItem>
                    <GridItem gridArea={'2 / 1 / 3 / 2'}>
                        <GreyLabel>City</GreyLabel>
                        <ContentText>New York</ContentText>
                    </GridItem>
                    <GridItem gridArea={'2 / 2 / 3 / 3'}>
                        <GreyLabel>Remote</GreyLabel>
                        <ContentText bold={true}>Yes</ContentText>
                    </GridItem>
                    <GridItem gridArea={'2 / 3 / 3 / 5'}>
                        <GreyLabel>Email</GreyLabel>
                        <ContentText>bartek.radziejewski@talent-alpha.com</ContentText>
                    </GridItem>
                    <GridItem gridArea={'3 / 1 / 4 / 3'}>
                        <GreyLabel>Top Skills</GreyLabel>
                        <ContentText color={COLOR_PALETTE.grey}>Awaiting Skills Discovery Result</ContentText>
                    </GridItem>
                    <AbsoluteButton>
                        <IconButton name='edit' size={32} />
                    </AbsoluteButton>
                </InfoWrapper>
            </Info>
        </MarginWrapper>
    )
}

export { EmployeeInfoBox }
