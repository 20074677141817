/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import styled from '@emotion/styled'
import { Fragment, FunctionComponent, useEffect, useState } from 'react'
import { ContactSalesModal } from '../../../components/profile/ContactSalesModal'
import { Experience } from '../../../components/profile/Experience'
import { Languages } from '../../../components/profile/Languages'
import { MoreInfo } from '../../../components/profile/MoreInfo'
import { ProfileHeader } from '../../../components/profile/ProfileHeader'
import { ShareProfileModal } from '../../../components/profile/ShareProfileModal'
import { TechnicalSkills } from '../../../components/profile/TechnicalSkills'
import { Profile } from '../../../contracts/profile/profile'
import { COLOR_PALETTE, mqMax } from '../../../GlobalStyle'
import { Nullable } from '../../../types'

const PROFILE_MAX_WIDTH = 816

const ContentContainerStyles = css`
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 28px 0;

    ${mqMax[2]} {
        padding: 28px 16px;
    }
`

const GreyContentContainer = styled.section`
    ${ContentContainerStyles}
    background-color: ${COLOR_PALETTE.grey_lighter};
    border-top: 1px solid ${COLOR_PALETTE.grey_light};
    border-bottom: 1px solid ${COLOR_PALETTE.grey_light};
`

const WhiteContentContainer = styled.section`
    ${ContentContainerStyles}
`

const ContentWrapper = styled.div`
    max-width: ${PROFILE_MAX_WIDTH + 'px'};
    width: 100%;
`

type SpecialistProfileProps = {
    specialistProfile: Nullable<Profile>
    isPublic?: boolean
    shareProfileLinkId?: string
    setRefetchProfile?: (val: boolean) => void
    setLoading?: (val: boolean) => void
}

const SpecialistProfile: FunctionComponent<SpecialistProfileProps> = ({
    specialistProfile,
    isPublic = false,
    shareProfileLinkId,
    setRefetchProfile,
    setLoading,
}) => {
    const [profile, setProfile] = useState<Nullable<Profile>>(specialistProfile)
    const [contactModal, setContactModal] = useState<boolean>(false)
    const [shareModal, setShareModal] = useState<boolean>(false)
    const [shareLinkId, setShareLinkId] = useState<string>('')

    useEffect(() => {
        if (shareProfileLinkId) {
            setShareLinkId(shareProfileLinkId)
        }
    }, [shareProfileLinkId])

    useEffect(() => {
        setProfile(specialistProfile)
    }, [specialistProfile])

    return (
        <Fragment>
            <GreyContentContainer>
                <ContentWrapper>
                    {profile && (
                        <ProfileHeader
                            profile={profile}
                            setShareModal={setShareModal}
                            setContactModal={setContactModal}
                            isPublic={isPublic}
                            setLoading={setLoading}
                        />
                    )}
                </ContentWrapper>
            </GreyContentContainer>

            <WhiteContentContainer>
                <ContentWrapper>{profile && <MoreInfo profile={profile} />}</ContentWrapper>
            </WhiteContentContainer>

            <WhiteContentContainer>
                <ContentWrapper>{profile && <TechnicalSkills profile={profile} />}</ContentWrapper>
            </WhiteContentContainer>

            <GreyContentContainer>
                <ContentWrapper>{profile && <Experience profile={profile} />}</ContentWrapper>
            </GreyContentContainer>

            <WhiteContentContainer>
                <ContentWrapper>{profile && <Languages profile={profile} />}</ContentWrapper>
            </WhiteContentContainer>

            {shareLinkId.length && <ShareProfileModal shareLinkId={shareLinkId} onClose={() => setShareModal(false)} opened={shareModal} />}
            <ContactSalesModal
                profile={profile}
                onClose={() => setContactModal(false)}
                opened={contactModal}
                setRefetchProfile={setRefetchProfile}
            />
        </Fragment>
    )
}

export { SpecialistProfile }
