import { DependencyList, EffectCallback, useCallback, useEffect } from 'react'

const useDebouncedEffect = (effect: EffectCallback, delay: number, deps: DependencyList) => {
    const callback = useCallback(effect, deps)

    useEffect(() => {
        const handler = setTimeout(() => {
            callback()
        }, delay)

        return () => {
            clearTimeout(handler)
        }
    }, [callback, delay])
}

export { useDebouncedEffect }
