/** @jsx jsx */
import { jsx } from '@emotion/core'
import styled from '@emotion/styled'
import { useEffect, useRef, useState } from 'react'
import { COLOR_PALETTE, mqMax } from './../../GlobalStyle'

const DropdownContainer = styled.div`
    position: absolute;
    top: 48px;
    left: 0;
    background-color: ${COLOR_PALETTE.white};
    border: 1px solid ${COLOR_PALETTE.grey_light};
    z-index: 300;
    border-radius: 2px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);

    ${mqMax[1]} {
        width: 100%;
        position: relative;
        top: 8px;
    }
`

const DropdownDivider = styled.div`
    width: calc(100% - 36px);
    margin: 0 auto;
    height: 1px;
    background-color: ${COLOR_PALETTE.grey_light};
`

const DropdownComponent = (InnerComponent: any) => (props: any) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const rangeRef = useRef<HTMLDivElement>()
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [isOpen, setIsOpen] = useState(false)

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside)

        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen])

    const handleClickOutside = (event: MouseEvent) => {
        if (isOpen && rangeRef && !(rangeRef as { current: HTMLDivElement }).current.contains(event.target as Node)) {
            setIsOpen(false)
        }
    }

    return <InnerComponent {...props} rangeRef={rangeRef} isOpen={isOpen} setIsOpen={setIsOpen} />
}

export { DropdownComponent, DropdownContainer, DropdownDivider }
