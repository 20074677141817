/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import styled from '@emotion/styled'
import { COLOR_PALETTE } from '../GlobalStyle'
import { Icon } from './Icon'

const NoDataContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`

const GreyText = styled.p`
    color: ${COLOR_PALETTE.grey};
    margin-top: 12px;
`

const NoData = () => {
    return (
        <NoDataContainer>
            <Icon
                name='no-data'
                style={css`
                    height: 28px;
                    width: 28px;
                `}
            />
            <GreyText>No Data Available</GreyText>
        </NoDataContainer>
    )
}

export { NoData }
