/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { FunctionComponent } from 'react'
import { Position } from '../../contracts/profile/position'
import { COLOR_PALETTE, mqMax } from '../../GlobalStyle'
import { randomString } from '../../utils/strings'
import { TimelineEntry } from './TimelineEntry'

type TimelineGroupProps = { companyName: string; positions: Array<Position> }

const TimelineGroup: FunctionComponent<TimelineGroupProps> = ({ companyName, positions }) => {
    return (
        <div
            css={css`
                margin-top: 24px;
                display: flex;
                ${mqMax[1]} {
                    flex-direction: column;
                }
            `}
        >
            <div
                css={css`
                    color: ${COLOR_PALETTE.grey_dark};
                    border-right: 2px solid ${COLOR_PALETTE.grey_light};
                    padding-top: 26px;
                    flex: 0 1 25%;
                    ${mqMax[1]} {
                        border-right: 0;
                        padding-top: 0;
                        padding-bottom: 22px;
                    }
                `}
            >
                {companyName}
            </div>
            <div
                css={css`
                    flex: 1 1 75%;
                `}
            >
                {positions.map(position => (
                    <div
                        key={randomString()}
                        css={css`
                            display: flex;
                        `}
                    >
                        <div
                            css={css`
                                height: 14px;
                                width: 14px;
                                background-color: ${COLOR_PALETTE.grey_light};
                                border-radius: 50%;
                                display: inline-block;
                                margin-top: 26px;
                                margin-left: -8px;
                                ${mqMax[1]} {
                                    display: none;
                                }
                            `}
                        ></div>
                        <TimelineEntry
                            position={position}
                            css={css`
                                flex: 1;
                                ${mqMax[1]} {
                                    margin-left: 0;
                                }
                            `}
                        />
                    </div>
                ))}
            </div>
        </div>
    )
}

export { TimelineGroup }
