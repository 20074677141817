/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import styled from '@emotion/styled'
import { FunctionComponent } from 'react'
import { EmployeeProject } from '../../contracts/employees/employeeProject'
import { COLOR_PALETTE } from '../../theme/colors'
import { convertTimestampToDateString } from '../../utils/dates'
import { Divider } from '../Divider'
import { IconButton } from '../Icon'
import { FlexBox } from '../layout/FlexBoxHelpers'

const DetailRow = styled.div`
    display: flex;
    margin-bottom: 16px;
`

const DetailKey = styled.div`
    flex: 1;
    max-width: 120px;
    color: ${COLOR_PALETTE.grey};
`

const DetailValue = styled.div`
    display: flex;
    flex: 3;
`

const DateString = styled.p`
    background-color: ${COLOR_PALETTE.grey_lighter};
    border-radius: 55px;
    padding: 0 8px;
    margin: 0 20px 0 0;
`

type ProjectBoxProps = {
    project: EmployeeProject
    onEditClicked: (projectId: string) => void
    onDeleteClicked: (projectId: string) => void
}

const ProjectBox: FunctionComponent<ProjectBoxProps> = ({ project, onEditClicked, onDeleteClicked }) => {
    return (
        <div
            css={css`
                max-width: 544px;
                padding: 24px;
                border: 1px solid ${COLOR_PALETTE.grey_light};
                margin-bottom: 16px;
            `}
        >
            <FlexBox alignItems='center'>
                <DetailKey>Role:</DetailKey>
                <DetailValue>
                    <p
                        css={css`
                            font-weight: 500;
                        `}
                    >
                        {project.role}
                    </p>
                </DetailValue>
                <div>
                    <IconButton name='edit' onClick={() => onEditClicked(project.id)} />
                    <IconButton name='trash' onClick={() => onDeleteClicked(project.id)} />
                </div>
            </FlexBox>

            <Divider />

            {project.from && (
                <DetailRow>
                    <DetailKey>From:</DetailKey>
                    <DetailValue>
                        <FlexBox>
                            <DateString>{convertTimestampToDateString(project.from, 'dd.MM.yyyy')}</DateString>
                            <DetailKey
                                css={css`
                                    margin-right: 20px;
                                `}
                            >
                                To:
                            </DetailKey>
                            <DateString>{convertTimestampToDateString(project.to, 'dd.MM.yyyy')}</DateString>
                        </FlexBox>
                    </DetailValue>
                </DetailRow>
            )}

            {project.project && (
                <DetailRow>
                    <DetailKey>Project:</DetailKey>
                    <DetailValue>{project.project}</DetailValue>
                </DetailRow>
            )}

            {project.industry && (
                <DetailRow>
                    <DetailKey>Industry:</DetailKey>
                    <DetailValue>{project.industry}</DetailValue>
                </DetailRow>
            )}

            {project.stack.length > 0 && (
                <DetailRow>
                    <DetailKey>Stack:</DetailKey>
                    <DetailValue>{project.stack}</DetailValue>
                </DetailRow>
            )}

            {project.description && (
                <DetailRow>
                    <DetailKey>Description:</DetailKey>
                    <DetailValue>{project.description}</DetailValue>
                </DetailRow>
            )}

            {project.country && (
                <DetailRow>
                    <DetailKey>Country:</DetailKey>
                    <DetailValue>{project.country}</DetailValue>
                </DetailRow>
            )}

            {project.company && (
                <DetailRow>
                    <DetailKey>Company:</DetailKey>
                    <DetailValue>{project.company}</DetailValue>
                </DetailRow>
            )}
        </div>
    )
}

export { ProjectBox }
