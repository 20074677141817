import { TalentProfileProject } from '../contracts/TalentProfileResponse'

const getStringFromArray = (list: Array<string>): string => {
    return list && list.length ? list.join(', ') : '-'
}

const getRecentValues = (projects: Array<TalentProfileProject>, key: string, maxNumberOfItems = 3): string => {
    const results: Array<string> = []

    for (const project of projects) {
        // @ts-ignore
        if (project[key] instanceof Array) {
            // @ts-ignore
            if (serveArray(project[key], results, maxNumberOfItems)) {
                break
            }
        } else {
            // @ts-ignore
            if (serveItem(project[key], results, maxNumberOfItems)) {
                break
            }
        }
    }

    return getStringFromArray(results)
}

const serveArray = (array: Array<string>, results: Array<string>, maxNumberOfItems: number): boolean => {
    for (const item of array) {
        if (serveItem(item, results, maxNumberOfItems)) {
            return true
        }
    }

    return false
}

const serveItem = (item: string, results: Array<string>, maxNumberOfItems: number): boolean => {
    if (item && results.indexOf(item) === -1) {
        results.push(item)
    }

    return results.length >= maxNumberOfItems
}

export type GroupedItems<T> = { key: string; items: Array<T> };

const groupArrayByKey = <T> (array: Array<T>, key: string): Array<GroupedItems<T>> => {
    return array.reduce((grouped, item) => {
        let group: GroupedItems<T>
        const filtered = grouped.filter(g => g.key === (item as any)[key])
        if (filtered.length > 0) {
            group = filtered[0]
        } else {
            group = {
                key: (item as any)[key],
                items: [],
            }
            grouped.push(group)
        }

        group.items.push(item)

        return grouped
    }, [] as Array<GroupedItems<T>>)
}

export { getStringFromArray, getRecentValues, groupArrayByKey }
