/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { getCountries, getUser, updateUser } from '../../api/api'
import { Button } from '../../components/Button'
import { Divider } from '../../components/Divider'
import { Input } from '../../components/Input'
import { FieldWrapper } from '../../components/layout/FormHelpers'
import { FirstColumn, ResponsiveColumns, SecondColumn } from '../../components/layout/ResponsiveColumns'
import { SettingsWrapper } from '../../components/layout/ResponsiveWrapper'
import { useNotifications } from '../../components/notification/NotificationProvider'
import { PhoneInput } from '../../components/PhoneInput'
import { Country } from '../../contracts/country'
import { mqMax } from '../../GlobalStyle'
import { ReduxContext } from '../../redux/Store'
import { Nullable } from '../../types'
import { resolveFieldError } from '../../utils/errors'
import { phoneRegex } from '../../utils/regexes'

const validRequiredPhoneNumber = 'Phone with area code required'
const schema = yup.object().shape({
    firstName: yup.string().required('Required'),
    lastName: yup.string().required('Required'),
    phone: yup.string().matches(phoneRegex, validRequiredPhoneNumber).required(validRequiredPhoneNumber),
})

const AccountPersonalDetails = () => {
    const {
        selectors: { user },
    } = useContext(ReduxContext)
    const [countries, setCountries] = useState<Array<Country>>([])
    const [userData, setUserData] = useState<Nullable<any>>(null)
    const { register, handleSubmit, errors, formState, reset } = useForm({
        validationSchema: schema,
    })
    const { addSuccess, addError } = useNotifications()

    useEffect(() => {
        const runEffect = async () => {
            const countriesData = await getCountries()
            setCountries(countriesData)
            if (user?.uid) {
                const data = await getUser(user.firebaseId)
                setUserData(data)
                reset(data)
            }
        }
        runEffect().finally()
    }, [user, reset])

    const onSubmit = async (formData: any) => {
        const newData = {
            id: user.uid,
            email: user.email,
            firebaseId: user.firebaseId,
            firstName: formData.firstName,
            lastName: formData.lastName,
            phone: formData.phone,
        }
        try {
            const { firstName, lastName, phone } = await updateUser(user.firebaseId, newData) // lodash.pick here?
            setUserData({ firstName, lastName, phone })
            addSuccess('User data updated!')
        } catch (e) {
            addError('Something went wrong :-/')
        }
    }

    return (
        <SettingsWrapper>
            <form onSubmit={handleSubmit(onSubmit)}>
                <h4>Personal Details</h4>
                <Divider />
                <ResponsiveColumns>
                    <FirstColumn>
                        <FieldWrapper>
                            <Input
                                name='firstName'
                                placeholder=''
                                label='First Name'
                                innerRef={register}
                                errorText={resolveFieldError(errors.firstName)}
                            />
                        </FieldWrapper>
                    </FirstColumn>
                    <SecondColumn>
                        <FieldWrapper>
                            <Input
                                name='lastName'
                                placeholder=''
                                label='Last Name'
                                innerRef={register}
                                errorText={resolveFieldError(errors.lastName)}
                            />
                        </FieldWrapper>
                    </SecondColumn>
                </ResponsiveColumns>
                <FieldWrapper>
                    <PhoneInput
                        countryAreaCodes={countries}
                        name='phone'
                        label='Phone'
                        placeholder='Phone number'
                        type='text'
                        innerRef={register}
                        errorText={resolveFieldError(errors.phone)}
                        defaultValue={userData?.phone}
                    />
                </FieldWrapper>
                <FieldWrapper>
                    <Input
                        name='accessRole'
                        placeholder=''
                        label='Your Access'
                        tooltip='This is your current access level'
                        value='Basic User'
                        disabled
                    />
                </FieldWrapper>
                <Divider />
                <Button
                    variant='primary'
                    type='submit'
                    disabled={formState.isSubmitting}
                    css={css`
                        ${mqMax[1]} {
                            width: 100%;
                        }
                    `}
                >
                    Save Details
                </Button>
            </form>
        </SettingsWrapper>
    )
}

export { AccountPersonalDetails }
