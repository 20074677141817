/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { COLOR_PALETTE } from '../GlobalStyle'
import clouds from './../assets/images/clouds.png'

const YellowClouds = ({ children }: { children: any }) => {
    return (
        <div
            css={css`
                display: flex;
                background-color: ${COLOR_PALETTE.yellow};
                flex-direction: column;
                justify-content: center;
                align-items: center;
                position: relative;

                height: 100%;
            `}
        >
            <div
                css={css`
                    width: 100%;
                    background: url(${clouds});
                    background-size: cover;
                    background-position: bottom;
                    background-repeat: no-repeat;
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 1;
                    height: 100%;
                `}
            ></div>

            <div
                css={css`
                    width: 100%;
                    background-color: ${COLOR_PALETTE.yellow};
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 2;
                    opacity: 0.1;
                    height: 100%;
                `}
            ></div>

            <div
                css={css`
                    position: relative;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    z-index: 3;
                `}
            >
                {children}
            </div>
        </div>
    )
}

export { YellowClouds }
