/** @jsx jsx */
import { jsx } from '@emotion/core'
import styled from '@emotion/styled'
import moment from 'moment'
import { Fragment, FunctionComponent } from 'react'
import { COLOR_PALETTE } from '../../../GlobalStyle'
import { PersonalData } from './PersonalTab'

const GreyLabel = styled.label`
    font-size: 12px;
    color: ${COLOR_PALETTE.grey_dark};
    line-height: 22px;
`
type ContentTextProps = {
    color?: string
}

const ContentText = styled.div<ContentTextProps>`
    font-size: 14px;
    color: ${props => (props.color ? props.color : COLOR_PALETTE.black)};
    margin-bottom: 24px;
`

type PersonalDataProps = {
    personalData: PersonalData
}

const PersonalTabReadOnly: FunctionComponent<PersonalDataProps> = ({ personalData }) => {
    const { birthDate, gender, city, country, street, phone, postalCode, socialLinks } = personalData
    const birthDateString = moment.unix(birthDate).format('MM/DD/YYYY')
    return (
        <div>
            <h6>Personal Information</h6>
            <GreyLabel>Phone</GreyLabel>
            <ContentText>{phone}</ContentText>
            <GreyLabel>Birth Date</GreyLabel>
            <ContentText>{birthDateString}</ContentText>
            <GreyLabel>Gender (optional)</GreyLabel>
            <ContentText>{gender}</ContentText>
            <h6>Home Adress (optional)</h6>
            <GreyLabel>Country</GreyLabel>
            <ContentText>{country}</ContentText>
            <GreyLabel>City</GreyLabel>
            <ContentText>{city}</ContentText>
            <GreyLabel>Street No.</GreyLabel>
            <ContentText>{street}</ContentText>
            <GreyLabel>Postal</GreyLabel>
            <ContentText>{postalCode}</ContentText>
            <h6>Social Links (optional)</h6>
            {socialLinks.map(({ label, url }) => {
                return (
                    <Fragment key={url}>
                        <GreyLabel>{label}</GreyLabel>
                        <ContentText>
                            <a href={url}>{url}</a>
                        </ContentText>
                    </Fragment>
                )
            })}
        </div>
    )
}

export { PersonalTabReadOnly }
