/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { COLOR_PALETTE } from '../../GlobalStyle'
import { Icon } from '../Icon'

const FilterBox = ({
    placeholder,
    displayedValue,
    onClick,
    isOpen,
    styles,
}: {
    placeholder: string
    onClick: () => void
    displayedValue?: string
    isOpen?: boolean
    styles?: string
}) => {
    return (
        <div
            onClick={onClick}
            css={css`
                height: 40px;
                border: 1px solid ${COLOR_PALETTE.grey_light};
                border-radius: 2px;
                padding: 0 16px;
                font-weight: 400;
                font-size: 14px;
                line-height: 24px;
                position: relative;
                background-color: ${COLOR_PALETTE.white};
                display: flex;
                align-items: center;
                justify-content: space-between;
                cursor: pointer;
                color: ${displayedValue ? COLOR_PALETTE.black : COLOR_PALETTE.grey};

                ${styles}
            `}
        >
            <span
                css={css`
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    max-width: 100%;
                `}
            >
                {displayedValue || placeholder}
            </span>
            <Icon
                name='arrow-down'
                css={css`
                    margin-left: 12px;
                    font-size: 24px;
                    color: ${COLOR_PALETTE.black};

                    &.opened {
                        transform: rotate(180deg);
                    }
                `}
            />
        </div>
    )
}

export { FilterBox }
