/** @jsx jsx */
import { css, jsx, SerializedStyles } from '@emotion/core'
import { FunctionComponent } from 'react'
import { COLOR_PALETTE } from '../../../GlobalStyle'

type HeaderBoxProps = {
    isActive?: boolean
    onClick?: () => void
    style?: SerializedStyles
}

const HeaderBox: FunctionComponent<HeaderBoxProps> = ({ children, isActive = false, onClick = () => null, style = {} }) => {
    return (
        <div
            onClick={onClick}
            css={css`
                height: 56px;
                padding: 0 8px;
                min-width: 60px;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;

                &:before {
                    content: '';
                    display: none;
                    position: absolute;
                    top: 0;
                    left: 8px;
                    right: 8px;
                    height: 4px;
                    background-color: ${COLOR_PALETTE.yellow};
                }

                ${isActive &&
                ` {
                    &:before {
                        display: block;
                    }
                `}

                &:hover {
                    border-top-color: ${COLOR_PALETTE.yellow};
                    cursor: ${isActive ? 'default' : 'pointer'};
                    &:before {
                        display: block;
                    }
                }

                ${style}
            `}
        >
            {children}
        </div>
    )
}

export { HeaderBox }
