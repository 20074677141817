import React, { Fragment, FunctionComponent, useCallback, useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { getCompanies } from '../../api/api'
import { Button } from '../../components/Button'
import { Divider } from '../../components/Divider'
import { FlexButtons } from '../../components/layout/FlexBoxHelpers'
import { Modal } from '../../components/Modal'
import { ReduxContext } from '../../redux/Store'

const TalentManagementGuard: FunctionComponent = ({ children }) => {
    const {
        selectors: { firebaseId },
    } = useContext(ReduxContext)
    const [pending, setPending] = useState(true)
    const [verifiedCompany, setVerifiedCompany] = useState(false)
    const history = useHistory()

    useEffect(() => {
        if (firebaseId) {
            getCompanies(firebaseId).then((companies: Array<any>) => {
                if (companies && companies.length && companies[0].status === 'VERIFIED') {
                    setVerifiedCompany(true)
                } else {
                    setVerifiedCompany(false)
                }
                setPending(false)
            })
        }
    }, [firebaseId])

    const goToSettings = useCallback(() => history.push('/dashboard/company-account/company-details'), [history])
    const goToDashboard = useCallback(() => history.push('/dashboard'), [history])
    const goBack = useCallback(() => history.goBack(), [history])

    return (
        <Fragment>
            {children}
            {!pending && !verifiedCompany && (
                <Modal opened onClose={goToDashboard} maskClosable={false}>
                    <h5>Do you want access to Talent Management App?</h5>
                    <p>Create Company Account first. Please go to Settings and fill in your Company's details.</p>
                    <Divider />
                    <FlexButtons justifyContent='flex-end'>
                        <Button variant='text' onClick={goBack}>
                            Go back
                        </Button>
                        <Button onClick={goToSettings}>Go to Settings</Button>
                    </FlexButtons>
                </Modal>
            )}
        </Fragment>
    )
}

export { TalentManagementGuard }
