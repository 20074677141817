/** @jsx jsx */
import { css, jsx, SerializedStyles } from '@emotion/core'
import styled from '@emotion/styled'
import { ChangeEvent, FunctionComponent, useEffect, useState } from 'react'
import { COLOR_PALETTE } from '../GlobalStyle'
import { Icon } from './Icon'
import { ErrorText } from './inputs/ErrorText'
import { Tooltip, TooltipIcon } from './Tooltip'

export type InputProps = {
    name: string
    onChange?: (value: string) => void
    value?: string
    errorText?: string
    label?: string
    placeholder?: string
    type?: string
    hasPassword?: boolean
    disabled?: boolean
    style?: SerializedStyles
    defaultValue?: string
    innerRef?: any
    tooltip?: string
}

const Input: FunctionComponent<InputProps> = ({
    name,
    onChange,
    value = '',
    errorText,
    label,
    placeholder,
    type = 'text',
    hasPassword = false,
    disabled = false,
    style,
    innerRef,
    tooltip,
    defaultValue,
}) => {
    const [valueState, setValueState] = useState(value)
    const [inputType, setInputType] = useState(type)

    useEffect(() => {
        setValueState(value)
    }, [value])

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setValueState(event.target.value)

        if (onChange) {
            onChange(event.target.value)
        }
    }

    const controlProps = !innerRef
        ? {
              value: valueState,
              onChange: handleChange,
          }
        : {}

    const togglePasswordVisibility = () => {
        if (inputType === 'password') {
            setInputType('text')
        } else {
            setInputType('password')
        }
    }

    const addErrorStyles = () => {
        return errorText
            ? css`
                  input {
                      border: 1px solid ${COLOR_PALETTE.red};
                  }
              `
            : null
    }

    return (
        <div
            css={[
                css`
                    display: flex;
                    flex-direction: column;
                    position: relative;

                    ${style}
                `,
                addErrorStyles(),
            ]}
        >
            {label && (
                <InputLabel>
                    {label}{' '}
                    {tooltip && (
                        <Tooltip content={tooltip}>
                            <TooltipIcon>?</TooltipIcon>
                        </Tooltip>
                    )}
                </InputLabel>
            )}
            <input
                css={css`
                    width: 100%;
                    height: 40px;
                    border: 1px solid ${COLOR_PALETTE.grey_light};
                    border-radius: 2px;
                    padding: 0 16px;
                    outline: none;
                    color: ${COLOR_PALETTE.black};

                    &[disabled] {
                        background-color: ${COLOR_PALETTE.grey_lighter};
                        color: ${COLOR_PALETTE.grey};
                    }

                    &:focus {
                        border-color: ${COLOR_PALETTE.grey};
                    }
                    &::placeholder {
                        color: ${COLOR_PALETTE.grey};
                    }
                `}
                name={name}
                placeholder={placeholder}
                type={inputType}
                disabled={disabled}
                ref={innerRef}
                defaultValue={defaultValue}
                {...controlProps}
            />
            {hasPassword && (
                <div
                    css={css`
                        cursor: pointer;
                        position: absolute;
                        bottom: 7px;
                        right: 16px;
                    `}
                    onClick={togglePasswordVisibility}
                >
                    {inputType === 'password' && <Icon name='eye' />}
                    {inputType !== 'password' && <Icon name='eye-hide' />}
                </div>
            )}
            {errorText && <ErrorText>{errorText}</ErrorText>}
        </div>
    )
}

const InputLabel = styled.label`
    display: block;
    color: ${COLOR_PALETTE.grey_dark};
    margin-bottom: 4px;
`

export { Input, InputLabel }
