/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { FunctionComponent } from 'react'
import { COLOR_PALETTE } from '../GlobalStyle'

const Info: FunctionComponent = ({ children }) => {
    return (
        <div
            css={css`
                border-radius: 4px;
                background: ${COLOR_PALETTE.grey_lighter};
                padding: 26px 32px;
            `}
        >
            {children}
        </div>
    )
}

export { Info }
