/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Fragment } from 'react'
import { DashboardTile, DashboardTiles } from '../../../components/layout/dashboard/DashboardTiles'
import { AppTileConfig } from '../../../contracts/applications'

const tiles: Array<AppTileConfig> = [
    {
        to: '/playground',
        title: 'Available Application',
        subtitle: 'Available Now',
        hint: 'Click will redirect',
        icon: 'bench',
        status: 'available',
    },
    {
        to: '/playground?enable-application',
        title: 'Unavailable Application',
        subtitle: 'Get Access',
        hint: 'Click will also redirect',
        icon: 'user',
        status: 'unavailable',
    },
    {
        to: '/disabled-url',
        title: 'Disabled App',
        subtitle: 'Later feature',
        hint: 'Click will NOT redirect',
        icon: 'sales',
        status: 'disabled',
    },
]

const Tiles = () => {
    return (
        <Fragment>
            <h5>Tiles (Dashboard)</h5>
            <DashboardTiles>
                {tiles.map(tile => (
                    <DashboardTile key={tile.title} {...tile} />
                ))}
            </DashboardTiles>
        </Fragment>
    )
}

export { Tiles }
