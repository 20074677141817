/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Fragment, useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { signInWithGoogle, signInWithMicrosoft } from '../api/firebase'
import google from '../assets/logos/google.svg'
import linkedin_color from '../assets/logos/linkedin_color.svg'
import microsoft from '../assets/logos/microsoft.svg'
import { mqMax } from '../GlobalStyle'
import { ReduxContext } from '../redux/Store'
import { Button } from './Button'
import { Divider } from './Divider'

const AlternativeAuthMethods = () => {
    const history = useHistory()
    const {
        selectors: { userId },
    } = useContext(ReduxContext)

    useEffect(() => {
        if (userId) {
            history.push('/dashboard')
        }
    }, [userId, history])

    const handleMicrosoftSSO = async () => {
        await signInWithMicrosoft()
    }

    const handleGoogleSSO = async () => {
        await signInWithGoogle()
    }

    return (
        <Fragment>
            <Divider
                style={css`
                    margin-bottom: 16px;
                `}
                text='or continue with'
            />
            <div
                css={css`
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    ${mqMax[0]} {
                        flex-direction: column;
                    }
                `}
            >
                <Button
                    variant='sso'
                    onClick={handleMicrosoftSSO}
                    icon={microsoft}
                    style={css`
                        width: calc(50% - 5px);
                        ${mqMax[0]} {
                            margin-bottom: 16px;
                            width: 100%;
                        }
                    `}
                >
                    Microsoft
                </Button>
                <Button
                    variant='sso'
                    onClick={handleGoogleSSO}
                    icon={google}
                    style={css`
                        width: calc(50% - 5px);
                        ${mqMax[0]} {
                            margin-bottom: 16px;
                            width: 100%;
                        }
                    `}
                >
                    Google
                </Button>

                {false && (
                    <Button
                        variant='sso'
                        icon={linkedin_color}
                        style={css`
                            width: calc(33% - 5px);
                            ${mqMax[0]} {
                                margin-bottom: 16px;
                                width: 100%;
                            }
                        `}
                    >
                        LinkedIn
                    </Button>
                )}
            </div>
        </Fragment>
    )
}

export { AlternativeAuthMethods }
