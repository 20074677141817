/** @jsx jsx */
import { css, jsx, SerializedStyles } from '@emotion/core'
import { ButtonHTMLAttributes, FunctionComponent } from 'react'
import { COLOR_PALETTE } from '../GlobalStyle'
import { blackToGrayFilter, blackToRedFilter, blackToVioletDarkFilter, blackToWhiteFilter } from './Icon'

export type LinkButtonVariant = 'link' | 'linkDelete' | 'linkForm'
export type ButtonVariant = LinkButtonVariant | 'primary' | 'secondary' | 'tertiary' | 'talent' | 'delete' | 'sso' | 'text'
export type ButtonSize = 'medium' | 'small'

const ButtonBase = css`
    cursor: pointer;
    &[disabled] {
        cursor: not-allowed;
    }
`

const getSizeStyles = (size: ButtonSize, variant: ButtonVariant) => {
    const verticalPadding = size === 'small' ? 5 : 10
    let horizontalPadding = size === 'small' ? 8 : 16
    if (variant.includes('link')) {
        horizontalPadding = 0
    }
    return css`
        padding: ${verticalPadding}px ${horizontalPadding}px ${verticalPadding - 1}px;
    `
}

const ButtonBaseSolid = css`
    ${ButtonBase};
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px;

    &[disabled],
    &[disabled]:hover {
        background-color: ${COLOR_PALETTE.grey_light};
        border-color: ${COLOR_PALETTE.grey_light};
        color: ${COLOR_PALETTE.grey};
        img {
            filter: ${blackToGrayFilter};
        }
    }
`

const ButtonPrimary = css`
    ${ButtonBaseSolid};
    background-color: ${COLOR_PALETTE.yellow};
    border: 1px solid ${COLOR_PALETTE.yellow};
    color: ${COLOR_PALETTE.black};
    &:hover {
        background-color: ${COLOR_PALETTE.yellow_dark};
        border: 1px solid ${COLOR_PALETTE.yellow_dark};
    }
`

const ButtonSecondary = css`
    ${ButtonBaseSolid};
    background-color: ${COLOR_PALETTE.black};
    border: 1px solid ${COLOR_PALETTE.black};
    color: ${COLOR_PALETTE.white};
    img {
        filter: ${blackToWhiteFilter};
    }
    &:hover {
        background-color: ${COLOR_PALETTE.grey_darker};
        border: 1px solid ${COLOR_PALETTE.grey_darker};
    }
`

const ButtonTertiary = css`
    ${ButtonBaseSolid};
    background-color: ${COLOR_PALETTE.white};
    border: 1px solid ${COLOR_PALETTE.yellow};
    color: ${COLOR_PALETTE.black};
    &:hover {
        background-color: ${COLOR_PALETTE.yellow};
    }
`

const ButtonTalent = css`
    ${ButtonBaseSolid};
    background-color: ${COLOR_PALETTE.violet_dark};
    border: 1px solid ${COLOR_PALETTE.violet_dark};
    color: ${COLOR_PALETTE.white};
    img {
        filter: ${blackToWhiteFilter};
    }
    &:hover {
        background-color: ${COLOR_PALETTE.violet};
        border: 1px solid ${COLOR_PALETTE.violet};
    }
`

const ButtonDelete = css`
    ${ButtonBaseSolid};
    background-color: ${COLOR_PALETTE.red};
    border: 1px solid ${COLOR_PALETTE.red};
    color: ${COLOR_PALETTE.white};
    img {
        filter: ${blackToWhiteFilter};
    }
    &:hover {
        background-color: ${COLOR_PALETTE.red_dark};
        border: 1px solid ${COLOR_PALETTE.red_dark};
    }
`

const ButtonSSO = css`
    ${ButtonBaseSolid};
    background-color: ${COLOR_PALETTE.white};
    border: 1px solid ${COLOR_PALETTE.grey_light};
    &:hover {
        border: 1px solid ${COLOR_PALETTE.grey};
    }
`

const ButtonText = css`
    ${ButtonBaseSolid};
    background-color: transparent;
    border: transparent;
    &:hover {
        text-decoration: underline;
    }
    &[disabled],
    &[disabled]:hover {
        background-color: transparent;
        text-decoration: none;
    }
`

const ButtonBaseLink = css`
    ${ButtonBase};
    background-color: transparent;
    border: none;
    display: inline-flex;
    align-items: center;

    &[disabled],
    &[disabled]:hover {
        color: ${COLOR_PALETTE.grey};
        text-decoration: none;
        img {
            filter: ${blackToGrayFilter};
        }
    }
`

const ButtonLink = css`
    ${ButtonBaseLink};
    color: ${COLOR_PALETTE.black};
    &:hover {
        text-decoration: underline;
        text-decoration-color: ${COLOR_PALETTE.yellow};
    }
`

const ButtonLinkDelete = css`
    ${ButtonBaseLink};
    color: ${COLOR_PALETTE.red};
    img {
        filter: ${blackToRedFilter};
    }
    &:hover {
        text-decoration: underline;
    }
`

const ButtonLinkForm = css`
    ${ButtonBaseLink};
    color: ${COLOR_PALETTE.violet_dark};
    img {
        filter: ${blackToVioletDarkFilter};
    }
    &:hover {
        text-decoration: underline;
        text-decoration-color: ${COLOR_PALETTE.yellow};
    }
`

const getButtonStyles = (variant: ButtonVariant): SerializedStyles => {
    switch (variant) {
        case 'primary':
            return ButtonPrimary
        case 'secondary':
            return ButtonSecondary
        case 'tertiary':
            return ButtonTertiary
        case 'talent':
            return ButtonTalent
        case 'delete':
            return ButtonDelete
        case 'sso':
            return ButtonSSO
        case 'text':
            return ButtonText
        case 'link':
            return ButtonLink
        case 'linkDelete':
            return ButtonLinkDelete
        case 'linkForm':
            return ButtonLinkForm
        default:
            return css``
    }
}

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
    variant?: ButtonVariant
    size?: ButtonSize
    icon?: any
    style?: SerializedStyles
}

const Button: FunctionComponent<ButtonProps> = ({
    variant = 'primary',
    size = 'medium',
    icon,
    style,
    type = 'button',
    children,
    ...rest
}) => {
    return (
        <button
            css={css`
                ${getButtonStyles(variant)}
                ${getSizeStyles(size, variant)}
                ${style}
            `}
            type={type}
            {...rest}
        >
            {icon && (
                <img
                    css={css`
                        margin-right: 8px;
                    `}
                    src={icon}
                    alt='Action button'
                />
            )}
            <span>{children}</span>
        </button>
    )
}

export { Button }
