/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Fragment, useContext, useEffect, useMemo } from 'react'
import { getApplications } from '../../api/api'
import { Divider } from '../../components/Divider'
import { DashboardLayout } from '../../components/layout/dashboard/DashboardLayout'
import { DashboardTiles } from '../../components/layout/dashboard/DashboardTiles'
import { ContentLoader } from '../../components/layout/Loader'
import { ApplicationsWrapper } from '../../components/layout/ResponsiveWrapper'
import { app2TileGroups } from '../../contracts/applications'
import { ReduxContext } from '../../redux/Store'

const Dashboard = () => {
    const {
        selectors: {
            userId,
            permanentData: { applications },
        },
        actions: { permanentDataSet },
    } = useContext(ReduxContext)
    useEffect(() => {
        if (userId && applications === null) {
            const fetchData = async () => {
                const data = await getApplications(userId)
                permanentDataSet({ name: 'applications', data })
            }
            fetchData().finally()
        }
    }, [userId, permanentDataSet, applications])
    const appTileGroups = useMemo(() => {
        return applications ? app2TileGroups(applications) : null
    }, [applications])

    return (
        <DashboardLayout>
            <ApplicationsWrapper>
                <h3>Welcome</h3>
                <Divider />
                <h4>Applications</h4>
                {!appTileGroups ? (
                    <ContentLoader />
                ) : (
                    <Fragment>
                        {appTileGroups.map(group => (
                            <div key={group.name} css={css`margin-bottom: 24px;`}>
                                <h5>{group.name}</h5>
                                <DashboardTiles tiles={group.apps} />
                            </div>
                        ))}
                    </Fragment>
                )}
            </ApplicationsWrapper>
        </DashboardLayout>
    )
}

export { Dashboard }
