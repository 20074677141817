/** @jsx jsx */
import { jsx } from '@emotion/core'
import styled from '@emotion/styled'
import { Fragment, useState } from 'react'
import { SkillMatrix as SkillMatrixModel } from '../contracts/profile/skillMatrix'
import { COLOR_PALETTE, mqMax, mqMin } from '../GlobalStyle'
import { AutocompleteSelect } from './autocomplete-select/AutocompleteSelect'
import { NoData } from './NoData'
import { SkillsList } from './talent/SkillsList'

const SkillMatrixContainer = styled.div`
    margin-top: 24px;
    display: flex;

    ${mqMax[1]} {
        flex-direction: column;
        margin-top: 0;
    }
`

const NavbarContainer = styled.div`
    flex: 2;
    height: fit-content;
    display: flex;
    flex-direction: column;
    border-top: 1px solid ${COLOR_PALETTE.grey_light};
    border-bottom: 1px solid ${COLOR_PALETTE.grey_light};

    ${mqMax[1]} {
        display: none;
    }
`

const NavbarEntryContainer = styled.div`
    padding: 0 16px;
    border-left: 1px solid ${COLOR_PALETTE.grey_light};
    border-right: 1px solid ${COLOR_PALETTE.grey_light};
    cursor: pointer;

    &.active {
        border-left: 3px solid ${COLOR_PALETTE.yellow};
        padding-left: 14px;
    }

    &:last-of-type > div {
        border-bottom: none;
    }
`

const NavbarEntry = styled.div`
    color: ${COLOR_PALETTE.violet_dark};
    padding: 14px 0;
    border-bottom: 1px solid ${COLOR_PALETTE.grey_light};
`

const SelectContainer = styled.div`
    display: flex;
    align-items: center;
    height: 104px;
    background-color: ${COLOR_PALETTE.grey_lighter};
    margin: 0 -16px 28px;
    padding: 0 16px;

    ${mqMin[1]} {
        display: none;
    }
`

const ContentContainer = styled.div`
    flex: 5;
    margin-left: 44px;

    ${mqMax[1]} {
        margin-left: 0;
    }
`

const ZeroMarginH6 = styled.h6`
    margin: 0;
`

const SubcategoryContainer = styled.div`
    margin-top: 22px;
`

const SubcategoryHeader = styled.div`
    padding: 7px 15px;
    background-color: ${COLOR_PALETTE.grey_lighter};
`

const SubcategoryContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 15px;
`

const SubcategoryLevelContainer = styled.div`
    display: flex;
    padding: 26px 0;
    border-bottom: 1px solid ${COLOR_PALETTE.grey_lighter};

    &:last-child {
        border-bottom: none;
    }
`

const SubcategoryLevelTitle = styled.div`
    display: flex;
    align-items: center;
    flex: 1;
    color: ${COLOR_PALETTE.grey_dark};
    font-size: 12px;
`

const SubcategoryLevelItems = styled.div`
    flex: 4;
`

const SkillMatrix = ({ skillMatrix }: { skillMatrix: SkillMatrixModel }) => {
    const [selectedCategory, setSelectedCategory] = useState<string>(skillMatrix?.categories[0].name)

    return (
        <SkillMatrixContainer>
            {skillMatrix && (
                <Fragment>
                    <NavbarContainer>
                        {skillMatrix.categories.map(category => {
                            return (
                                <NavbarEntryContainer
                                    key={category.name}
                                    className={selectedCategory === category.name ? 'active' : ''}
                                    onClick={() => setSelectedCategory(category.name)}
                                >
                                    <NavbarEntry>{category.name}</NavbarEntry>
                                </NavbarEntryContainer>
                            )
                        })}
                    </NavbarContainer>
                    <SelectContainer>
                        <AutocompleteSelect
                            canFilter={false}
                            options={skillMatrix.categories.map(category => category.name)}
                            onSelectedValuesChange={(values: Array<string>) => setSelectedCategory(values[0])}
                            currentValues={[selectedCategory]}
                        ></AutocompleteSelect>
                    </SelectContainer>
                    <ContentContainer>
                        <ZeroMarginH6>{selectedCategory}</ZeroMarginH6>
                        {skillMatrix.categories
                            .find(category => category.name === selectedCategory)
                            ?.subcategories.map(subscategory => {
                                return (
                                    <SubcategoryContainer key={subscategory.name}>
                                        <SubcategoryHeader>{subscategory.name}</SubcategoryHeader>
                                        <SubcategoryContentContainer>
                                            {subscategory.expert.length > 0 && (
                                                <SubcategoryLevelContainer>
                                                    <SubcategoryLevelTitle>Expert</SubcategoryLevelTitle>
                                                    <SubcategoryLevelItems>
                                                        <SkillsList skills={subscategory.expert} />
                                                    </SubcategoryLevelItems>
                                                </SubcategoryLevelContainer>
                                            )}

                                            {subscategory.good.length > 0 && (
                                                <SubcategoryLevelContainer>
                                                    <SubcategoryLevelTitle>Good</SubcategoryLevelTitle>
                                                    <SubcategoryLevelItems>
                                                        <SkillsList skills={subscategory.good} />
                                                    </SubcategoryLevelItems>
                                                </SubcategoryLevelContainer>
                                            )}

                                            {subscategory.basic.length > 0 && (
                                                <SubcategoryLevelContainer>
                                                    <SubcategoryLevelTitle>Basic</SubcategoryLevelTitle>
                                                    <SubcategoryLevelItems>
                                                        <SkillsList skills={subscategory.basic} />
                                                    </SubcategoryLevelItems>
                                                </SubcategoryLevelContainer>
                                            )}
                                        </SubcategoryContentContainer>
                                    </SubcategoryContainer>
                                )
                            })}
                    </ContentContainer>
                </Fragment>
            )}

            {!skillMatrix && (
                <Fragment>
                    <NoData />
                </Fragment>
            )}
        </SkillMatrixContainer>
    )
}

export { SkillMatrix }
