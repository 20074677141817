import { FunctionComponent } from 'react'
import { getAppPath } from './contracts/applications'
import { ConfirmCompanyEmail } from './pages/confirm-company-email/ConfirmCompanyEmail'
import { CompanyDetails } from './pages/dashboard/company-account/CompanyDetails'
import { CompanyDetailsForm } from './pages/dashboard/company-account/CompanyDetailsForm'
import { Dashboard } from './pages/dashboard/Dashboard'
import { LoginSettings } from './pages/dashboard/my-account/LoginSettings'
import { PersonalDetails } from './pages/dashboard/my-account/PersonalDetails'
import { MarketingConsents } from './pages/documents/MarketingConsents'
import { PrivacyPolicy } from './pages/documents/PrivacyPolicy'
import { TermsOfUse } from './pages/documents/TermsOfUse'
import { Login } from './pages/login/Login'
import { Logout } from './pages/logout/Logout'
import { LogoutUser } from './pages/logout/LogoutUser'
import { Playground } from './pages/playground/Playground'
import { DataTables } from './pages/playground/sections/DataTables'
import { FormValidation } from './pages/playground/sections/FormValidation'
import { InternalProfile } from './pages/profile/internal-profile/InternalProfile'
import { PublicProfile } from './pages/profile/public-profile/PublicProfile'
import { RecoverPassword } from './pages/recover-password/RecoverPassword'
import { RecoverPasswordNewPassword } from './pages/recover-password/RecoverPasswordNewPassword'
import { Register } from './pages/register/Register'
import { RegisterConfirmation } from './pages/register/RegisterConfirmation'
import { EmployeeProfilePage } from './pages/talent-management/EmployeeProfilePage'
import { EmployeesSearchPage } from './pages/talent-management/EmployeesSearchPage'
import { SpecialistsSearchFavoritesPage } from './pages/virtual-bench/SpecialistsSearchFavoritesPage'
import { SpecialistsSearchPage } from './pages/virtual-bench/SpecialistsSearchPage'

type Routes = Array<RouteDefinition>

type RouteDefinition = {
    path: string
    component?: FunctionComponent<any>
    children?: Routes
    public?: boolean
}

const routes: Routes = [
    {
        path: '/',
        component: Login,
        public: true,
    },
    {
        path: '/logout',
        component: Logout,
        public: true,
        children: [
            {
                path: '/user',
                component: LogoutUser,
                public: true,
            },
        ],
    },
    {
        path: '/documents',
        children: [
            {
                path: '/terms-of-use',
                component: TermsOfUse,
                public: true,
            },
            {
                path: '/privacy-policy',
                component: PrivacyPolicy,
                public: true,
            },
            {
                path: '/marketing-consents',
                component: MarketingConsents,
                public: true,
            },
        ],
    },
    {
        path: '/register',
        component: Register,
        public: true,
        children: [
            {
                path: '/confirmation',
                component: RegisterConfirmation,
                public: true,
            },
        ],
    },
    {
        path: '/recover-password',
        component: RecoverPassword,
        public: true,
        children: [
            {
                path: '/new-password',
                component: RecoverPasswordNewPassword,
                public: true,
            },
        ],
    },
    {
        path: '/dashboard',
        component: Dashboard,
        children: [
            {
                path: '/my-account',
                children: [
                    {
                        path: '/login-settings',
                        component: LoginSettings,
                    },
                    {
                        path: '/personal-details',
                        component: PersonalDetails,
                    },
                ],
            },
            {
                path: '/company-account',
                children: [
                    {
                        path: '/company-details',
                        component: CompanyDetails,
                        children: [
                            {
                                path: '/form',
                                component: CompanyDetailsForm,
                            },
                        ],
                    },
                ],
            },
        ],
    },
    {
        path: '/profile/:specialistId',
        component: InternalProfile,
    },
    {
        path: '/public/profile/:linkId',
        component: PublicProfile,
        public: true,
    },
    {
        path: '/confirm-company-email',
        component: ConfirmCompanyEmail,
    },
    {
        path: getAppPath('VIRTUAL_BENCH'),
        component: SpecialistsSearchPage,
        children: [
            {
                path: '/favorites',
                component: SpecialistsSearchFavoritesPage,
            },
        ],
    },
    {
        path: getAppPath('TALENT_MANAGEMENT'),
        component: EmployeesSearchPage,
        children: [
            {
                path: '/employee/:employeeId',
                component: EmployeeProfilePage,
            },
        ],
    },
    {
        path: '/playground',
        component: Playground,
        public: true,
    },

    {
        path: '/playground/form',
        component: FormValidation,
        public: true,
    },
    {
        path: '/playground/data-tables',
        component: DataTables,
        public: true,
    },
]

const mapRoutes = (routesDefinition: Routes) => {
    const output: Routes = []

    const mapNode = (routes: Routes, prefix = '') => {
        routes.forEach((routeDef: RouteDefinition) => {
            if (routeDef.children) {
                mapNode(routeDef.children, prefix + routeDef.path)
                delete routeDef.children
            }

            if (routeDef.component) {
                routeDef.path = prefix + routeDef.path
                output.push(routeDef)
            }
        })
    }

    mapNode(routesDefinition)

    return output
}

const routesDefinition = mapRoutes(routes)

export { routesDefinition }
