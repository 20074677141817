import pureAxios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import config from '../environment/config.json'

const handleError = (error: any): void => {
    console.warn('Api error: ', error)
}

const defaultConfig = {
    baseURL: config.apiUrl,
}

const standardAxios: AxiosInstance = pureAxios.create(defaultConfig)

const onRequestFulfilled = (request: AxiosRequestConfig) => {
    // TODO: get this config directly from the store
    const accessToken = localStorage.getItem('accessToken') || null
    request.headers['Authorization'] = `Bearer ${accessToken}`
    return request
}
const onRequestRejected = (error: AxiosError) => {
    return Promise.reject(error)
}
const onResponseFulfilled = (response: AxiosResponse) => response.data
const onResponseRejected = (error: AxiosError) => {
    handleError(error)
    if (error.response && error.response.status === 401) {
        // Clear access token in the future
    }

    return Promise.reject(error.response)
}

standardAxios.interceptors.request.use(onRequestFulfilled, onRequestRejected)
standardAxios.interceptors.response.use(onResponseFulfilled, onResponseRejected)

const axios: AxiosInstance = standardAxios

export { axios, pureAxios }
