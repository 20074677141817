import { Application } from '../contracts/applications'
import { Country } from '../contracts/country'
import { DocumentsResponse, DocumentType } from '../contracts/documentsResponse'
import { Employee } from '../contracts/employees/employee'
import { EmployeesFiltersRequest, EmployeesFiltersResponse } from '../contracts/employees/employeesFilters'
import { EmployeesSearchRequest, EmployeesSearchResponse } from '../contracts/employees/employeesSearch'
import { ContactForm } from '../contracts/profile/contactForm'
import { Profile } from '../contracts/profile/profile'
import { FiltersRequest, FiltersResponse } from '../contracts/search/specialistsFilters'
import { SpecialistsSearchRequest, SpecialistsSearchResponse } from '../contracts/search/specialistsSearch'
import { SignDocumentsRequest } from '../contracts/signDocumentsRequest'
import { UserData } from '../contracts/userData'
import { axios } from './axios'

const getCountries = (): Promise<Array<Country>> => {
    return axios.get(`/countries`)
}

const getCities = (country: string): Promise<Array<string>> => {
    return axios.get(`/countries/${country}/cities`)
}

const getSpecialistRoles = (filter?: string): Promise<Array<string>> => {
    return axios.get(`/specialist-roles?filter=${filter || ''}`)
}

const getCompanyRoles = async (): Promise<Array<string>> => {
    return axios.get(`/company-roles`)
}

const getUser = async (userId: string): Promise<any> => {
    return axios.get(`/users/${userId}`)
}

const updateUser = async (userId: string, data: UserData): Promise<any> => {
    return axios.put(`/users/${userId}`, data)
}

const getCompanies = (userId: string): Promise<Array<any>> => {
    return axios.get(`/companies?user-id=${userId}`)
}

const saveCompany = (company: any): Promise<void> => {
    return axios.post(`/companies`, company)
}

const editCompany = (company: any): Promise<void> => {
    return axios.put(`/companies/${company.id}`, company)
}

const sendCompanyEmailConfirmation = (companyId: string): Promise<void> => {
    return axios.post(`/companies/${companyId}:sendConfirmationEmail`)
}

const documentsEndpoint = `/documents`

const getDocuments = (): Promise<DocumentsResponse> => {
    return axios.get(documentsEndpoint)
}

const getUserDocuments = (userId: string): Promise<any> => {
    return axios.get(`${documentsEndpoint}/-/users/${userId}`)
}

const signDocuments = (types: Array<DocumentType>, email: string): Promise<any> => {
    const requestPayload: SignDocumentsRequest = {
        documentsTypes: types,
        userEmail: email,
    }

    return axios.post(`${documentsEndpoint}:sign`, requestPayload)
}

const getApplications = (userId: string): Promise<Array<Application>> => {
    return axios.get(`/applications/-/users/${userId}`)
}

const getSpecialistsProfiles = (searchRequest: SpecialistsSearchRequest): Promise<SpecialistsSearchResponse> => {
    return axios.post(`/specialists:search`, searchRequest)
}

const getSpecialistsFilters = (searchRequest: FiltersRequest): Promise<FiltersResponse> => {
    return axios.post(`/specialists:filters`, searchRequest)
}

const getSpecialistProfile = (specialistId: string): Promise<Profile> => {
    return axios.get(`/specialists/${specialistId}`)
}

const getPublicSpecialistProfile = (linkId: string): Promise<Profile> => {
    return axios.get(`/specialists/-/links/${linkId}`)
}

const addSpecialistProfileToFavorites = (specialistId: string): Promise<{ specialistId: string }> => {
    return axios.post('/favoriteSpecialists', { specialistId })
}

const removeSpecialistProfileFromFavorites = (specialistId: string): Promise<void> => {
    return axios.delete(`/favoriteSpecialists/${specialistId}`)
}

const getShareProfileLinkId = (specialistId: string): Promise<{ linkId: string }> => {
    return axios.post(`/specialists/${specialistId}/links`)
}

// EMPLOYEES

const addEmployee = (employee: Employee): Promise<Employee> => {
    return axios.post(`/employees`, employee)
}

const addEmployees = (employees: Array<Employee>): Promise<Array<Employee>> => {
    return axios.post(`/employees:batchCreate`, { employees })
}

const validateEmployeeEmail = (email: string): Promise<any> => {
    return axios.post(`/employees:validateEmail`, { email })
}

const getEmployees = (searchRequest: EmployeesSearchRequest): Promise<EmployeesSearchResponse> => {
    return axios.post(`/employees:search`, searchRequest)
}

const getEmployeesFilters = (searchRequest: EmployeesFiltersRequest): Promise<EmployeesFiltersResponse> => {
    return axios.post(`/employees:filters`, searchRequest)
}

const getEmployee = (employeeId: string): Promise<Employee> => {
    return axios.get(`/employees/${employeeId}`)
}

const updateEmployee = (employeeId: number, employee: Employee): Promise<Employee> => {
    // TODO: discuss update/put method signatures (explicit id)
    return axios.put(`/employees/${employeeId}`, employee)
}

const deleteEmployee = (employeeId: number): Promise<void> => {
    return axios.delete(`/employees/${employeeId}`)
}

const sendContactForm = (contactForm: ContactForm): Promise<void> => {
    return axios.post('/hireSpecialistContacts', contactForm)
}

export {
    getCompanies,
    getCountries,
    getCities,
    getSpecialistRoles,
    getCompanyRoles,
    updateUser,
    getUser,
    getDocuments,
    getUserDocuments,
    signDocuments,
    saveCompany,
    editCompany,
    getApplications,
    getSpecialistsProfiles,
    getSpecialistsFilters,
    sendCompanyEmailConfirmation,
    getSpecialistProfile,
    getPublicSpecialistProfile,
    addSpecialistProfileToFavorites,
    removeSpecialistProfileFromFavorites,
    getShareProfileLinkId,
    // employees
    addEmployee,
    addEmployees,
    validateEmployeeEmail,
    getEmployees,
    getEmployeesFilters,
    getEmployee,
    updateEmployee,
    deleteEmployee,
    sendContactForm,
}
