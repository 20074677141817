/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { useCallback, useContext, useState } from 'react'
import { sendPasswordResetEmail } from '../../api/firebase'
import { Button } from '../../components/Button'
import { Divider } from '../../components/Divider'
import { Info } from '../../components/Info'
import { SettingsWrapper } from '../../components/layout/ResponsiveWrapper'
import { mqMax } from '../../GlobalStyle'
import { ReduxContext } from '../../redux/Store'

const AccountLoginSettings = () => {
    const {
        selectors: { user },
    } = useContext(ReduxContext)

    const [emailSent, setEmailSent] = useState(false)

    const handleChangePassword = useCallback(async () => {
        await sendPasswordResetEmail(user.email)
        setEmailSent(true)
    }, [user])

    return (
        <SettingsWrapper>
            <h4>My Account</h4>
            <Divider />
            <h5>Login Settings</h5>
            <h6>Email Address</h6>
            <p>
                Your current Email Address is <strong>{user && user.email}</strong>
            </p>
            <Divider />
            <h6>Change Password</h6>
            {emailSent ? (
                <Info>
                    <h6>Check your Inbox</h6>
                    Please check your email account to proceed with changing your password.
                    <Divider />
                    <Button onClick={handleChangePassword} variant='linkForm'>
                        Resend Email
                    </Button>
                </Info>
            ) : (
                <Button
                    variant='primary'
                    onClick={handleChangePassword}
                    disabled={!user}
                    css={css`
                        ${mqMax[1]} {
                            width: 100%;
                        }
                    `}
                >
                    Change Password
                </Button>
            )}
        </SettingsWrapper>
    )
}

export { AccountLoginSettings }
