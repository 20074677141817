/** @jsx jsx */
import { jsx } from '@emotion/core'
import { FunctionComponent, useCallback } from 'react'
import { AutocompleteSelect } from '../../../components/autocomplete-select/AutocompleteSelect'
import { FilterColumn, FiltersBarWrapper, FiltersClearTrigger, FiltersContainer } from '../../../components/filters/FiltersBarHelpers'
import { LocationsPicker } from '../../../components/inputs/LocationsPicker'
import { FirstColumn, ResponsiveColumns, SecondColumn } from '../../../components/layout/ResponsiveColumns'
import { SearchLocation } from '../../../contracts/common/searchLocation'
import { mapEmploymentType } from '../../../contracts/employees/employee'
import { EmployeesFilters, EmployeesFiltersOptions } from '../../../contracts/employees/employeesFilters'
import { countSelectedFilters } from '../talentManagementUtils'

type EmployeesFiltersProps = {
    possibleFilters: EmployeesFiltersOptions
    selectedFilters: EmployeesFilters
    opened: boolean
    onChangeFilters: (filters: EmployeesFilters) => void
    onClearAll: () => void
}

const EmployeesFiltersBar: FunctionComponent<EmployeesFiltersProps> = ({
    possibleFilters,
    selectedFilters,
    opened,
    onChangeFilters,
    onClearAll,
}) => {
    const onChangeFilter = useCallback(
        (values: Array<string | SearchLocation> | boolean, filterName: string) => {
            onChangeFilters({
                ...selectedFilters,
                [filterName]: values,
            })
        },
        [onChangeFilters, selectedFilters],
    )

    const onChangeLocations = useCallback(locations => onChangeFilter(locations, 'locations'), [onChangeFilter])
    const onChangeRemote = useCallback(remote => onChangeFilter(remote, 'remote'), [onChangeFilter])

    return (
        <FiltersBarWrapper filtersOpened={opened}>
            <ResponsiveColumns>
                <FirstColumn width={60} padding={6}>
                    <FiltersContainer>
                        <FilterColumn width={25}>
                            <AutocompleteSelect
                                multiple
                                placeholder='Type'
                                options={possibleFilters.employmentTypes}
                                filterName='employmentTypes'
                                currentValues={selectedFilters.employmentTypes}
                                selectedLabelTransformer={mapEmploymentType}
                                onSelectedValuesChange={onChangeFilter}
                            />
                        </FilterColumn>
                        <FilterColumn width={25}>
                            <AutocompleteSelect
                                multiple
                                placeholder='Role'
                                options={possibleFilters.roles}
                                filterName='roles'
                                currentValues={selectedFilters.roles}
                                onSelectedValuesChange={onChangeFilter}
                            />
                        </FilterColumn>
                        <FilterColumn width={25}>
                            <AutocompleteSelect
                                multiple
                                placeholder='Seniority'
                                options={possibleFilters.seniorities}
                                filterName='seniorities'
                                currentValues={selectedFilters.seniorities}
                                onSelectedValuesChange={onChangeFilter}
                            />
                        </FilterColumn>
                        <FilterColumn width={25}>
                            <AutocompleteSelect
                                multiple
                                placeholder='Top Skills'
                                options={possibleFilters.skills}
                                filterName='skills'
                                currentValues={selectedFilters.skills}
                                onSelectedValuesChange={onChangeFilter}
                            />
                        </FilterColumn>
                    </FiltersContainer>
                </FirstColumn>
                <SecondColumn width={40} padding={6}>
                    <FiltersContainer>
                        <FilterColumn width={80}>
                            <LocationsPicker
                                locations={possibleFilters.locations}
                                selectedLocations={selectedFilters.locations}
                                selectedRemote={selectedFilters.remote}
                                onChangeLocations={onChangeLocations}
                                onChangeRemote={onChangeRemote}
                            />
                        </FilterColumn>
                        <FilterColumn width={20}>
                            <FiltersClearTrigger onClear={onClearAll} quantity={countSelectedFilters(selectedFilters)} />
                        </FilterColumn>
                    </FiltersContainer>
                </SecondColumn>
            </ResponsiveColumns>
        </FiltersBarWrapper>
    )
}

export { EmployeesFiltersBar }
