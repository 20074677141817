/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import styled from '@emotion/styled'
import { ChangeEvent, Fragment, FunctionComponent, MutableRefObject, useEffect, useRef, useState } from 'react'
import { FixedSizeList, ListChildComponentProps } from 'react-window'
import { COLOR_PALETTE } from '../../GlobalStyle'
import { distinctFilter } from '../../utils/filters'
import { InputLabel } from '../Input'
import { ErrorText } from '../inputs/ErrorText'
import { DropdownComponent, DropdownContainer, DropdownDivider } from './DropdownComonent'
import { DropdownFooter } from './DropdownFooter'
import { FilterBox } from './FilterBox'

const checkboxActive = `data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='3.5' y='4.5' width='15' height='15' fill='white' stroke='%23ACACAC' /%3E%3C/svg%3E`

const checkboxSelected = `data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='3.5' y='4.5' width='15' height='15' fill='white' stroke='%2313100D' /%3E%3Crect x='4.5' y='5.5' width='13' height='13' fill='%2313100D' stroke='black' /%3E%3Cpath d='M7 12.6L9.4 15L15.4 9' stroke='white' /%3E%3C/svg%3E`

type AutoCompleteSelectBaseProps = {
    rangeRef: MutableRefObject<HTMLDivElement>
    isOpen: boolean
    options: Array<string>
    filterName: string
    setIsOpen: (isOpen: boolean) => void
    placeholder?: string
    canFilter?: boolean
    onSelectedValuesChange?: (values: Array<string>, filterName: any) => void
    dropdownWidth?: string
    selectWidth?: string
    currentValues?: Array<string>
    errors?: any
    disabled?: boolean
    multiple?: boolean
    selectedLabelTransformer?: (opt: any) => string
    labelTransformer?: (opt: any) => string
    searchTransformer?: (opt: any) => string
    label?: string
    defaultValues?: Array<string>
    name?: string
    virtualized?: boolean
    innerRef?: any
}

const SelectItemRenderer: FunctionComponent<ListChildComponentProps> = ({ data, index, style }) => {
    const { onOptionClick, isOptionChecked, multiple, labelTransformer, options } = data
    const option = options[index]
    return (
        <div style={style}>
            <SelectItem onClick={() => onOptionClick(option)} multiple={multiple} selected={isOptionChecked(option)}>
                <span title={option}>{labelTransformer(option)}</span>
            </SelectItem>
        </div>
    )
}

type SelectItemProps = {
    selected?: boolean
    multiple?: boolean
    autosize?: boolean
}

const SelectItem = styled.div<SelectItemProps>`
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    padding: 12px 10px 12px ${props => (props.multiple ? 42 : 18)}px;
    position: relative;
    cursor: pointer;
    color: ${COLOR_PALETTE.black};
    ${props =>
        !props.autosize &&
        css`
            height: 40px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        `}
    
    &:before {
        ${props => (props.multiple ? 'content: "";' : '')} // display only for multiple
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 18px;
        background-image: url("${props => (props.selected ? checkboxSelected : checkboxActive)}");
        background-repeat: no-repeat;
        width: 20px;
        height: 20px;
        background-position: center center;
    }
`

const AutocompleteSelectBase: FunctionComponent<AutoCompleteSelectBaseProps> = ({
    placeholder = '',
    options,
    filterName = '',
    canFilter = true,
    onSelectedValuesChange = () => {
        return
    },
    dropdownWidth,
    selectWidth,
    currentValues = [],
    rangeRef,
    isOpen,
    errors,
    setIsOpen,
    disabled = false,
    multiple = false,
    selectedLabelTransformer = opt => opt,
    labelTransformer = selectedLabelTransformer,
    searchTransformer = labelTransformer,
    label,
    defaultValues,
    innerRef,
    name,
    virtualized,
    ...rest
}) => {
    const inputRef = useRef<HTMLInputElement | any>()
    const [filteredOptions, setFilteredOptions] = useState(options)
    const [selectedValues, setSelectedValues] = useState(currentValues || defaultValues)
    const [newValues, setNewValues] = useState(currentValues)
    const [hasChanged, setHasChanged] = useState(false)

    useEffect(() => {
        setSelectedValues(currentValues)
        setNewValues(currentValues)
    }, [currentValues])

    useEffect(() => {
        setFilteredOptions(options)
    }, [options])

    const onSearching = (event: ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target

        if (options) {
            const newFilteredOptions = options.filter(option => searchTransformer(option).toLowerCase().includes(value.toLowerCase()))
            setFilteredOptions(newFilteredOptions)
        }
    }

    const onFilterBoxClick = () => {
        if (!disabled) {
            setIsOpen(!isOpen)

            if (!isOpen) {
                setNewValues(selectedValues)
                setFilteredOptions(options)
                setHasChanged(true)
            }
        }
    }

    const onClearAll = () => {
        setNewValues([])
        setSelectedValues([])
        onSelectedValuesChange([], filterName)
        setHasChanged(true)
    }

    const onApply = () => {
        if (!areButtonsDisabled()) {
            const newCurrentValues = newValues.filter(distinctFilter)
            setSelectedValues(newCurrentValues)
            onSelectedValuesChange(newCurrentValues, filterName)
            setIsOpen(false)
        }
    }

    const selectOption = (event: string) => {
        const newOptions = multiple ? [...newValues] : []
        newOptions.push(event)
        const values = newOptions.filter(distinctFilter)
        setNewValues(values)

        if (!multiple) {
            setSelectedValues(values)
            onSelectedValuesChange(values, filterName)
            setIsOpen(false)
        }
    }

    const deselectOption = (event: string) => {
        const newOptions = [...newValues]
        setNewValues(newOptions.filter(option => option !== event))
    }

    const onOptionClick = (option: string) => {
        if (isOptionChecked(option)) {
            deselectOption(option)
        } else {
            selectOption(option)
        }
        setHasChanged(true)
    }

    const isOptionChecked = (option: string): boolean => {
        return newValues.includes(option)
    }

    const areButtonsDisabled = (): boolean => {
        return !hasChanged
    }

    const displayedValue = (values: Array<any>): string => {
        if (values && values.length === 1) {
            return selectedLabelTransformer(values[0])
        }

        if (values && values.length > 1) {
            return `${selectedLabelTransformer(values[0])} (+${values.length - 1})`
        }

        return ''
    }

    return (
        <div
            ref={rangeRef}
            css={css`
                position: relative;
                display: block;
                flex: 1;
            `}
            {...rest}
        >
            {innerRef && (
                <input
                    hidden
                    name={name}
                    ref={innerRef}
                    value={selectedValues}
                    onChange={() => {
                        return
                    }}
                />
            )}
            {label && (
                <InputLabel
                    css={css`
                        display: inline-block;
                        color: ${COLOR_PALETTE.grey_dark};
                    `}
                >
                    {label}
                </InputLabel>
            )}
            <FilterBox
                onClick={onFilterBoxClick}
                placeholder={placeholder}
                displayedValue={displayedValue(selectedValues)}
                isOpen={isOpen}
                styles={`
                    width: ${selectWidth};

                    ${disabled ? 'background-color:' + COLOR_PALETTE.grey_lighter + ';' : ''}
                    ${disabled ? 'color:' + COLOR_PALETTE.grey + ';' : ''}

                    ${errors ? 'border: 1px solid ' + COLOR_PALETTE.red + ';' : ''}
                `}
            />

            {errors && <ErrorText>{errors.message ? errors.message : `This field is required`}</ErrorText>}

            {isOpen && (
                <DropdownContainer
                    css={css`
                        min-width: ${dropdownWidth};
                        background-color: ${COLOR_PALETTE.white};
                    `}
                >
                    {canFilter && (
                        <div
                            css={css`
                                min-width: 150px;
                                display: flex;
                                border-bottom: 1px solid ${COLOR_PALETTE.grey_light};
                                position: relative;
                            `}
                        >
                            <input
                                placeholder='Start typing'
                                type='text'
                                ref={inputRef}
                                autoFocus
                                onChange={onSearching}
                                css={css`
                                    width: 100%;
                                    box-shadow: none;
                                    padding: 16px;
                                    font-size: 14px;
                                    line-height: 130%;
                                    border: 0;
                                    color: ${COLOR_PALETTE.grey};
                                `}
                            />
                        </div>
                    )}
                    {virtualized && (
                        <FixedSizeList
                            itemData={{
                                options: filteredOptions,
                                onOptionClick,
                                isOptionChecked,
                                multiple,
                                labelTransformer,
                            }}
                            height={220}
                            itemCount={filteredOptions.length}
                            itemSize={40}
                            width={dropdownWidth || '100%'}
                        >
                            {SelectItemRenderer}
                        </FixedSizeList>
                    )}
                    {!virtualized && (
                        <div
                            css={css`
                                max-height: 220px;
                                overflow-y: auto;
                            `}
                        >
                            {filteredOptions &&
                                filteredOptions.map((option, index) => (
                                    <SelectItem
                                        key={option}
                                        onClick={() => onOptionClick(option)}
                                        multiple={multiple}
                                        selected={isOptionChecked(option)}
                                        autosize
                                    >
                                        {labelTransformer(option)}
                                    </SelectItem>
                                ))}
                        </div>
                    )}
                    {multiple && (
                        <Fragment>
                            <DropdownDivider />
                            <DropdownFooter onApply={onApply} onClearAll={onClearAll} buttonsDisabled={areButtonsDisabled()} />
                        </Fragment>
                    )}
                </DropdownContainer>
            )}
        </div>
    )
}

const AutocompleteSelect = DropdownComponent(AutocompleteSelectBase)

export { AutocompleteSelect }
