/** @jsx jsx */
import { jsx } from '@emotion/core'
import { AwardsBox } from '../../components/AwardsBox'
import { TwoCols } from '../../components/layout/TwoCols'
import { LoginForm } from '../../components/LoginForm'

const Logout = () => {
    return <TwoCols firstCol={<LoginForm />} secondCol={<AwardsBox />} />
}

export { Logout }
