const resolveFieldError = (errors: any, errorText?: string) => {
    if (errorText) {
        return errorText
    }
    // currently supported: display first error only
    if (Array.isArray(errors) && errors.length) {
        const [first] = errors
        return first?.message || first || 'field not valid'
    } else if (typeof errors === 'object') {
        return errors?.message || false
    }

    return false
}

type AntValidationStatus = '' | 'error' | 'success' | 'warning' | 'validating' | undefined
const resolveAntValidationProps = (errors: any) => {
    const validateStatus: AntValidationStatus = !!errors ? 'error' : undefined
    const message = resolveFieldError(errors)
    return { validateStatus, help: message || '' }
}

export { resolveFieldError, resolveAntValidationProps }
