import queryString from 'query-string'
import { FiltersResponse } from '../contracts/search/specialistsFilters'
import { SpecialistsSearchRequest } from '../contracts/search/specialistsSearch'

const defaultPageSize = 6

const stringifySearchRequestToQuery = (searchRequest: SpecialistsSearchRequest): string => {
    const params = []
    const { searchText, seniorities, skills, industries, languages, projects, experience, locations, remote, paging } = searchRequest

    if (searchText) {
        params.push(`searchText=${searchText}`)
    }

    if (seniorities && seniorities.length > 0) {
        params.push(`seniorities=${seniorities.join(',')}`)
    }

    if (skills && skills.length > 0) {
        params.push(`skills=${skills.join(',')}`)
    }

    if (industries && industries.length > 0) {
        params.push(`industries=${industries.join(',')}`)
    }

    if (languages && languages.length > 0) {
        params.push(`languages=${languages.join(',')}`)
    }

    if (projects && projects.length > 0) {
        params.push(`projects=${projects.join(',')}`)
    }

    if (experience && experience.gte) {
        params.push(`experienceMin=${experience.gte}`)
    }
    if (experience && experience.lte) {
        params.push(`experienceMax=${experience.lte}`)
    }

    if (locations && locations.length > 0) {
        if (locations[0].country) {
            params.push(`locationsCountry=${locations[0].country}`)
            if (locations[0].cities.length > 0) {
                params.push(`locationsCities=${locations[0].cities.join(',')}`)
            }
        }
    }

    if (remote) {
        params.push(`remote=1`)
    }

    if (paging && paging.page !== 1) {
        params.push(`page=${paging.page}`)
    }

    return params.join('&')
}

type Parsed<T> = T | T[] | null | undefined

const parseToArray: (value: Parsed<string>) => Array<string> = value => {
    if (typeof value === 'string') {
        return value ? value.split(',') : []
    } else if (Array.isArray(value)) {
        return value.map(v => v.toString())
    } else {
        return value ? [value] : []
    }
}

type NumberParser = (value: Parsed<string>, defaultValue?: number) => number
const parseToNumber: NumberParser = (value, defaultValue = 0) => {
    if (typeof value === 'string') {
        return parseInt(value, 10) || defaultValue
    } else {
        return defaultValue
    }
}

const parseToBoolean: (value: Parsed<string>) => boolean = value => {
    return parseToNumber(value) !== 0
}

const parseToString: (value: Parsed<string>) => string = value => {
    if (typeof value === 'string') {
        return value
    } else {
        return ''
    }
}

const toMonths = (years: number) => years * 12
const toYears = (months: number) => Math.floor(months / 12)

type NonSearchQueryableFields = {
    favorite?: boolean
}

const parseQueryToSearchRequest = (searchQuery: string, additional: NonSearchQueryableFields): SpecialistsSearchRequest => {
    const params = queryString.parse(searchQuery)
    const {
        searchText,
        seniorities,
        skills,
        industries,
        languages,
        projects,
        experienceMin,
        experienceMax,
        locationsCountry,
        locationsCities,
        remote,
        page,
    } = params

    const result: SpecialistsSearchRequest = {
        searchText: parseToString(searchText),
        seniorities: parseToArray(seniorities),
        skills: parseToArray(skills),
        industries: parseToArray(industries),
        languages: parseToArray(languages),
        projects: parseToArray(projects),
        remote: parseToBoolean(remote),
        paging: {
            page: parseToNumber(page, 1),
            size: defaultPageSize, // TODO: ask designers and change
        },
    }

    if (experienceMin || experienceMax) {
        result.experience = {
            gte: experienceMin ? parseToNumber(experienceMin) : 0,
            lte: experienceMax ? parseToNumber(experienceMax) : toMonths(40),
        }
    }

    if (locationsCountry) {
        result.locations = [
            {
                country: parseToString(locationsCountry),
                cities: [],
            },
        ]
        if (locationsCities) {
            result.locations[0].cities = parseToArray(locationsCities)
        }
    }

    if (additional && additional.favorite) {
        result.favorite = additional.favorite
    }

    return result
}

const sanitizePossibleFilters = (possibleFilters: FiltersResponse) => possibleFilters

export { stringifySearchRequestToQuery, parseQueryToSearchRequest, sanitizePossibleFilters, toMonths, toYears, defaultPageSize }
