import React from 'react'
import { BrowserRouter, Switch } from 'react-router-dom'
import { NotificationProvider } from './components/notification/NotificationProvider'
import { GlobalStylesProvider } from './GlobalStyle'
import { PrivateRoute } from './guards/PrivateRoute'
import { PublicRoute } from './guards/PublicRoute'
import { PageNotFound } from './pages/error/PageNotFound'
import { ReduxProvider } from './redux/Store'
import { routesDefinition } from './Routes'

const App = () => {
    // React StrictMode nested under NotificationProvider until
    // https://github.com/jossmac/react-toast-notifications/issues/82
    // resolved (should be couple of days)
    return (
        <NotificationProvider>
            <React.StrictMode>
                <ReduxProvider>
                    <GlobalStylesProvider>
                        <BrowserRouter>
                            <Switch>
                                {routesDefinition.map(route =>
                                    route.public ? (
                                        <PublicRoute key={route.path} exact path={route.path} component={route.component} />
                                    ) : (
                                        <PrivateRoute key={route.path} exact path={route.path} component={route.component} />
                                    ),
                                )}
                                <PublicRoute component={PageNotFound} />
                            </Switch>
                        </BrowserRouter>
                    </GlobalStylesProvider>
                </ReduxProvider>
            </React.StrictMode>
        </NotificationProvider>
    )
}

export { App }
