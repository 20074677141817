/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import styled from '@emotion/styled'
import { FunctionComponent } from 'react'
import { mqMin } from '../../GlobalStyle'

export type ColumnProps = {
    width?: number
    padding?: number
}

const FirstColumn = styled.div<ColumnProps>`
    width: 100%;
    ${mqMin[2]} {
        width: ${props => props.width || 50}%;
        padding-right: ${({ padding = 9 }) => padding}px;
    }
`

const SecondColumn = styled.div<ColumnProps>`
    width: 100%;
    ${mqMin[2]} {
        width: ${props => props.width || 50}%;
        padding-left: ${({ padding = 9 }) => padding}px;
    }
`

type ResponsiveColumnsProps = {
    firstCol?: any
    secondCol?: any
}

const ResponsiveColumns: FunctionComponent<ResponsiveColumnsProps> = ({ firstCol, secondCol, children }) => {
    return (
        <div
            css={css`
                display: flex;
                justify-content: space-between;
                flex-direction: row;
                flex-wrap: wrap;
            `}
        >
            {firstCol && <FirstColumn>{firstCol}</FirstColumn>}
            {secondCol && <SecondColumn>{secondCol}</SecondColumn>}
            {children}
        </div>
    )
}

export { ResponsiveColumns, FirstColumn, SecondColumn }
