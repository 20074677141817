/** @jsx jsx */
import { jsx } from '@emotion/core'
import { SkillMatrix } from '../../../components/SkillMatrix'
import { SkillMatrix as SkillMatrixModel } from '../../../contracts/profile/skillMatrix'

const skillMatrix: SkillMatrixModel = {
    categories: [
        {
            name: 'Akka Ecosystem',
            subcategories: [
                {
                    name: 'Akka',
                    basic: [],
                    good: ['Akka-streams'],
                    expert: ['Akka-HTTP'],
                    notRated: [],
                },
            ],
        },
        {
            name: 'Databases',
            subcategories: [
                {
                    name: 'NoSQL Databases',
                    basic: ['Neo4j'],
                    good: ['Apache Couchdb', 'Apache HBase', 'Mongodb'],
                    expert: ['Redis'],
                    notRated: [],
                },
                {
                    name: 'Relational Databases',
                    basic: [],
                    good: ['Amazon Aurora', 'MySQL', 'PostgreSQL'],
                    expert: [],
                    notRated: [],
                },
                {
                    name: 'SQL Tools',
                    basic: [],
                    good: ['Flyway', 'ReactiveMongo'],
                    expert: ['Elastic4s'],
                    notRated: [],
                },
            ],
        },
        {
            name: 'Programming',
            subcategories: [
                {
                    name: 'Data Formats',
                    basic: [],
                    good: ['Xml'],
                    expert: [],
                    notRated: [],
                },
                {
                    name: 'Programming Approaches',
                    basic: ['DDD (Domain Driven Design)', 'GraphQL', 'Regular Expressions'],
                    good: ['Event Sourcing', 'Microservices'],
                    expert: ['CQRS', 'performing code reviews', 'REST-API'],
                    notRated: [],
                },
                {
                    name: 'Programming Languages',
                    basic: ['Angular', 'CSS', 'HTML', 'JavaScript', 'jQuery', 'Kotlin', 'ReactJS', 'Type classes', 'TypeScript'],
                    good: ['Bash', 'Either', 'Extension methods', 'Java', 'Monad transformers'],
                    expert: ['Higher-order functions', 'SQL'],
                    notRated: [],
                },
                {
                    name: 'Programming Paradigms',
                    basic: ['Reactive Programming'],
                    good: ['Functional Programming'],
                    expert: ['Object-Oriented Programming'],
                    notRated: [],
                },
                {
                    name: 'Scala skills',
                    basic: ['Type classes'],
                    good: ['Either', 'Extension methods', 'Monad transformers'],
                    expert: ['Higher-order functions'],
                    notRated: ['2.13'],
                },
            ],
        },
        {
            name: 'Other',
            subcategories: [
                {
                    name: 'Other',
                    basic: ['MS DOS', 'MyCloud', 'Some Test Value', 'TestDB'],
                    good: ['Hadoop', 'oh my zsh'],
                    expert: ['Algorithms', 'Meow', 'much TDD'],
                    notRated: ['Deployment'],
                },
            ],
        },
    ],
}

const SkillMatrixPlayground = () => {
    return <SkillMatrix skillMatrix={skillMatrix} />
}

export { SkillMatrixPlayground }
