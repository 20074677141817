/** @jsx jsx */
import { jsx } from '@emotion/core'
import { AwardsBox } from '../../components/AwardsBox'
import { TwoCols } from '../../components/layout/TwoCols'
import { RegisterForm } from './components/RegisterForm'

const Register = () => {
    return <TwoCols firstCol={<RegisterForm />} secondCol={<AwardsBox />} />
}

export { Register }
