/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { FunctionComponent, useCallback, useState } from 'react'
import shareIcon from '../../assets/icons/share.svg'
import { BackLink } from '../../components/BackLink'
import { Button } from '../../components/Button'
import { Divider } from '../../components/Divider'
import { DashboardLayout } from '../../components/layout/dashboard/DashboardLayout'
import { FlexButtons } from '../../components/layout/FlexBoxHelpers'
import { Tabs, TabsColor } from '../../components/Tabs'
import { Tooltip, TooltipIcon } from '../../components/Tooltip'
import { getAppPath } from '../../contracts/applications'
import { EducationTab } from './employee-profile/EducationTab'
import { EmployeeInfoBox } from './employee-profile/EmployeeInfoBox'
import { ColumnsWrapper, MainColumn, SideColumn } from './employee-profile/EmployeeProfileLayout'
import { employeeTabsUtils, EmployeeTabType } from './employee-profile/employeeTabsUtils'
import { GeneralInformation } from './employee-profile/GeneralInformation'
import { PersonalTab } from './employee-profile/PersonalTab'
import { ProjectsTab } from './employee-profile/ProjectsTab'
import { TechnicalSkillsTab } from './employee-profile/TechnicalSkillsTab'
import { HeaderWithActions } from './shared/HeaderWithActions'
import { applicationName, menuItems } from './talentManagementApp'
const EmployeeProfilePage: FunctionComponent<any> = ({ match }) => {
    const { employeeId } = match.params
    const [tab, setTab] = useState(EmployeeTabType.PERSONAL)
    const onTabChange = useCallback(tabIndex => {
        setTab(tabIndex)
    }, [])

    return (
        <DashboardLayout applicationName={applicationName} applicationMenuItems={menuItems}>
            <ColumnsWrapper>
                <MainColumn>
                    <BackLink
                        path={getAppPath('TALENT_MANAGEMENT')}
                        text='Back to List'
                        css={css`
                            margin-bottom: 16px;
                        `}
                    />
                    <HeaderWithActions header={`Employee ${employeeId}`}>
                        <FlexButtons>
                            <Button variant='link' icon={shareIcon}>
                                Share Profile
                            </Button>
                            <Button variant='primary'>Send Invitation</Button>
                        </FlexButtons>
                    </HeaderWithActions>
                    <EmployeeInfoBox />
                    <Tabs tabs={employeeTabsUtils} mode={TabsColor.BLACK} onTabChange={onTabChange} />
                    {tab === EmployeeTabType.PERSONAL && <PersonalTab />}
                    {tab === EmployeeTabType.PROJECTS && <ProjectsTab />}
                    {tab === EmployeeTabType.EDUCATION && <EducationTab />}
                    {tab === EmployeeTabType.TECHNICAL && <TechnicalSkillsTab>Technical Skills</TechnicalSkillsTab>}
                </MainColumn>
                <SideColumn>
                    <h5>
                        Profile completeness{' '}
                        <Tooltip content='This is completeness level' zIndex={100}>
                            <TooltipIcon>?</TooltipIcon>
                        </Tooltip>
                    </h5>
                    <Divider />
                    <GeneralInformation />
                </SideColumn>
            </ColumnsWrapper>
        </DashboardLayout>
    )
}

export { EmployeeProfilePage }
