/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Fragment, FunctionComponent, useCallback, useEffect } from 'react'
import { Button } from '../../../components/Button'
import { useNotifications } from '../../../components/notification/NotificationProvider'
import { Debug } from '../../../components/utils/Debug'
import { ExpandableSectionProps, More } from '../components/PresentationHelpers'

const Notifications: FunctionComponent<ExpandableSectionProps> = ({ opened }) => {
    const { addNotification, removeAllNotifications, addSuccess, addInfo, addWarning, addError, notificationStack } = useNotifications()

    const handleInternalClick = useCallback(() => {
        addInfo('Done!')
    }, [addInfo])

    const showAllVariants = useCallback(() => {
        addSuccess('Successs')
        addSuccess('Great success! '.repeat(50))
        addInfo('Info notification')
        addWarning('Warning notification')
        addError('Error notification')
        addSuccess('Woohoooo', {
            actionText: 'Add to Team',
            onActionClick: handleInternalClick,
        })
        addWarning('Woohoooo'.repeat(9), {
            actionText: 'Add to Team',
            onActionClick: handleInternalClick,
        })
    }, [addSuccess, addInfo, addWarning, addError, handleInternalClick])

    useEffect(() => {
        if (opened) {
            showAllVariants()
        }
    }, [opened, showAllVariants])

    return (
        <Fragment>
            <h5>Notification (Toasts)</h5>
            <Button variant='tertiary' onClick={() => addNotification('Default notification')}>
                Default
            </Button>
            <Button variant='delete' onClick={() => addNotification('Error notification', { appearance: 'error' })}>
                Error
            </Button>
            <Button variant='secondary' onClick={() => addNotification('Info notification', { appearance: 'info' })}>
                Info
            </Button>
            <Button onClick={() => addNotification('Success notification', { appearance: 'success' })}>Success</Button>
            <Button variant='talent' onClick={() => addNotification('Warning notification', { appearance: 'warning' })}>
                Warning
            </Button>
            <br />
            <Button variant='link' onClick={() => removeAllNotifications()}>
                Clean all
            </Button>
            <Button variant='link' onClick={showAllVariants}>
                Show all
            </Button>
            <More>
                <Debug target={{ notificationStack }} />
            </More>
        </Fragment>
    )
}

export { Notifications }
