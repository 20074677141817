/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Fragment } from 'react'
import { TimelineGroup } from '../../../components/timeline/TimelineGroup'
import { randomString } from '../../../utils/strings'

const projects = [
    {
        companyName: 'Company 1',
        positions: [
            {
                durationInMonths: 41,
                industry: 'Aviation',
                roles: ['Java Developer'],
                techStack: [],
            },
            {
                durationInMonths: 27,
                industry: 'Face Recognition System',
                roles: ['Java Developer'],
                techStack: [],
            },
            {
                durationInMonths: 19,
                industry: 'Automotive',
                roles: ['Java Developer'],
                techStack: [],
            },
        ],
    },
    {
        companyName: 'Company 4',
        positions: [
            {
                durationInMonths: 14,
                industry: 'Entertainment',
                roles: ['Java Developer'],
                techStack: [],
            },
            {
                durationInMonths: 3,
                industry: 'E-commerce',
                roles: ['Java Developer'],
                techStack: [],
            },
        ],
    },
]

const Timelines = () => {
    return (
        <Fragment>
            {projects.map(project => (
                <TimelineGroup companyName={project.companyName} positions={project.positions} key={randomString()} />
            ))}
        </Fragment>
    )
}

export { Timelines }
