/** @jsx jsx */

import { css, jsx } from '@emotion/core'
import { FunctionComponent, useCallback, useMemo } from 'react'
import { SearchLocation } from '../../contracts/common/searchLocation'
import { mqMin } from '../../GlobalStyle'
import { AutocompleteSelect } from '../autocomplete-select/AutocompleteSelect'
import { Checkbox } from '../Checkbox'
import { FlexBox } from '../layout/FlexBoxHelpers'

export type LocationsPickerProps = {
    locations: Array<SearchLocation>
    selectedLocations: Array<SearchLocation>
    selectedRemote: boolean
    onChangeLocations: (locations: Array<SearchLocation>) => void
    onChangeRemote: (remote: boolean) => void
}

const LocationsPicker: FunctionComponent<LocationsPickerProps> = ({
    locations,
    selectedLocations,
    selectedRemote,
    onChangeLocations,
    onChangeRemote,
}) => {
    const possibleCountries = useMemo(() => {
        return locations ? locations.map(location => location.country) : []
    }, [locations])

    const selectedCountries = useMemo(() => {
        return selectedLocations ? selectedLocations.map(selected => selected.country) : []
    }, [selectedLocations])

    const possibleCities = useMemo(() => {
        const filteredLocations = locations.filter(location => selectedCountries.includes(location.country))
        return filteredLocations.reduce((acc: Array<string>, { cities }) => acc.concat(cities), [])
    }, [locations, selectedCountries])

    const selectedCities = useMemo(() => {
        return selectedLocations.reduce((acc: Array<string>, { cities }) => acc.concat(cities), [])
    }, [selectedLocations])

    const onCountriesChange = useCallback(
        newCountries => {
            const newSelectedLocations: Array<SearchLocation> = []
            newCountries.forEach((newCountry: string) => {
                const foundSelectedLocation = selectedLocations.find(l => l.country === newCountry)
                if (foundSelectedLocation) {
                    newSelectedLocations.push(foundSelectedLocation) // preserve selected cities
                } else {
                    newSelectedLocations.push({ country: newCountry, cities: [] }) // new cities are empty by default
                }
            })
            onChangeLocations(newSelectedLocations)
        },
        [onChangeLocations, selectedLocations],
    )

    const onCitiesChange = useCallback(
        newCities => {
            const newSelectedLocations: Array<SearchLocation> = []
            newCities.forEach((newCity: string) => {
                // find location it belongs to
                const foundLocation = locations.find(l => l.cities.includes(newCity))
                if (foundLocation) {
                    const newLocationsIndex = newSelectedLocations.findIndex(l => l.country === foundLocation.country)
                    if (newLocationsIndex > -1) {
                        // already in new locations, add to cities array
                        newSelectedLocations[newLocationsIndex].cities.push(newCity)
                    } else {
                        // push new location object
                        newSelectedLocations.push({
                            country: foundLocation.country,
                            cities: [newCity],
                        })
                    }
                }
            })
            onChangeLocations(newSelectedLocations)
        },
        [onChangeLocations, locations],
    )

    return (
        <FlexBox
            alignItems='center'
            justifyContent='flex-start'
            css={css`
                flex-wrap: wrap;
                ${mqMin[1]} {
                    flex-wrap: nowrap;
                }
            `}
        >
            <AutocompleteSelect
                multiple
                placeholder='Country'
                options={possibleCountries}
                currentValues={selectedCountries}
                onSelectedValuesChange={onCountriesChange}
            />
            <AutocompleteSelect
                multiple
                placeholder='City'
                options={possibleCities}
                currentValues={selectedCities}
                onSelectedValuesChange={onCitiesChange}
                css={css`
                    margin: 0 12px 0 0;
                `}
            />
            <div>
                <Checkbox onChange={onChangeRemote} checked={selectedRemote} label='Remote' />
            </div>
        </FlexBox>
    )
}

export { LocationsPicker }
