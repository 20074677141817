import { IconName } from '../components/Icon'

export type ApplicationType =
    | 'VIRTUAL_BENCH'
    | 'TALENT_MANAGEMENT'
    | 'SALES'
    | 'APPLICATION_TRACKING'
    | 'PULSE_SURVEY'
    | 'PSYCHOMETRIC_MEASUREMENTS'
    | 'SYNDICATE'
    | 'CONTRACTOR_MANAGEMENT'

export type AvailabilityType = 'AVAILABLE' | 'UNAVAILABLE' | 'UPCOMING' | 'PLANNED'

export interface Application {
    id: string
    name: string
    order: number
    tooltip: string
    applicationType: ApplicationType
    availabilityType: AvailabilityType
}

// helper types

export type AppTileGroup = {
    name?: string
    apps: Array<AppTileConfig>
}

export type AppTileStatus = 'available' | 'unavailable' | 'disabled'

export type AppTileConfig = {
    to: string
    icon: IconName
    title: string
    status: AppTileStatus
    subtitle: string
    hint: string
}

const getAppIcon: (type: ApplicationType) => IconName = type => {
    switch (type) {
        case 'VIRTUAL_BENCH':
            return 'bench'
        case 'TALENT_MANAGEMENT':
            return 'user'
        case 'SALES':
            return 'sales'
        case 'APPLICATION_TRACKING':
            return 'aplication-tracking'
        case 'PULSE_SURVEY':
            return 'doc'
        case 'PSYCHOMETRIC_MEASUREMENTS':
            return 'psychometrics'
        case 'SYNDICATE':
            return 'users'
        case 'CONTRACTOR_MANAGEMENT':
            return 'contractor'
    }
    return 'small-close'
}

const getAppSubtitle: (availability: AvailabilityType) => string = availability => {
    switch (availability) {
        case 'AVAILABLE':
            return 'Available Now'
        case 'UNAVAILABLE':
            return 'Get Access'
        case 'UPCOMING':
            return 'Later Feature'
    }
    return 'Later Feature'
}

const getAppStatus: (availability: AvailabilityType) => AppTileStatus = availability => {
    switch (availability) {
        case 'AVAILABLE':
            return 'available'
        case 'UNAVAILABLE':
            return 'unavailable'
        case 'UPCOMING':
            return 'disabled'
    }
    return 'disabled'
}

const getAppPath: (type: ApplicationType) => string = type => {
    switch (type) {
        case 'VIRTUAL_BENCH':
            return '/virtual-bench'
        case 'TALENT_MANAGEMENT':
            return '/talent-management'
    }
    return ''
}

const getAppAccessPath: (type: ApplicationType) => string = type => {
    if (type === 'TALENT_MANAGEMENT') {
        return '/dashboard/company-account/company-details'
    }
    return ''
}

const app2TileFilter: (app: Application) => boolean = app => app.availabilityType !== 'PLANNED'

const app2TileConfig: (app: Application) => AppTileConfig = app => ({
    title: app.name,
    hint: app.tooltip,
    // group properties from same type when api ready
    icon: getAppIcon(app.applicationType),
    to: app.availabilityType === 'UNAVAILABLE' ? getAppAccessPath(app.applicationType) : getAppPath(app.applicationType),
    status: getAppStatus(app.availabilityType),
    subtitle: getAppSubtitle(app.availabilityType),
})

const app2TileGroups: (apps: Array<Application>) => Array<AppTileGroup> = (apps: Array<Application>) => {
    const displayed = apps.filter(app2TileFilter)
    const available = displayed.filter(app => app.availabilityType === 'AVAILABLE').map(app2TileConfig)
    const unavailable = displayed.filter(app => app.availabilityType === 'UNAVAILABLE').map(app2TileConfig)
    const upcoming = displayed.filter(app => app.availabilityType === 'UPCOMING').map(app2TileConfig)
    return [
        { name: 'Your Apps:', apps: available },
        { name: 'Apps Available with Company Account:', apps: unavailable },
        { name: 'Coming Soon:', apps: upcoming },
    ]
}

export { app2TileConfig, app2TileGroups, getAppPath }
