/** @jsx jsx */
import { css, jsx, SerializedStyles } from '@emotion/core'
import { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'
import { COLOR_PALETTE } from '../GlobalStyle'
import { Icon } from './Icon'

type BackLinkProps = { text: string; path: string; style?: SerializedStyles }
const BackLink: FunctionComponent<BackLinkProps> = ({ text, path, style, ...rest }) => {
    return (
        <Link
            css={css`
                display: block;
                color: ${COLOR_PALETTE.black};
                ${style}
            `}
            to={path}
            {...rest}
        >
            <Icon name='back' />
            <span
                css={css`
                    position: relative;
                    top: -3px;
                    margin-left: 10px;
                `}
            >
                {text}
            </span>
        </Link>
    )
}

export { BackLink }
