/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { axios } from '../../api/axios'
import { Button } from '../../components/Button'
import { Footer } from '../../components/Footer'
import { Icon } from '../../components/Icon'
import { Logo } from '../../components/Logo'
import { YellowClouds } from '../../components/YellowClouds'
import { bodyBig, COLOR_PALETTE, mqMin } from '../../GlobalStyle'
import { ReduxContext } from '../../redux/Store'
import { useQuery } from '../../utils/useQuery'

const ConfirmCompanyEmail = () => {
    const query = useQuery()
    const history = useHistory()
    const {
        selectors: { user },
    } = useContext(ReduxContext)

    useEffect(() => {
        const prospectId = query.get('prospectId')

        axios.post(`/companies/${prospectId}:confirmCompany`)
    }, [query])

    const goToDashboard = () => {
        if (user) {
            return history.push('/dashboard')
        }

        history.push('/')
    }

    return (
        <section
            css={css`
                display: flex;
                height: 100vh;
                background-color: ${COLOR_PALETTE.white};
                flex-direction: column;
            `}
        >
            <YellowClouds>
                <div
                    css={css`
                        position: absolute;
                        top: 0;
                        left: 0;
                        display: flex;
                        justify-content: space-between;
                        width: 100%;

                        padding: 50px 10px;
                        z-index: 3;
                        box-sizing: border-box;

                        ${mqMin[1]} {
                            padding: 50px 130px;
                        }
                    `}
                >
                    <Logo
                        style={css`
                            height: 38px;
                            margin-left: 0;
                            margin-top: 0;
                        `}
                        type='logoFullMono'
                    />
                </div>

                <div
                    css={css`
                        position: relative;
                        z-index: 3;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;
                        text-align: center;
                        padding: 20px;
                    `}
                >
                    <div
                        css={css`
                            height: 100px;
                            width: 100px;
                            border: 4px solid ${COLOR_PALETTE.white};
                            border-radius: 50%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            margin-bottom: 44px;
                        `}
                    >
                        <Icon
                            style={css`
                                height: 28px;
                                width: 32px;
                            `}
                            name='check'
                        />
                    </div>
                    <h2>Company Created Successfully</h2>
                    <p
                        css={css`
                            ${bodyBig}
                            margin-top: 4px;
                        `}
                    >
                        Go back to Your Human Cloud and check what is new.
                    </p>
                    <Button variant='secondary' onClick={goToDashboard}>
                        Go to Your Human Cloud
                    </Button>
                </div>
            </YellowClouds>
            <Footer />
        </section>
    )
}

export { ConfirmCompanyEmail }
