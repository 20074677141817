/** @jsx jsx */
import { css, Global, jsx } from '@emotion/core'
import emotionNormalize from 'emotion-normalize'
import { Fragment, useContext } from 'react'
import { Loader } from './components/layout/Loader'
import { ReduxContext } from './redux/Store'
import { COLOR_PALETTE } from './theme/colors'

export type BreakpointIndex = 0 | 1 | 2 | 3
const breakpoints = [375, 768, 1024, 1440]

const mqMin = breakpoints.map(bp => `@media (min-width: ${bp}px)`)
const mqMax = breakpoints.map(bp => `@media (max-width: ${bp}px)`)

const globalStyles = css`
    @import url('https://fonts.googleapis.com/css2?family=Jost:wght@300;400;500&display=swap');
    ${emotionNormalize}

    body {
        margin: 0;
        font-family: 'Jost', sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 14px;
        line-height: 20px;
    }

    * {
        box-sizing: border-box;
        outline: none;
    }

    code {
        font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-top: 0;
        margin-bottom: 1em;
    }

    h1 {
        font-weight: 500;
        font-size: 44px;
        line-height: 56px;

        ${mqMax[1]} {
            font-size: 32px;
            line-height: 38px;
        }
    }

    h2 {
        font-weight: 500;
        font-size: 34px;
        line-height: 40px;

        ${mqMax[1]} {
            font-size: 28px;
            line-height: 36px;
        }
    }

    h3 {
        font-weight: 500;
        font-size: 26px;
        line-height: 36px;

        ${mqMax[1]} {
            font-size: 24px;
            line-height: 30px;
        }
    }

    h4 {
        font-weight: 500;
        font-size: 24px;
        line-height: 30px;

        ${mqMax[1]} {
            font-size: 20px;
            line-height: 26px;
        }
    }

    h5 {
        font-weight: 500;
        font-size: 20px;
        line-height: 26px;

        ${mqMax[1]} {
            font-size: 16px;
        }
    }

    h6 {
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
    }

    small {
        font-size: 12px;
        line-height: 16px;
    }

    a {
        text-decoration: none;
        color: ${COLOR_PALETTE.black};
    }
    a:not([class]) {
        &:hover {
            text-decoration: underline;
            text-decoration-color: ${COLOR_PALETTE.yellow};
        }
    }

    strong {
        font-weight: 500;
    }

    .mark-down {
        h1 {
            font-size: 20px;
        }
    }
`

const bodyBig = css`
    font-size: 18px;
    line-height: 26px;

    ${mqMax[1]} {
        font-size: 16px;
        line-height: 22px;
    }
`

const bodyBold = css`
    font-weight: 500;
`

const bodyBigHighlight = css`
    ${bodyBig}
    ${bodyBold}
`

const verySmall = css`
    font-size: 11px;
    line-height: 14px;
`

const underlinedText = css`
    position: relative;
    display: inline-block;

    &:before {
        position: absolute;
        bottom: 10px;
        left: 0;
        content: '';
        height: 4px;
        background-color: ${COLOR_PALETTE.white};
        width: 100%;
        z-index: -1;
    }
`

const GlobalStylesProvider = ({ children }: { children: any }): any => {
    const {
        selectors: { showLoader, user },
    } = useContext(ReduxContext)

    return (
        <Fragment>
            <Global styles={globalStyles} />
            {showLoader && <Loader disableOpacity={user === null} />}
            {children}
        </Fragment>
    )
}

export { GlobalStylesProvider, bodyBig, bodyBold, bodyBigHighlight, verySmall, mqMin, mqMax, COLOR_PALETTE, underlinedText }
