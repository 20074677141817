/** @jsx jsx */
import styled from '@emotion/styled'
import { BreakpointIndex, mqMin } from '../../GlobalStyle'

type FlexBoxProps = {
    justifyContent?: string
    alignItems?: string
}

const FlexBox = styled.div<FlexBoxProps>`
    display: flex;
    justify-content: ${({ justifyContent = 'flex-start' }) => justifyContent};
    align-items: ${({ alignItems = 'flex-start' }) => alignItems};
`

const FlexButtons = styled(FlexBox)`
    margin-right: -4px;
    margin-left: -4px;
    > * {
        margin-right: 4px;
        margin-left: 4px;
    }
`

type FlexColumnsProps = FlexBoxProps & {
    breakAt?: BreakpointIndex
}

const FlexColumns = styled(FlexBox)<FlexColumnsProps>`
    width: 100%;
    margin-right: -8px;
    margin-left: -8px;
    flex-direction: column;
    ${({ breakAt = 1 }) => mqMin[breakAt]} {
        flex-direction: row;
    }
`

type FlexColumnProps = {
    shrinkable?: boolean
    minWidth?: string
}

const FlexColumn = styled.div<FlexColumnProps>`
    width: 100%;
    margin-left: 8px;
    margin-right: 8px;
    min-height: 56px;
    min-width: ${props => (props.minWidth ? props.minWidth : 'auto')};
    flex-shrink: ${props => (props.shrinkable ? 2 : 1)};
`

export { FlexBox, FlexColumns, FlexColumn, FlexButtons }
