/** @jsx jsx */
import { css, jsx, SerializedStyles } from '@emotion/core'
import { FunctionComponent, ImgHTMLAttributes } from 'react'
import logoFullInverted from '../assets/logos/logo-full-inverted.svg'
import logoFullMono from '../assets/logos/logo-full-mono.svg'
import logoFull from '../assets/logos/logo-full.svg'
import logoInverted from '../assets/logos/logo-inverted.svg'
import logoMono from '../assets/logos/logo-mono.svg'
import logo from '../assets/logos/logo.svg'
import { mqMax } from '../GlobalStyle'

export type LogoType = 'logo' | 'logoMono' | 'logoInverted' | 'logoFull' | 'logoFullMono' | 'logoFullInverted'

const getSvg = (name: LogoType): string => {
    switch (name) {
        case 'logo':
            return logo
        case 'logoMono':
            return logoMono
        case 'logoInverted':
            return logoInverted
        case 'logoFull':
            return logoFull
        case 'logoFullMono':
            return logoFullMono
        case 'logoFullInverted':
            return logoFullInverted
    }
}
type LogoProps = ImgHTMLAttributes<HTMLImageElement> & { type?: LogoType; style?: SerializedStyles }
const Logo: FunctionComponent<LogoProps> = ({ type = 'logo', style, ...rest }) => {
    return (
        <img
            alt='Talent Alpha Logo'
            css={css`
                width: 112px;
                height: 40px;

                ${mqMax[0]} {
                    width: 106px;
                    height: 38px;
                }

                ${style}
            `}
            src={getSvg(type)}
            {...rest}
        />
    )
}

export { Logo }
