/** @jsx jsx */
import { css, jsx, SerializedStyles } from '@emotion/core'
import styled from '@emotion/styled'
import { useEffect, useRef, useState } from 'react'
import { COLOR_PALETTE } from '../GlobalStyle'
import { Icon } from './Icon'

export enum TabsColor {
    YELLOW,
    BLACK,
}
interface TabsProps {
    tabs: string[]
    mode?: TabsColor
    onTabChange?: (tabIndex: number) => void
    withArrows?: boolean
}

const YellowTabsStyles = (selectedTab: number) => {
    return css`
        ul > li {
            background-color: ${COLOR_PALETTE.grey_lighter};
            color: ${COLOR_PALETTE.black};
            padding: 16px 21px 8px;
            border-top: 2px solid ${COLOR_PALETTE.grey_lighter};
            border-left: 1px solid ${COLOR_PALETTE.grey_lighter};
            border-right: 1px solid ${COLOR_PALETTE.grey_lighter};
            border-bottom: 1px solid ${COLOR_PALETTE.grey_light};

            &.active {
                background-color: ${COLOR_PALETTE.white};
                border-top: 2px solid ${COLOR_PALETTE.yellow};
                border-left: 1px solid ${COLOR_PALETTE.grey_light};
                border-right: 1px solid ${COLOR_PALETTE.grey_light};
                border-bottom: 0;
            }
        }
    `
}

const BlackTabsStyles = (selectedTab: number) => {
    return css`
        ul {
            border-bottom: 1px solid ${COLOR_PALETTE.black};
            height: 34px;

            & > li {
                display: flex;
                align-items: center;
                padding: 0 22px;

                &.active {
                    background-color: ${COLOR_PALETTE.black};
                    color: ${COLOR_PALETTE.white};
                }
            }
        }
    `
}

const ArrowIcon = styled.button`
    width: 22px;
    height: 22px;
    background-color: ${COLOR_PALETTE.grey_light};
    border-radius: 50px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all linear 0.3s;
    border: 0;
    box-shadow: none;

    svg {
        width: 10px;
    }
    &:hover {
        background-color: ${COLOR_PALETTE.black};

        path {
            fill: ${COLOR_PALETTE.white};
        }
    }
`

const ArrowIconContainer = styled.div`
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
`

const Tabs = ({
    tabs,
    mode = TabsColor.YELLOW,
    onTabChange,
    withArrows,
    ...other
}: {
    tabs: string[]
    mode?: TabsColor
    onTabChange?: (tabIndex: number) => void
    withArrows?: boolean
}) => {
    const wrapper = useRef<HTMLDivElement>(null)
    const child = useRef<HTMLSpanElement>(null)
    const [selectedTab, setSelectedTab] = useState(0)
    const [shift, setShift] = useState(0)
    const [currentPosition, setCurrentPosition] = useState(0)
    const [wrapperWidth, setWrapperWidth] = useState(0)
    const [totalChildrenWidth, setTotalChildrenWidth] = useState(0)
    const [deepChildren, setDeepChildren] = useState<Array<ChildNode>>([])
    const [chevronsEnabled, setChevronsEnabled] = useState(false)
    const [isLeftArrowVisible, setIsLeftArrowVisible] = useState(false)
    const [isRightArrowVisible, setIsRightArrowVisible] = useState(true)

    useEffect(() => {
        if (withArrows) {
            setChildren()

            if (child.current && wrapper.current) {
                setChevronsEnabled(child.current.clientWidth > wrapper.current.clientWidth)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [withArrows])

    const tabSelectionChange = (index: number) => {
        setSelectedTab(index)
        if (onTabChange) {
            onTabChange(index)
        }
    }

    const setStyles = (tabsColor: TabsColor, selected: number): SerializedStyles => {
        switch (tabsColor) {
            case TabsColor.YELLOW:
                return YellowTabsStyles(selected)
            case TabsColor.BLACK:
                return BlackTabsStyles(selected)
            default:
                return YellowTabsStyles(selected)
        }
    }

    const switchLeft = () => {
        doSwitch('left')
    }

    const switchRight = () => {
        doSwitch('right')
    }

    const doSwitch = (value: string) => {
        setChildren()

        if (deepChildren.length === 0) {
            return
        }

        const positive = value === 'left'
        const currentElement = deepChildren[currentPosition] as HTMLElement

        if (currentElement) {
            if (positive) {
                const newShift = shift + currentElement.clientWidth
                const isSmallerThanLeftBound = newShift > 0

                if (isSmallerThanLeftBound) {
                    setIsLeftArrowVisible(false)
                }

                setShift(isSmallerThanLeftBound ? 0 : newShift)
                setCurrentPosition(currentPosition - 1)
                setIsRightArrowVisible(true)
            } else {
                const newShift = shift - currentElement.clientWidth
                let minShift
                let isGreaterThanRightBound

                if (wrapperWidth && child.current) {
                    minShift = wrapperWidth - child.current.clientWidth
                    isGreaterThanRightBound = newShift < minShift
                }

                if (isGreaterThanRightBound) {
                    setIsRightArrowVisible(false)

                    if (minShift) {
                        setShift(minShift)
                    }
                } else {
                    setShift(newShift)
                }

                setCurrentPosition(currentPosition + 1)
                setIsLeftArrowVisible(true)
            }
        }

        correctEdgeValues(deepChildren)
    }

    const setChildren = () => {
        if (wrapper.current) {
            setWrapperWidth(wrapper.current.clientWidth)

            if (child.current?.hasChildNodes()) {
                setDeepChildren(filterChildNodes(child.current.childNodes[0].childNodes))
                setTotalChildrenWidth(child.current.getBoundingClientRect().width)
            } else {
                setDeepChildren([])
                setTotalChildrenWidth(0)
            }
        }
    }

    const filterChildNodes = (nodes: NodeListOf<ChildNode>): Array<ChildNode> => {
        const nodesArray = Array.from(nodes)

        return nodesArray.filter(value => (value as HTMLElement).clientWidth !== undefined)
    }

    const correctEdgeValues = (deepChild: Array<any>) => {
        if (currentPosition < 0) {
            setCurrentPosition(0)
        }

        if (currentPosition >= deepChild.length) {
            setCurrentPosition(deepChild.length - 1)
        }

        if (totalChildrenWidth < wrapperWidth + Math.abs(shift)) {
            const newShift = wrapperWidth - totalChildrenWidth
            setShift(newShift > 0 ? 0 : newShift)
        }

        if (shift > 0) {
            setShift(0)
        }
    }

    return (
        <div
            css={[
                css`
                    overflow-x: auto;
                    display: flex;
                `,
                setStyles(mode, selectedTab),
            ]}
            {...other}
        >
            {chevronsEnabled && withArrows && (
                <ArrowIconContainer>
                    {isLeftArrowVisible && (
                        <ArrowIcon onClick={switchLeft}>
                            <Icon
                                name='arrow-down'
                                style={css`
                                    transform: rotate(90deg);
                                `}
                            />
                        </ArrowIcon>
                    )}
                </ArrowIconContainer>
            )}
            <div
                css={css`
                    overflow-x: hidden;
                    width: 100%;

                    ${chevronsEnabled &&
                    withArrows &&
                    css`
                        width: calc(100% - 80px);
                    `}
                `}
            >
                <div
                    ref={wrapper}
                    css={css`
                        display: flex;
                        flex-wrap: nowrap;
                        flex-direction: row;
                        align-items: stretch;
                        font-size: 12px;
                        transform: translateX(${shift}px);
                    `}
                >
                    <span
                        ref={child}
                        css={css`
                            ${!withArrows &&
                            css`
                                width: 100%;
                            `}
                        `}
                    >
                        <ul
                            css={css`
                                list-style: none;
                                padding: 0;
                                margin: 0;
                                display: flex;
                            `}
                        >
                            {tabs.map((tabName, index) => (
                                <li
                                    onClick={() => tabSelectionChange(index)}
                                    key={`tab-${tabName}-${index}`}
                                    css={css`
                                        text-align: center;
                                        cursor: pointer;
                                        white-space: nowrap;
                                        box-sizing: border-box;
                                    `}
                                    className={selectedTab === index ? 'active' : undefined}
                                >
                                    {tabName}
                                </li>
                            ))}
                        </ul>
                    </span>
                </div>
            </div>
            {chevronsEnabled && withArrows && (
                <ArrowIconContainer>
                    {isRightArrowVisible && (
                        <ArrowIcon onClick={switchRight}>
                            <Icon
                                name='arrow-down'
                                style={css`
                                    transform: rotate(-90deg);
                                `}
                            />
                        </ArrowIcon>
                    )}
                </ArrowIconContainer>
            )}
        </div>
    )
}

export { Tabs }
