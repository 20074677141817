/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Fragment, FunctionComponent } from 'react'
import { AppearanceTypes, DefaultToast, ToastProps } from 'react-toast-notifications'
import { COLOR_PALETTE } from '../../GlobalStyle'
import { Button } from '../Button'
import { blackToWhiteFilter, Icon, IconName } from '../Icon'

type NotificationProps = ToastProps

const WrapperBaseStyles = css`
    min-width: 360px;
    max-width: 547px;
    padding: 4px 0px;
    .react-toast-notifications__toast__icon-wrapper {
        display: none; // we handle icons our way
    }
    .react-toast-notifications__toast__countdown {
    }
    .react-toast-notifications__toast__content {
        display: flex;
        align-items: center;
        min-height: 24px;
        padding: 0px 0px;
        font-size: 12px;
        line-height: 15px;
    }
    .react-toast-notifications__toast__dismiss-button {
        color: inherit;
        opacity: 0.7;
        padding: 0px 12px;
        display: flex;
        align-items: center;
    }
    .react-toast-notifications__toast__dismiss-icon {
        height: 12px;
        width: 12px;
    }
`

const getVisualProps = (appearance: AppearanceTypes) => {
    let iconName: IconName = 'back'
    let iconStyle
    let wrapperStyle

    switch (appearance) {
        case 'success':
            iconName = 'check'
            iconStyle = css`
                color: ${COLOR_PALETTE.black};
            `
            wrapperStyle = css`
                color: ${COLOR_PALETTE.black};
                background-color: ${COLOR_PALETTE.yellow};
            `
            break
        case 'warning':
            iconName = 'notification'
            iconStyle = css`
                filter: ${blackToWhiteFilter};
            `
            wrapperStyle = css`
                color: ${COLOR_PALETTE.white};
                background-color: ${COLOR_PALETTE.violet_darker};
            `
            break
        case 'error':
            iconName = 'alert'
            iconStyle = css`
                color: ${COLOR_PALETTE.red};
            `
            wrapperStyle = css`
                color: ${COLOR_PALETTE.red};
                background-color: ${COLOR_PALETTE.red_lighter};
            `
            break
        default:
            // 'info'
            iconName = 'notification' // TODO: change to "i"
            iconStyle = css`
                color: black;
            `
            wrapperStyle = css`
                background-color: ${COLOR_PALETTE.blue_lighter};
            `
            break
    }

    return { iconName, iconStyle, wrapperStyle }
}
const Notification: FunctionComponent<NotificationProps> = ({ children, appearance, ...rest }) => {
    const { iconName, iconStyle, wrapperStyle } = getVisualProps(appearance)
    return (
        <DefaultToast
            appearance={appearance}
            css={css`
                ${WrapperBaseStyles}
                ${wrapperStyle}
            `}
            {...rest}
        >
            <span
                css={css`
                    margin-right: 16px;
                    margin-left: 8px;
                    display: flex;
                    align-items: center;
                `}
            >
                <Icon name={iconName} css={iconStyle} size={16} />
            </span>
            <span
                css={css`
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-grow: 1;
                    word-break: break-word;
                `}
            >
                {children}
            </span>
        </DefaultToast>
    )
}

export type NotificationContentProps = {
    appearance?: AppearanceTypes
    actionText?: string
    onActionClick?: () => void
}

const NotificationContent: FunctionComponent<NotificationContentProps> = ({ children, actionText, onActionClick, appearance }) => {
    return (
        <Fragment>
            <span>{children}</span>
            {actionText && onActionClick && (
                <Button
                    variant={appearance === 'warning' ? 'talent' : 'secondary'}
                    size='small'
                    onClick={onActionClick}
                    css={css`
                        margin-left: 12px;
                        flex-shrink: 0;
                    `}
                >
                    {actionText}
                </Button>
            )}
        </Fragment>
    )
}

export { Notification, NotificationContent }
