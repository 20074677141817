/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import styled from '@emotion/styled'
import { FunctionComponent } from 'react'
import { Progress } from '../../components/antd'
import { COLOR_PALETTE } from '../../theme/colors'
import { FlexBox } from '../layout/FlexBoxHelpers'

const CircularProgressLabel = styled.span`
    color: ${COLOR_PALETTE.violet_darker};
    font-size: 12px;
    padding-left: 6px;
`

type CircularProgressProps = {
    percent: number
    color?: string
}

const CircularProgress: FunctionComponent<CircularProgressProps> = ({ percent, color = COLOR_PALETTE.violet_darker }) => {
    return (
        <FlexBox>
            <Progress
                type='circle'
                percent={percent * 0.95}
                width={16}
                showInfo={false}
                trailColor={COLOR_PALETTE.grey_light}
                strokeColor={color}
                strokeWidth={20}
                strokeLinecap='square'
                css={css`
                    margin-top: -4px; // TODO: adjust after positioning problem research
                `}
            />
            <CircularProgressLabel>{percent}%</CircularProgressLabel>
        </FlexBox>
    )
}

export { CircularProgress }
