/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { getCompanies } from '../../../api/api'
import { DashboardLayout, MenuType } from '../../../components/layout/dashboard/DashboardLayout'
import { ReduxContext } from '../../../redux/Store'
import { CompanyAccount } from './components/CompanyAccount'

const CompanyDetails = () => {
    const {
        selectors: { firebaseId },
    } = useContext(ReduxContext)

    const [company, setCompany] = useState()
    const [showWizard, setShowWizard] = useState(false)
    const history = useHistory()

    useEffect(() => {
        if (firebaseId) {
            getCompanies(firebaseId).then((companies: Array<any>) => {
                if (companies.length > 0) {
                    const firstCompany = companies.pop()

                    if (firstCompany.status === 'VERIFIED') {
                        return history.push('/dashboard/company-account/company-details/form')
                    }

                    setCompany(firstCompany)
                }

                setShowWizard(true)
            })
        }
    }, [firebaseId, history])

    return (
        <DashboardLayout menuType={MenuType.companyAccount}>
            <div
                css={css`
                    display: flex;
                    width: 100%;
                `}
            >
                {showWizard && <CompanyAccount company={company} />}
            </div>
        </DashboardLayout>
    )
}

export { CompanyDetails }
