export interface Employee {
    city: string
    country: string
    email: string
    employmentType: string
    firstName: string
    id: string
    lastName: string
    profileStrength: number
    remote: boolean
    role: string
    seniority: string
    topSkills: Array<string>
}

type EmploymentTypeMapping = {
    [k: string]: string
}

const employmentTypesMapping: EmploymentTypeMapping = {
    B2B: 'B2B',
    CANDIDATE: 'Candidate',
    CONTRACTOR: 'Contractor',
    FULL_TIME: 'Full Time Employee',
}

const mapEmploymentType = (type: string) => {
    return employmentTypesMapping[type] || type
}

export { mapEmploymentType }
