/** @jsx jsx */
import { jsx } from '@emotion/core'
import { FunctionComponent, useContext, useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { getUser, sendCompanyEmailConfirmation } from '../../../../api/api'
import { Button } from '../../../../components/Button'
import { Divider } from '../../../../components/Divider'
import { Info } from '../../../../components/Info'
import { SettingsWrapper } from '../../../../components/layout/ResponsiveWrapper'
import { useNotifications } from '../../../../components/notification/NotificationProvider'
import { ReduxContext } from '../../../../redux/Store'
import { Nullable } from '../../../../types'

const CreateCompanyAccountPossible: FunctionComponent = () => (
    <Info>
        <h6>Create Company Account</h6>
        <p>Create a Company Account to unleash the full potential of the Human Cloud.</p>
        <Divider />
        <Link to='/dashboard/company-account/company-details/form'>
            <Button>Create Company Account</Button>
        </Link>
    </Info>
)

const CreateCompanyAccountImpossible: FunctionComponent = () => (
    <Info>
        <h6>Create Company Account</h6>
        <p>
            You will be able to create a Company Account once you fill in your personal details. Create a Company Account to unleash the
            full potential of the Human Cloud.
        </p>
        <Divider />
        <Link to='/dashboard/my-account/personal-details'>
            <Button>Personal Data Settings</Button>
        </Link>
        <Divider />
        <Button disabled>Create Company Account</Button>
    </Info>
)

const ConfirmCompanyEmail = ({
    workEmail,
    handleBack,
    handleResendClick,
}: {
    workEmail: string
    handleBack: () => void
    handleResendClick: () => void
}) => (
    <Info>
        <h6>Confirm Company Account</h6>
        <p>
            Check your work email <strong>{workEmail}</strong> to confirm Company Account.
        </p>
        <Divider />
        <Button variant='link' onClick={handleResendClick}>
            Resend Email
        </Button>
        <br />
        <Button variant='link' onClick={handleBack}>
            Back to Company Details
        </Button>
    </Info>
)

const CompanyAccount = ({ company }: { company?: any }) => {
    const {
        selectors: { user },
    } = useContext(ReduxContext)

    const { addSuccess } = useNotifications()

    const history = useHistory()
    const [workEmail, setWorkEmail] = useState('')
    const [companyId, setCompanyId] = useState('')
    const [hasPersonalDetails, setHasPersonalDetails] = useState<Nullable<boolean>>(null)

    useEffect(() => {
        if (user.firebaseId) {
            getUser(user.firebaseId).then(userData => {
                if (userData.email && userData.firstName && userData.lastName && userData.phone) {
                    return setHasPersonalDetails(true)
                }

                setHasPersonalDetails(false)
            })
        }

        if (company) {
            setWorkEmail(company.workEmailAddress)
            setCompanyId(company.id)
        }
    }, [company, user.firebaseId])

    const handleResendClick = () => {
        sendCompanyEmailConfirmation(companyId).then(() => {
            addSuccess('The confirmation email has been sent successfully')
        })
    }

    const handleBack = () => {
        history.push('/dashboard/company-account/company-details/form')
    }

    return (
        <SettingsWrapper>
            <h4>Company Account</h4>
            <Divider />
            <h5>Company Details</h5>
            {hasPersonalDetails && !company && <CreateCompanyAccountPossible />}
            {hasPersonalDetails === false && <CreateCompanyAccountImpossible />}
            {hasPersonalDetails && company && (
                <ConfirmCompanyEmail workEmail={workEmail} handleBack={handleBack} handleResendClick={handleResendClick} />
            )}
        </SettingsWrapper>
    )
}

export { CompanyAccount }
