import { format } from 'date-fns'

const defaultFormat = 'dd/MM/yyyy'

const formatDate = (stringDate: string, dateFormat = defaultFormat): string => {
    if (stringDate) {
        return format(new Date(stringDate), dateFormat)
    }

    return ''
}

const convertTimestampToDateString = (timestamp: number, dateFormat = defaultFormat) => {
    const date = new Date(timestamp * 1000)

    return formatDate(date.toDateString(), dateFormat)
}

export { formatDate, convertTimestampToDateString }
