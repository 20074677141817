/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Fragment, FunctionComponent } from 'react'
import { Badge, TalentBadge } from '../../../components/Badge'

const Badges: FunctionComponent = () => {
    return (
        <Fragment>
            <h5>Badges</h5>
            <Badge>Default</Badge>
            <Badge variant='solid'>Solid</Badge>
            <Badge variant='outlined'>Outlined</Badge>
            <Badge variant='outlined' disabled>
                Outlined, disabled
            </Badge>
            <br />
            <TalentBadge>Default</TalentBadge>
            <TalentBadge variant='primary'>Senior</TalentBadge>
            <TalentBadge variant='secondary'>Mid</TalentBadge>
            <TalentBadge variant='tertiary'>Junior</TalentBadge>
            <br />
        </Fragment>
    )
}

export { Badges }
