/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Awards } from './Awards'
import { YellowClouds } from './YellowClouds'

const AwardsBox = () => {
    return (
        <YellowClouds>
            <Awards />
        </YellowClouds>
    )
}

export { AwardsBox }
