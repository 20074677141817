/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import styled from '@emotion/styled'
import { Fragment, FunctionComponent, useCallback, useState } from 'react'
import { Link } from 'react-router-dom'
import { RoleType, Specialist, SpecialistRole } from '../../contracts/search/specialist'
import { COLOR_PALETTE, mqMax } from '../../GlobalStyle'
import { TalentBadge } from '../Badge'
import { IconButton } from '../Icon'
import { TextHighlight } from '../TextHighlight'
import { TalentBoxLabelBlack, TalentBoxLabelGrey } from './TalentResultBox'

const TalentBoxRoleInfoRow = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    font-weight: 500;
    margin: 0 0 5px;

    .talent-box-label {
        width: auto;
        padding-bottom: 0;
    }
`

export interface TalentBoxRolesInfoProps {
    talent: Specialist
    searchWords: Array<string>
    onToggleFavorite: (favorite: boolean, specialistId: string) => Promise<any>
}

const TalentBoxRolesInfo: FunctionComponent<TalentBoxRolesInfoProps> = ({ talent, searchWords, onToggleFavorite }) => {
    const [isFavorite, setIsFavorite] = useState(talent.favorite)

    const handleToggleFavorite = useCallback(() => {
        onToggleFavorite(!isFavorite, talent.specialistId)
            .then(() => setIsFavorite(!isFavorite))
            .finally()
    }, [isFavorite, talent.specialistId, onToggleFavorite])

    const renderRoleByType = (rolesInfo: Array<SpecialistRole>, roleType: RoleType) => {
        const foundRole = rolesInfo.find(role => role.roleType === roleType)

        if (foundRole) {
            return (
                <Fragment>
                    {foundRole.roleType === RoleType.PRIMARY ? (
                        <TalentBoxLabelBlack className='talent-box-label'>
                            <TextHighlight searchWords={searchWords} text={foundRole.role} />
                        </TalentBoxLabelBlack>
                    ) : (
                        <TalentBoxLabelGrey className='talent-box-label'>
                            <TextHighlight searchWords={searchWords} text={foundRole.role} />
                        </TalentBoxLabelGrey>
                    )}
                    {foundRole.roleSeniority && (
                        <TalentBadge variant={foundRole.roleType === RoleType.PRIMARY ? 'primary' : 'tertiary'}>
                            {foundRole.roleSeniority.toString().toLowerCase()}
                        </TalentBadge>
                    )}
                </Fragment>
            )
        }

        return roleType === RoleType.PRIMARY ? 'Primary role has not been set' : ''
    }

    if (talent) {
        return (
            <div
                css={css`
                    padding: 19px 16px 12px;
                    border-bottom: 1px solid ${COLOR_PALETTE.grey_light};
                    position: relative;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;

                    .talent-box-label {
                        margin-right: 10px;
                    }
                `}
            >
                <div>
                    <span
                        css={css`
                            display: block;
                            font-size: 12px;
                            color: ${COLOR_PALETTE.grey_dark};
                        `}
                    >
                        Current Roles
                    </span>
                    <TalentBoxRoleInfoRow>{renderRoleByType(talent.rolesInfo, RoleType.PRIMARY)}</TalentBoxRoleInfoRow>
                    <TalentBoxRoleInfoRow>{renderRoleByType(talent.rolesInfo, RoleType.SECONDARY)}</TalentBoxRoleInfoRow>
                </div>
                <div
                    className='contact-us-buttons'
                    css={css`
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                        position: absolute;
                        top: 0;
                        right: 0;
                        opacity: 1;
                        height: 100%;
                        padding: 0 16px;

                        ${mqMax[1]} {
                            opacity: 1;
                            justify-content: space-between;
                            width: 100%;
                            padding: 16px 0 4px;
                            position: relative;
                        }
                    `}
                >
                    <Link
                        to={`/profile/${talent.specialistId}`}
                        css={css`
                            margin: 0 8px;
                        `}
                    >
                        <IconButton name='profile-details' variant='outlined' size={40} />
                    </Link>
                    <IconButton name='bookmark' variant='outlined' size={40} pressed={isFavorite} onClick={handleToggleFavorite} />
                </div>
            </div>
        )
    }

    return null
}

export { TalentBoxRolesInfo }
