import styled from '@emotion/styled'
import { COLOR_PALETTE, mqMin } from '../../../GlobalStyle'

const ColumnsWrapper = styled.div`
    max-width: 1440px;
    margin: 0 auto;
    display: flex;
    align-items: stretch;
    flex-direction: column;
    ${mqMin[2]} {
        flex-direction: row;
    }
`

const MainColumn = styled.div`
    padding-top: 16px;
    padding-left: 16px;
    padding-right: 16px;
    flex-grow: 1;
    ${mqMin[2]} {
        padding: 16px 64px;
    }
`

const SideColumn = styled.div`
    padding-top: 16px;
    padding-left: 16px;
    padding-right: 16px;
    ${mqMin[2]} {
        padding-left: 24px;
        width: 376px;
        border-left: 1px solid ${COLOR_PALETTE.grey_light};
    }
`

export { ColumnsWrapper, MainColumn, SideColumn }
