/** @jsx jsx */
import { jsx } from '@emotion/core'
import styled from '@emotion/styled'
import { Fragment, FunctionComponent } from 'react'
import { Education as EducationModel } from '../contracts/profile/education'
import { COLOR_PALETTE } from '../GlobalStyle'

const EducationContainer = styled.div`
    background-color: ${COLOR_PALETTE.white};
    padding: 26px 36px 24px;
    margin-top: 24px;
    border: 1px solid ${COLOR_PALETTE.grey_light};
`

const EducationHeader = styled.div`
    border-bottom: 1px solid ${COLOR_PALETTE.grey_light};
    padding-bottom: 20px;
    margin-bottom: 2px;
`

const EducationDetailRow = styled.div`
    display: flex;
    margin: 14px 0;

    &:last-of-type {
        margin-bottom: 0;
    }
`

const EducationTitle = styled.small`
    flex: 2;
    color: ${COLOR_PALETTE.grey};
`

const EducationValue = styled.p`
    margin: 0;
    flex: 5;
`

type EducationProps = { education: EducationModel }

const Education: FunctionComponent<EducationProps> = ({ education }) => {
    return (
        <Fragment>
            <EducationContainer>
                {education.type === 'EDUCATION' && (
                    <Fragment>
                        <EducationHeader>Studies</EducationHeader>

                        {education.specialization && (
                            <EducationDetailRow>
                                <EducationTitle>Course:</EducationTitle>
                                <EducationValue>{education.specialization}</EducationValue>
                            </EducationDetailRow>
                        )}

                        {education.faculty && (
                            <EducationDetailRow>
                                <EducationTitle>Faculty:</EducationTitle>
                                <EducationValue>{education.faculty}</EducationValue>
                            </EducationDetailRow>
                        )}

                        {education.school && (
                            <EducationDetailRow>
                                <EducationTitle>University:</EducationTitle>
                                <EducationValue>{education.school}</EducationValue>
                            </EducationDetailRow>
                        )}
                    </Fragment>
                )}

                {(education.type === 'COURSE' || education.type === 'CERTIFICATE') && (
                    <Fragment>
                        <EducationHeader>{education.type === 'COURSE' ? 'Course' : 'Certificate'}</EducationHeader>

                        {education.name && (
                            <EducationDetailRow>
                                <EducationTitle>Subject:</EducationTitle>
                                <EducationValue>{education.name}</EducationValue>
                            </EducationDetailRow>
                        )}

                        {education.school && (
                            <EducationDetailRow>
                                <EducationTitle>Name:</EducationTitle>
                                <EducationValue>{education.school}</EducationValue>
                            </EducationDetailRow>
                        )}
                    </Fragment>
                )}
            </EducationContainer>
        </Fragment>
    )
}

export { Education }
