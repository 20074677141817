/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Fragment, FunctionComponent, useState } from 'react'
import { Button } from '../../../components/Button'
import { Divider } from '../../../components/Divider'
import { Input } from '../../../components/Input'
import { Modal } from '../../../components/Modal'

const Modals: FunctionComponent = () => {
    const [opened, setOpened] = useState(false)
    const handleClose = () => setOpened(false)
    return (
        <Fragment>
            <h5>Modals</h5>
            <Button onClick={() => setOpened(true)}>Open modal</Button>
            <Modal onClose={handleClose} opened={opened}>
                <h5>Header</h5>
                <p>Some internal content</p>
                <Input name='modal' placeholder='Input' />
                <Divider />
                <Button css={css`margin-right: 8px`}>Send</Button>
                <Button variant='secondary' onClick={handleClose}>Cancel</Button>
            </Modal>
        </Fragment>
    )
}

export { Modals }
