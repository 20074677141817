/** @jsx jsx */
import { css, jsx, keyframes, SerializedStyles } from '@emotion/core'
import styled from '@emotion/styled'
import { FunctionComponent, useState } from 'react'
import { COLOR_PALETTE } from '../GlobalStyle'

type DynamicStyles = (props: any) => SerializedStyles

const fadeAnimation = keyframes`
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
`

const animation: DynamicStyles = props => css`
    animation: ${props.fadeDuration}ms ${props.fadeEasing} 0s 1 ${fadeAnimation};
`

const tooltipStyles: DynamicStyles = props => {
    const arrowSize = 8
    return css`
        position: absolute;
        min-width: 190px;
        bottom: 100%;
        left: 50%;
        transform: translateX(-${arrowSize * 2}px);
        padding: 10px;
        ${props.zIndex && `z-index: ${props.zIndex};`};
        background: ${COLOR_PALETTE.black};
        color: ${COLOR_PALETTE.white};
        ${props.fadeDuration && props.fadeDuration > 0 && animation(props)};
        margin-bottom: ${props.offset}px;
        font-size: 14px;
        font-weight: 400;
        &:after {
            display: block;
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            border-left: ${arrowSize}px solid transparent;
            border-right: ${arrowSize}px solid transparent;
            border-top: ${arrowSize}px solid ${COLOR_PALETTE.black};
            bottom: -${arrowSize}px;
        }
    `
}

type TooltipBubbleProps = {
    offset?: number
    zIndex?: number
    fadeEasing?: string
    fadeDuration?: number
}

const TooltipBubble: FunctionComponent<TooltipBubbleProps> = ({ children, ...props }) => <div css={tooltipStyles(props)}>{children}</div>

type TooltipProps = TooltipBubbleProps & {
    content: string
    opened?: boolean
}

const Tooltip: FunctionComponent<TooltipProps> = ({
    children,
    content,
    opened = false,
    offset = 13,
    zIndex = 0,
    fadeDuration = 800,
    fadeEasing = 'ease-in',
}) => {
    const [open, setOpen] = useState(opened)

    const handleMouseEnter = () => {
        setOpen(true)
    }
    const handleMouseLeave = () => {
        setOpen(false)
    }
    const handleTouch = () => {
        setOpen(!open)
    }

    return (
        <span
            css={css`
                position: relative;
                display: inline-flex;
            `}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onTouchEnd={handleTouch}
        >
            {children}
            {open && (
                <TooltipBubble offset={offset} zIndex={zIndex} fadeDuration={fadeDuration} fadeEasing={fadeEasing}>
                    {content}
                </TooltipBubble>
            )}
        </span>
    )
}

const TooltipIcon = styled.div`
    width: 14px;
    height: 14px;
    border-radius: 2px;
    background-color: ${COLOR_PALETTE.grey_light};
    font-size: 12px;
    color: ${COLOR_PALETTE.grey_dark};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 4px;
    cursor: default;
`

export { Tooltip, TooltipIcon }
