/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import styled from '@emotion/styled'
import { FunctionComponent } from 'react'
import { Specialist } from '../../contracts/search/specialist'
import { COLOR_PALETTE } from '../../GlobalStyle'
import { getStringFromArray } from '../../utils/lists'
import { TextHighlight } from '../TextHighlight'
import { SkillsList } from './SkillsList'
import { TalentBoxLabelGrey } from './TalentResultBox'

const TalentBoxBottomInfoRow = styled.div`
    width: 100%;
    margin: 0 0 15px;
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;

    &:last-of-type {
        margin: 0;
    }
`

const TalentBoxColumnHeading = styled.div`
    width: 35%;
    padding-right: 10px;
`

const TalentBoxColumnContent = styled.div`
    width: 65%;
    display: flex;
    flex-wrap: wrap;
    font-size: 14px;
    line-height: 20px;
    color: ${COLOR_PALETTE.black};
`

export interface TalentBoxBottomInfoProps {
    talent: Specialist
    searchWords: Array<string>
}

const TalentBoxBottomInfo: FunctionComponent<TalentBoxBottomInfoProps> = ({ talent, searchWords }) => {
    const getTopSkills = (): Array<string> => {
        const { rolesInfo } = talent
        let skills: Array<string> = []
        if (rolesInfo && rolesInfo.length) {
            // return [].concat(...rolesInfo.map(role => role.topSkillsForRole))
            rolesInfo.forEach(role => {
                skills = skills.concat(...role.topSkillsForRole)
            })
        }

        return skills
    }

    if (talent) {
        return (
            <div
                css={css`
                    padding: 15px 16px;
                `}
            >
                <TalentBoxBottomInfoRow>
                    <TalentBoxColumnHeading>
                        <TalentBoxLabelGrey>Top Skills</TalentBoxLabelGrey>
                    </TalentBoxColumnHeading>
                    <TalentBoxColumnContent>
                        <SkillsList skills={getTopSkills()} searchWords={searchWords} />
                    </TalentBoxColumnContent>
                </TalentBoxBottomInfoRow>
                <TalentBoxBottomInfoRow>
                    <TalentBoxColumnHeading>
                        <TalentBoxLabelGrey>Recent roles</TalentBoxLabelGrey>
                    </TalentBoxColumnHeading>
                    <TalentBoxColumnContent>
                        <TextHighlight searchWords={searchWords} text={getStringFromArray(talent.recentRoles)} />
                    </TalentBoxColumnContent>
                </TalentBoxBottomInfoRow>
                <TalentBoxBottomInfoRow>
                    <TalentBoxColumnHeading>
                        <TalentBoxLabelGrey>Recent industries</TalentBoxLabelGrey>
                    </TalentBoxColumnHeading>
                    <TalentBoxColumnContent>
                        <TextHighlight searchWords={searchWords} text={getStringFromArray(talent.industries)} />
                    </TalentBoxColumnContent>
                </TalentBoxBottomInfoRow>
                <TalentBoxBottomInfoRow>
                    <TalentBoxColumnHeading>
                        <TalentBoxLabelGrey>Recent projects</TalentBoxLabelGrey>
                    </TalentBoxColumnHeading>
                    <TalentBoxColumnContent>
                        <TextHighlight searchWords={searchWords} text={getStringFromArray(talent.projects)} />
                    </TalentBoxColumnContent>
                </TalentBoxBottomInfoRow>
                <TalentBoxBottomInfoRow>
                    <TalentBoxColumnHeading>
                        <TalentBoxLabelGrey>Foreign language</TalentBoxLabelGrey>
                    </TalentBoxColumnHeading>
                    <TalentBoxColumnContent>
                        <TextHighlight searchWords={searchWords} text={getStringFromArray(talent.languages)} />
                    </TalentBoxColumnContent>
                </TalentBoxBottomInfoRow>
                <TalentBoxBottomInfoRow>
                    <TalentBoxColumnHeading>
                        <TalentBoxLabelGrey>Location</TalentBoxLabelGrey>
                    </TalentBoxColumnHeading>
                    <TalentBoxColumnContent>
                        <TextHighlight searchWords={searchWords} text={talent.location} />
                    </TalentBoxColumnContent>
                </TalentBoxBottomInfoRow>
            </div>
        )
    }

    return null
}

export { TalentBoxBottomInfo }
