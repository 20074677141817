import styled from '@emotion/styled'
import { COLOR_PALETTE } from '../../GlobalStyle'

const ErrorText = styled.p`
    text-align: right;
    font-size: 12px;
    margin: 0;
    color: ${COLOR_PALETTE.red};
`

export { ErrorText }
