/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import moment from 'moment'
import { Fragment, useCallback, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { AutoComplete, DatePicker, Form as AntForm, Input as AntInput } from '../../../components/antd/index'
import { AutocompleteSelect as TaAutocompleteSelect } from '../../../components/autocomplete-select/AutocompleteSelect'
import { Button } from '../../../components/Button'
import { Divider } from '../../../components/Divider'
import { Input as TaInput } from '../../../components/Input'
import { FieldWrapper } from '../../../components/layout/FormHelpers'
import { FirstColumn, ResponsiveColumns, SecondColumn } from '../../../components/layout/ResponsiveColumns'
import { Debug } from '../../../components/utils/Debug'
import { useSpecialistRoles } from '../../../redux/permanentDataHooks'
import { resolveAntValidationProps, resolveFieldError } from '../../../utils/errors'

const schema = yup.object().shape({
    taFirstName: yup.string().required('This field is required'),
    antFirstName: yup.string().required('This field is required'),
    taSpecialistRole: yup.string().required('This field is required'),
    antSpecialistRole: yup.string().required('This field is required'),
    antDate: yup.string().nullable().required('This field is required'),
    antRange: yup.array().min(2, 'Date range is required').of(yup.string()).nullable().required('This field is required'),
})

export interface FormData {
    taFirstName: string
    antFirstName: string
    taSpecialistRole: string
    antSpecialistRole: string
    antDate: string
    antRange: Array<string>
}

const { RangePicker } = DatePicker

moment.updateLocale('en', {
    weekdaysMin: ['Sun', 'Mon', 'Tue', 'Wen', 'Thu', 'Fri', 'Sat'],
})

const FormValidation = () => {
    const specialistRoles = useSpecialistRoles()
    const specialistOptions = useMemo(() => {
        return specialistRoles ? specialistRoles.map(role => ({ value: role })) : []
    }, [specialistRoles])
    const { register, handleSubmit, errors, control, getValues } = useForm({
        validationSchema: schema,
    })
    const onSubmit = useCallback(data => {
        console.log(data)
    }, [])

    return (
        <Fragment>
            <div
                css={css`
                    width: 80%;
                    margin: 0 auto;
                `}
            >
                <h4
                    css={css`
                        text-align: center;
                    `}
                >
                    TA Components vs Ant Components
                </h4>
                <Divider />

                <section>
                    <section>
                        <ResponsiveColumns>
                            <FirstColumn>
                                <TaInput name='taFirstName' label='First Name (TA)' placeholder='Foo Bar' />
                            </FirstColumn>
                            <SecondColumn>
                                <AntForm.Item label='First Name (ANT)' labelCol={{ span: 24 }}>
                                    <AntInput name='antFirstName' placeholder='Foo Bar' />
                                </AntForm.Item>
                            </SecondColumn>
                        </ResponsiveColumns>
                    </section>
                    <section>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <ResponsiveColumns>
                                <FirstColumn>
                                    <TaInput
                                        name='taFirstName'
                                        label='First Name (TA)'
                                        placeholder='Foo Bar'
                                        innerRef={register()}
                                        errorText={resolveFieldError(errors.taFirstName)}
                                    />
                                </FirstColumn>
                                <SecondColumn>
                                    <AntForm.Item
                                        label='First Name (ANT)'
                                        labelCol={{ span: 24 }}
                                        {...resolveAntValidationProps(errors.antFirstName)}
                                    >
                                        <Controller name='antFirstName' control={control} as={<AntInput placeholder='Foo Bar' />} />
                                    </AntForm.Item>
                                </SecondColumn>
                            </ResponsiveColumns>
                            <ResponsiveColumns>
                                <FirstColumn>
                                    <Controller
                                        control={control}
                                        name='taSpecialistRole'
                                        onChange={val => val[0][0]}
                                        onChangeName='onSelectedValuesChange'
                                        as={
                                            <TaAutocompleteSelect
                                                label='Specialist Role (TA)'
                                                options={specialistRoles}
                                                placeholder='Try to type "B"...'
                                                errors={errors.taSpecialistRole}
                                                currentValues={[getValues('taSpecialistRole')]}
                                            />
                                        }
                                    />
                                </FirstColumn>
                                <SecondColumn>
                                    <AntForm.Item
                                        label='Specialist Role (ANT)'
                                        labelCol={{ span: 24 }}
                                        {...resolveAntValidationProps(errors.antSpecialistRole)}
                                    >
                                        <Controller
                                            name='antSpecialistRole'
                                            control={control}
                                            as={
                                                <AutoComplete
                                                    placeholder='Try to type "B"...'
                                                    options={specialistOptions}
                                                    filterOption={(inputValue, option) => {
                                                        return option?.value.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
                                                    }}
                                                />
                                            }
                                        />
                                    </AntForm.Item>
                                </SecondColumn>
                            </ResponsiveColumns>
                            <ResponsiveColumns>
                                <FirstColumn>
                                    <TaInput name='bar' label='First Name (TA)' placeholder='Foo Bar' />
                                </FirstColumn>
                                <SecondColumn>
                                    <FieldWrapper>
                                        <AntForm.Item
                                            style={{ width: 'fit-content' }}
                                            label='Date from ANT'
                                            {...resolveAntValidationProps(errors.antDate)}
                                        >
                                            <Controller
                                                control={control}
                                                name='antDate'
                                                onChange={([dateObject]) => dateObject}
                                                as={<DatePicker name='antDate' format='DD-MM-YYYY' />}
                                            />
                                        </AntForm.Item>
                                    </FieldWrapper>
                                </SecondColumn>
                            </ResponsiveColumns>
                            <ResponsiveColumns>
                                <FirstColumn>
                                    <TaInput name='foo' label='First Name (TA)' placeholder='Foo Bar' />
                                </FirstColumn>
                                <SecondColumn>
                                    <AntForm.Item
                                        style={{ width: '50%' }}
                                        label='Date from ANT'
                                        {...resolveAntValidationProps(errors.antRange)}
                                    >
                                        <Controller
                                            control={control}
                                            name='antRange'
                                            onChange={([dateObjects]) => dateObjects}
                                            as={<RangePicker name='antRange' format='DD-MM-YYYY' />}
                                        />
                                    </AntForm.Item>
                                </SecondColumn>
                            </ResponsiveColumns>
                            <br />
                            <Button variant='primary' type='submit'>
                                Submit
                            </Button>
                        </form>
                        <Divider />
                    </section>
                    <Debug target={{ values: getValues(), errors, specialistRoles }} />
                </section>
            </div>
        </Fragment>
    )
}

export { FormValidation }
