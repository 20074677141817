import { ApplicationMenuItemEntry } from '../../components/layout/dashboard/ApplicationMenu'
import { getAppPath } from '../../contracts/applications'

const applicationName = 'Virtual Bench'

const menuItems: Array<ApplicationMenuItemEntry> = [
    {
        path: getAppPath('VIRTUAL_BENCH'),
        title: 'Find Talent',
    },
    {
        path: `${getAppPath('VIRTUAL_BENCH')}/favorites`,
        title: 'Bookmarked Talent',
    },
]

export { menuItems, applicationName }
