/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import styled from '@emotion/styled'
import { ChangeEvent, FunctionComponent, KeyboardEvent, MouseEvent, useCallback, useEffect, useState } from 'react'
import Typist from 'react-typist'
import { COLOR_PALETTE } from '../GlobalStyle'
import { Nullable } from '../types'
import { getKeyboardKey } from '../utils/keyboard'
import { Icon, IconButton } from './Icon'
import { TextHighlight } from './TextHighlight'

interface SearchInputProps {
    initialText?: string
    onSubmit: (text: string) => void
    onToggleFilters: () => void
    filtersOpened: boolean
    isOnResultsPage?: boolean
    onClearClick?: () => void
    autocompleteOptions?: string[]
    placeholder?: string
    withTypist?: boolean
}

const SearchInput: FunctionComponent<SearchInputProps> = ({
    initialText = '',
    onSubmit,
    onToggleFilters,
    filtersOpened,
    isOnResultsPage,
    onClearClick,
    autocompleteOptions,
    placeholder = '',
    withTypist,
    ...other
}) => {
    const [searchText, setSearchText] = useState(initialText)
    const [showTypist, setShowTypist] = useState(withTypist)
    const [isFocused, setIsFocused] = useState(false)
    const [filteredOptions, setFilteredOptions] = useState<string[]>([])
    const [showAutocompleteOptions, setShowAutocompleteOptions] = useState(false)
    const [selectedAutocompleteOption, setSelectedAutocompleteOption] = useState<Nullable<number>>(null)

    const filterFunction = (input: string, option: string): boolean => {
        if (input && input.length > 0) {
            return option.toLowerCase().indexOf(input.toLowerCase()) > -1
        }

        return true
    }

    const updateShowAutocompleteOptions = useCallback(
        (options: string[]) => {
            if (options.length > 0) {
                if (options.length === 1) {
                    if (options[0].toLowerCase() === searchText.toLowerCase()) {
                        setShowAutocompleteOptions(false)
                    } else {
                        setShowAutocompleteOptions(true)
                    }
                } else {
                    setShowAutocompleteOptions(true)
                }
            } else {
                setShowAutocompleteOptions(false)
            }
        },
        [searchText],
    )

    useEffect(() => {
        if (autocompleteOptions && searchText && searchText.length > 0 && searchText !== initialText) {
            const newOptions = autocompleteOptions.filter(option => filterFunction(searchText, option))
            updateShowAutocompleteOptions(newOptions)
            setFilteredOptions(newOptions)
        } else {
            setShowAutocompleteOptions(false)
            setFilteredOptions([])
        }
    }, [searchText, autocompleteOptions, initialText, updateShowAutocompleteOptions])

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchText(event.target.value)
    }

    const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
        const key = getKeyboardKey(event)
        switch (key) {
            case 'ArrowDown':
                if (showAutocompleteOptions) {
                    if (selectedAutocompleteOption === null || selectedAutocompleteOption === filteredOptions.length - 1) {
                        setSelectedAutocompleteOption(0)
                    } else {
                        setSelectedAutocompleteOption(selectedAutocompleteOption + 1)
                    }
                }
                break
            case 'ArrowUp':
                if (showAutocompleteOptions) {
                    if (selectedAutocompleteOption === null || selectedAutocompleteOption === 0) {
                        setSelectedAutocompleteOption(filteredOptions.length - 1)
                    } else {
                        setSelectedAutocompleteOption(selectedAutocompleteOption - 1)
                    }
                }
                break
            case 'Enter':
                if (showAutocompleteOptions && selectedAutocompleteOption !== null) {
                    setSearchText(filteredOptions[selectedAutocompleteOption])
                } else {
                    setShowAutocompleteOptions(false)
                    onSubmit(searchText)
                }
                break

            default:
                break
        }
    }

    const handleFocus = () => {
        if (withTypist) {
            setShowTypist(false)
        }
        setIsFocused(true)
    }

    const handleBlur = () => {
        if (withTypist && (!searchText || searchText.length === 0)) {
            setShowTypist(true)
        }
        setIsFocused(false)
    }

    const handleSearchClick = (event: MouseEvent) => {
        onSubmit(searchText)
    }

    const handleClearClick = () => {
        if (onClearClick) {
            onClearClick()
            setSearchText('')
        }
    }

    return (
        <div
            css={css`
                width: 100%;
                display: flex;
                justify-content: center;
                z-index: 2;
                box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
                margin-bottom: 24px;
            `}
            {...other}
        >
            <div
                css={css`
                    width: 100%;
                    border: 1px solid ${isFocused ? COLOR_PALETTE.grey : COLOR_PALETTE.grey_light};
                    position: relative;
                    height: 48px;
                    background-color: ${COLOR_PALETTE.white};
                    transition: all linear 0.3s;
                `}
            >
                {isOnResultsPage && (
                    <Icon
                        name='close'
                        onClick={handleClearClick}
                        css={css`
                            position: absolute;
                            top: 50%;
                            transform: translateY(-50%);
                            right: 16px;
                            cursor: pointer;
                            z-index: 10;
                        `}
                    />
                )}

                <input
                    css={css`
                        width: 100%;
                        padding: 17px 16px 15px;
                        height: 48px;
                        border: 0;
                        background: transparent;
                        position: relative;
                        z-index: 2;
                        ::placeholder {
                            color: ${COLOR_PALETTE.grey};
                        }
                    `}
                    onFocus={handleFocus}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    onBlur={handleBlur}
                    value={searchText}
                    type='text'
                    placeholder={withTypist ? '' : placeholder}
                />
                {showAutocompleteOptions && (
                    <ul
                        css={css`
                            background-color: ${COLOR_PALETTE.white};
                            padding: 8px 16px;
                            margin: 0;
                            border: 1px solid ${isOnResultsPage ? COLOR_PALETTE.grey_light : COLOR_PALETTE.grey_dark};
                        `}
                    >
                        {filteredOptions.map((opt, index) => (
                            <li
                                key={opt}
                                onClick={() => setSearchText(opt)}
                                css={css`
                                    list-style-type: none;
                                    padding: 8px 0;
                                    font-size: 14px;
                                    cursor: pointer;
                                    background-color: ${selectedAutocompleteOption === index ? COLOR_PALETTE.grey_lighter : ''};
                                `}
                            >
                                <TextHighlight searchWords={searchText.split(' ')} text={opt} />
                            </li>
                        ))}
                    </ul>
                )}
                {searchText === '' && (
                    <div
                        css={css`
                            color: ${COLOR_PALETTE.grey};
                            position: absolute;
                            top: 14px;
                            left: 17px;
                            width: 100%;
                        `}
                    >
                        {showTypist && (
                            <Typist
                                cursor={{ hideWhenDone: true }}
                                css={css`
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    width: calc(100% - 45px);
                                    font-size: 14px;
                                `}
                            >
                                Junior
                                <Typist.Backspace count={6} delay={300} />
                                Senior FrontEnd Developer with Angular
                                <Typist.Backspace count={7} delay={500} />
                                React experienced in Banking
                                <Typist.Backspace count={7} delay={400} />
                                Automotive
                            </Typist>
                        )}
                    </div>
                )}
            </div>
            <ButtonPositioner isFocused={isFocused} onClick={onToggleFilters}>
                <IconButton name='filter' pressed={filtersOpened} size={32} innerSize={16} />
            </ButtonPositioner>
            <ButtonPositioner isFocused={isFocused} onClick={handleSearchClick}>
                <Icon name='search' size={16} />
            </ButtonPositioner>
        </div>
    )
}

type ButtonPositionerProps = {
    isFocused: boolean
}

const ButtonPositioner = styled.div<ButtonPositionerProps>`
    border-width: 1px;
    border-style: solid;
    border-color: ${props => (props.isFocused ? COLOR_PALETTE.grey : COLOR_PALETTE.grey_light)};
    width: 48px;
    background-color: ${COLOR_PALETTE.white};
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: -1px;
    cursor: pointer;
`

export { SearchInput }
