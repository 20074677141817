/** @jsx jsx */
import { css, jsx, SerializedStyles } from '@emotion/core'
import { FunctionComponent, HTMLAttributes, ImgHTMLAttributes } from 'react'
import alert from '../assets/icons/alert.svg'
import aplicationTracking from '../assets/icons/aplication-tracking.svg'
import arrowDown from '../assets/icons/arrow-down.svg'
import arrowUp from '../assets/icons/arrow-up.svg'
import back from '../assets/icons/back.svg'
import bench from '../assets/icons/bench.svg'
import bookmark from '../assets/icons/bookmark.svg'
import calendar from '../assets/icons/calendar.svg'
import checkGrey from '../assets/icons/check-grey.svg'
import checkRed from '../assets/icons/check-red.svg'
import checkWhite from '../assets/icons/check-white.svg'
import check from '../assets/icons/check.svg'
import closeGrey from '../assets/icons/close-grey.svg'
import close from '../assets/icons/close.svg'
import cloud from '../assets/icons/cloud.svg'
import cog from '../assets/icons/cog.svg'
import contractor from '../assets/icons/contractor.svg'
import doc from '../assets/icons/doc.svg'
import dollar from '../assets/icons/dollar.svg'
import doubleArrowDown from '../assets/icons/double-arrow-down.svg'
import doubleArrowUp from '../assets/icons/double-arrow-up.svg'
import download from '../assets/icons/download.svg'
import edit from '../assets/icons/edit.svg'
import external from '../assets/icons/external.svg'
import eyeHide from '../assets/icons/eye-hide.svg'
import eye from '../assets/icons/eye.svg'
import filter from '../assets/icons/filter.svg'
import flag from '../assets/icons/flag.svg'
import grid from '../assets/icons/grid.svg'
import hamburger from '../assets/icons/hamburger.svg'
import list from '../assets/icons/list.svg'
import lock from '../assets/icons/lock.svg'
import magGlass from '../assets/icons/mag-glass.svg'
import mail from '../assets/icons/mail.svg'
import more from '../assets/icons/more.svg'
import noData from '../assets/icons/no-data.svg'
import notification from '../assets/icons/notification.svg'
import plane from '../assets/icons/plane.svg'
import profileDetails from '../assets/icons/profile-details.svg'
import psychometrics from '../assets/icons/psychometrics.svg'
import sales from '../assets/icons/sales.svg'
import save from '../assets/icons/save.svg'
import search from '../assets/icons/search.svg'
import share from '../assets/icons/share.svg'
import smallClose from '../assets/icons/small-close.svg'
import syndicate from '../assets/icons/syndicate.svg'
import time from '../assets/icons/time.svg'
import tooltip from '../assets/icons/tooltip.svg'
import trash from '../assets/icons/trash.svg'
import userAdd from '../assets/icons/user-add.svg'
import userRemove from '../assets/icons/user-remove.svg'
import user from '../assets/icons/user.svg'
import users from '../assets/icons/users.svg'
import { COLOR_PALETTE } from '../GlobalStyle'

export type IconName =
    | 'alert'
    | 'aplication-tracking'
    | 'arrow-down'
    | 'arrow-up'
    | 'back'
    | 'bench'
    | 'bookmark'
    | 'calendar'
    | 'check-grey'
    | 'check-white'
    | 'check-red'
    | 'check'
    | 'close-grey'
    | 'close'
    | 'cloud'
    | 'cog'
    | 'contractor'
    | 'doc'
    | 'dollar'
    | 'double-arrow-down'
    | 'double-arrow-up'
    | 'download'
    | 'edit'
    | 'external'
    | 'eye-hide'
    | 'eye'
    | 'filter'
    | 'flag'
    | 'grid'
    | 'hamburger'
    | 'list'
    | 'lock'
    | 'mag-glass'
    | 'mail'
    | 'more'
    | 'no-data'
    | 'notification'
    | 'plane'
    | 'profile-details'
    | 'psychometrics'
    | 'sales'
    | 'save'
    | 'search'
    | 'share'
    | 'small-close'
    | 'syndicate'
    | 'time'
    | 'tooltip'
    | 'trash'
    | 'user-add'
    | 'user-remove'
    | 'user'
    | 'users'

const getSvg = (name: IconName): string => {
    switch (name) {
        case 'aplication-tracking':
            return aplicationTracking
        case 'alert':
            return alert
        case 'arrow-down':
            return arrowDown
        case 'arrow-up':
            return arrowUp
        case 'back':
            return back
        case 'bench':
            return bench
        case 'bookmark':
            return bookmark
        case 'calendar':
            return calendar
        case 'check':
            return check
        case 'check-grey':
            return checkGrey
        case 'check-white':
            return checkWhite
        case 'check-red':
            return checkRed
        case 'close-grey':
            return closeGrey
        case 'close':
            return close
        case 'cloud':
            return cloud
        case 'cog':
            return cog
        case 'contractor':
            return contractor
        case 'doc':
            return doc
        case 'dollar':
            return dollar
        case 'double-arrow-down':
            return doubleArrowDown
        case 'double-arrow-up':
            return doubleArrowUp
        case 'download':
            return download
        case 'edit':
            return edit
        case 'external':
            return external
        case 'eye-hide':
            return eyeHide
        case 'eye':
            return eye
        case 'filter':
            return filter
        case 'flag':
            return flag
        case 'grid':
            return grid
        case 'hamburger':
            return hamburger
        case 'list':
            return list
        case 'lock':
            return lock
        case 'mag-glass':
            return magGlass
        case 'mail':
            return mail
        case 'more':
            return more
        case 'no-data':
            return noData
        case 'notification':
            return notification
        case 'plane':
            return plane
        case 'profile-details':
            return profileDetails
        case 'psychometrics':
            return psychometrics
        case 'sales':
            return sales
        case 'save':
            return save
        case 'search':
            return search
        case 'share':
            return share
        case 'small-close':
            return smallClose
        case 'syndicate':
            return syndicate
        case 'time':
            return time
        case 'tooltip':
            return tooltip
        case 'trash':
            return trash
        case 'user-add':
            return userAdd
        case 'user-remove':
            return userRemove
        case 'user':
            return user
        case 'users':
            return users
    }
}

const blackToGrayFilter = 'invert(100%) sepia(0%) saturate(1272%) hue-rotate(179deg) brightness(72%) contrast(100%)'
const blackToRedFilter = 'invert(9%) sepia(96%) saturate(6006%) hue-rotate(359deg) brightness(80%) contrast(109%)'
const blackToVioletDarkFilter = 'invert(18%) sepia(25%) saturate(3850%) hue-rotate(229deg) brightness(94%) contrast(92%)'
const blackToWhiteFilter = 'invert()'

type IconProps = ImgHTMLAttributes<HTMLImageElement> & {
    name: IconName
    style?: SerializedStyles
    size?: number
}

const Icon: FunctionComponent<IconProps> = ({ name, size = 14, style, src, ...rest }) => {
    return (
        <img
            alt={name}
            css={css`
                width: ${size}px;
                height: ${size}px;

                ${style}
            `}
            src={getSvg(name) || src}
            {...rest}
        />
    )
}

type RoundIconWrapperProps = HTMLAttributes<HTMLSpanElement> & {
    size: number
}

const RoundIconWrapper: FunctionComponent<RoundIconWrapperProps> = ({ children, size, ...rest }) => (
    <span
        css={css`
            display: inline-flex;
            width: ${size}px;
            height: ${size}px;
            border-width: 1px;
            border-style: solid;
            border-radius: ${size / 2}px;
            justify-content: center;
            align-items: center;
        `}
        {...rest}
    >
        {children}
    </span>
)

type AppIconStatus = 'available' | 'unavailable' | 'disabled'
type AppIconVariant = 'tile' | 'menu'

const getAppIconStyles = (status: AppIconStatus, variant: AppIconVariant) => {
    let styles
    switch (status) {
        case 'available':
            styles = css`
                border-color: ${variant === 'tile' ? COLOR_PALETTE.black : COLOR_PALETTE.yellow};
                background: ${variant === 'tile' ? COLOR_PALETTE.black : 'transparent'};
                img {
                    filter: ${variant === 'tile' ? blackToWhiteFilter : 'none'};
                }
            `
            break
        case 'unavailable':
            styles = css`
                border-color: ${COLOR_PALETTE.yellow};
                background: transparent;
            `
            break
        default:
            styles = css`
                border-color: ${variant === 'tile' ? COLOR_PALETTE.grey_light : COLOR_PALETTE.grey_lighter};
                background: ${COLOR_PALETTE.grey_lighter};
                img {
                    filter: ${blackToGrayFilter};
                }
            `
            break
    }
    return styles
}

type AppIconProps = IconProps & {
    innerSize?: number
    status?: AppIconStatus
    style?: SerializedStyles
    variant?: AppIconVariant
}

const AppIcon: FunctionComponent<AppIconProps> = ({
    name,
    status = 'available',
    size = 60,
    innerSize = size / 2,
    variant = 'tile',
    style = {},
    ...rest
}) => {
    return (
        <RoundIconWrapper
            size={size}
            css={css`
                ${getAppIconStyles(status, variant)}

                ${style};
            `}
            {...rest}
        >
            <Icon size={innerSize} name={name} />
        </RoundIconWrapper>
    )
}

type IconButtonVariant = 'standard' | 'outlined' | 'primary'

type IconButtonProps = IconProps & {
    innerSize?: number
    pressed?: boolean
    variant?: IconButtonVariant
}

const getIconButtonStyles = (variant: IconButtonVariant, pressed: boolean) => {
    let styles
    if (variant === 'outlined') {
        styles = css`
            border-color: ${pressed ? COLOR_PALETTE.violet_darker : COLOR_PALETTE.violet_light};
            background: ${pressed ? COLOR_PALETTE.violet_darker : 'transparent'};
            &:hover {
                border-color: ${COLOR_PALETTE.violet_dark};
                background: ${COLOR_PALETTE.violet_dark};
                img {
                    filter: ${blackToWhiteFilter};
                }
            }
            img {
                filter: ${pressed ? blackToWhiteFilter : 'none'};
            }
        `
    } else if (variant === 'primary') {
        styles = css`
            border-width: 0;
            background: ${pressed ? COLOR_PALETTE.yellow_dark : COLOR_PALETTE.yellow};
            &:hover {
                background: ${COLOR_PALETTE.yellow_dark};
            }
        `
    } else {
        styles = css`
            border-width: 0;
            background: ${pressed ? COLOR_PALETTE.black : 'transparent'};
            &:hover {
                background: ${pressed ? COLOR_PALETTE.grey_darker : COLOR_PALETTE.grey_light};
            }
            img {
                filter: ${pressed ? blackToWhiteFilter : 'none'};
            }
        `
    }
    return styles
}

const IconButton: FunctionComponent<IconButtonProps> = ({
    name,
    onClick,
    variant = 'standard',
    pressed = false,
    size = 40,
    innerSize = size / 2,
    title = '',
    ...rest
}) => {
    return (
        <RoundIconWrapper
            size={size}
            onClick={onClick}
            title={title}
            css={css`
                cursor: pointer;
                ${getIconButtonStyles(variant, pressed)}
            `}
        >
            <Icon size={innerSize} name={name} {...rest} />
        </RoundIconWrapper>
    )
}

export { Icon, AppIcon, IconButton, getSvg, blackToGrayFilter, blackToRedFilter, blackToVioletDarkFilter, blackToWhiteFilter }
