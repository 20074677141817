/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Fragment, FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
    addSpecialistProfileToFavorites,
    getSpecialistsFilters,
    getSpecialistsProfiles,
    removeSpecialistProfileFromFavorites,
} from '../../api/api'
import { Pagination } from '../../components/inputs/Pagination'
import { DashboardLayout } from '../../components/layout/dashboard/DashboardLayout'
import { OverlayContentLoader } from '../../components/layout/Loader'
import { FullPageWrapper } from '../../components/layout/ResponsiveWrapper'
import { useNotifications } from '../../components/notification/NotificationProvider'
import { SearchResultsInfo } from '../../components/search/SearchResultsInfo'
import { SearchInput } from '../../components/SearchInput'
import { TalentResultBox, TalentResultBoxContainer } from '../../components/talent/TalentResultBox'
import {
    SpecialistsSearchFilters,
    SpecialistsSearchRequest,
    SpecialistsSearchResponse,
} from '../../contracts/search/specialistsSearch'
import { COLOR_PALETTE } from '../../GlobalStyle'
import { useDebouncedEffect } from '../../utils/hooks'
import {
    defaultPageSize,
    parseQueryToSearchRequest,
    sanitizePossibleFilters,
    stringifySearchRequestToQuery,
} from '../../utils/searchFilters'
import { FiltersBar } from './specialists-search/FiltersBar'
import { applicationName, menuItems } from './virtualBenchApp'
import { countSelectedFilters } from './virtualBenchUtils'

type SearchProps = {
    onlyFavorites?: boolean
}

const SpecialistsSearchPage: FunctionComponent<SearchProps> = ({ onlyFavorites = false }) => {
    const {
        replace,
        location: { search },
    } = useHistory()
    const { addSuccess } = useNotifications()
    const searchRequest = useMemo<SpecialistsSearchRequest>(() => parseQueryToSearchRequest(search, { favorite: onlyFavorites }), [
        search,
        onlyFavorites,
    ])
    const searchFilters = useMemo<SpecialistsSearchFilters>(() => {
        const { searchText: s, paging: p, favorite, ...filtersOnly } = searchRequest
        return filtersOnly
    }, [searchRequest])
    const [searchText, setSearchText] = useState(searchRequest.searchText)
    const [initialSearchText] = useState(searchRequest.searchText)
    const [filters, setFilters] = useState<SpecialistsSearchFilters>(searchFilters)
    const [paging, setPaging] = useState(searchRequest.paging)

    const [filtersOpened, setFiltersOpened] = useState(true)

    const [possibleFilters, setPossibleFilters] = useState({})
    const [resultsPending, setResultsPending] = useState(false)
    const [results, setResults] = useState<SpecialistsSearchResponse>()

    useEffect(() => {
        const completeRequest = { searchText, paging, ...filters }
        const newSearchQuery = stringifySearchRequestToQuery(completeRequest)
        replace({ search: newSearchQuery })
    }, [searchText, filters, paging, replace])

    useEffect(() => {
        const getFiltersData = async () => {
            const filtersData = await getSpecialistsFilters({ searchText: searchRequest.searchText })
            setPossibleFilters(sanitizePossibleFilters(filtersData))
            if (initialSearchText !== searchRequest.searchText) {
                setFilters({})
                setPaging({
                    page: 1,
                    size: defaultPageSize,
                })
            }
        }
        getFiltersData().finally()
    }, [searchRequest.searchText, initialSearchText])

    useDebouncedEffect(
        () => {
            setResultsPending(true)
            getSpecialistsProfiles(searchRequest)
                .then(data => {
                    setResults(data)
                })
                .finally(() => {
                    setResultsPending(false)
                })
        },
        300,
        [searchRequest],
    )

    const onChangeSearchText = useCallback((newSearchText: string) => {
        setSearchText(newSearchText)
    }, [])

    const onChangeFilters = useCallback((newFilters: SpecialistsSearchRequest) => {
        setFilters(newFilters)
    }, [])

    const onClearFilters = useCallback(() => {
        setFilters({})
    }, [])

    const onToggleFilters = useCallback(() => {
        setFiltersOpened(!filtersOpened)
    }, [filtersOpened])

    const onToggleFavourite = useCallback(
        (favourite: boolean, specialistId: string) => {
            if (favourite) {
                return addSpecialistProfileToFavorites(specialistId).then(() => {
                    addSuccess('Specialist added to Bookmarked Talent successfully.')
                })
            } else {
                return removeSpecialistProfileFromFavorites(specialistId).then(() => {
                    addSuccess('Specialist removed from Bookmarked Talent successfully.')
                })
            }
        },
        [addSuccess],
    )

    const onChangePage = useCallback((page: number, size: number) => {
        setPaging({
            page,
            size,
        })
    }, [])

    const showResults = results && ((onlyFavorites === true && results.matchingCount) || onlyFavorites === false)

    return (
        <DashboardLayout applicationName={applicationName} applicationMenuItems={menuItems} backgroundColor={COLOR_PALETTE.grey_lighter}>
            <FullPageWrapper>
                <SearchInput
                    initialText={searchText}
                    onSubmit={onChangeSearchText}
                    filtersOpened={filtersOpened}
                    onToggleFilters={onToggleFilters}
                    withTypist
                />
                <FiltersBar
                    possibleFiltersValues={possibleFilters}
                    onChangeFilters={onChangeFilters}
                    selectedFilters={searchFilters}
                    onClearFilters={onClearFilters}
                    filtersOpened={filtersOpened}
                />
                <div
                    css={css`
                        position: relative;
                        margin: 0 auto 60px;
                        min-height: 400px;
                    `}
                >
                    {results && (
                        <Fragment>
                            <SearchResultsInfo
                                searchResults={results}
                                searchText={searchText}
                                filtersCount={countSelectedFilters(searchRequest)}
                                favorites={onlyFavorites}
                            />
                            {showResults && (
                                <Fragment>
                                    <TalentResultBoxContainer>
                                        {results.specialists.map((specialistEntry, index) => (
                                            <TalentResultBox
                                                key={specialistEntry.specialist.specialistId}
                                                talent={specialistEntry.specialist}
                                                searchWords={searchText ? searchText.split(' ').map(word => word.toLowerCase()) : []}
                                                onToggleFavourite={onToggleFavourite}
                                            />
                                        ))}
                                    </TalentResultBoxContainer>

                                    <div
                                        css={css`
                                            margin-top: 16px;
                                        `}
                                    >
                                        <Pagination
                                            total={results.total}
                                            pageSize={defaultPageSize}
                                            current={paging?.page || 1}
                                            onChange={onChangePage}
                                        />
                                    </div>
                                </Fragment>
                            )}
                        </Fragment>
                    )}
                    {resultsPending && <OverlayContentLoader />}
                </div>
            </FullPageWrapper>
        </DashboardLayout>
    )
}

export { SpecialistsSearchPage }
