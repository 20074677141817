/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import styled from '@emotion/styled'
import { FunctionComponent, MutableRefObject, useEffect, useState } from 'react'
import { COLOR_PALETTE, mqMin } from '../../GlobalStyle'
import { DropdownComponent, DropdownContainer, DropdownDivider } from '../autocomplete-select/DropdownComonent'
import { DropdownFooter } from '../autocomplete-select/DropdownFooter'
import { FilterBox } from '../autocomplete-select/FilterBox'
import { Slider, sliderStyling } from '../inputs/Slider'

const RangeSelectionInput = styled.input`
    border: 1px solid ${COLOR_PALETTE.grey_light};
    box-sizing: border-box;
    border-radius: 2px;
    color: ${COLOR_PALETTE.black};
    font-size: 14px;
    line-height: 130%;
    padding: 13px 13px 12px;
    max-width: 54px;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    &:first-of-type {
        margin-right: 10px;
    }

    &:last-of-type {
        margin-left: 10px;
    }
`

interface RangeSelectionProps {
    min?: number
    max?: number
    step?: number
    placeholder?: string
    label?: string
    onRangeChange: (min: number, max: number) => void
    selectedMin?: number
    selectedMax?: number
    rangeRef: MutableRefObject<HTMLDivElement>
    isOpen: boolean
    setIsOpen: (isOpen: boolean) => void
    rangeWidth?: string
    dropdownWidth?: string
    valueTransformer?: (value: number) => number
}

const RangeSelectionBase: FunctionComponent<RangeSelectionProps> = ({
    min = 0,
    max = 40,
    step = 1,
    placeholder = 'Select range',
    label = 'Select range',
    onRangeChange,
    selectedMin = 0,
    selectedMax = 40,
    rangeRef,
    isOpen,
    setIsOpen,
    rangeWidth = '100%',
    dropdownWidth = '150%',
    valueTransformer = v => v,
}) => {
    const [selectedValues, setSelectedValues] = useState({ selectedMin, selectedMax })
    const [minValue, setMinValue] = useState(selectedValues.selectedMin)
    const [maxValue, setMaxValue] = useState(selectedValues.selectedMax)

    useEffect(() => {
        setSelectedValues({ selectedMin, selectedMax })
    }, [selectedMin, selectedMax])

    const onFilterBoxClick = () => {
        setIsOpen(!isOpen)

        if (!isOpen) {
            setMinValue(selectedValues.selectedMin)
            setMaxValue(selectedValues.selectedMax)
        }
    }

    const onApply = () => {
        setIsOpen(false)
        setSelectedValues({ selectedMin: minValue, selectedMax: maxValue })
        onRangeChange(valueTransformer(minValue), valueTransformer(maxValue))
    }

    const onClearAll = () => {
        setMinValue(min)
        setMaxValue(max)
        setSelectedValues({ selectedMin: min, selectedMax: max })
        onRangeChange(valueTransformer(min), valueTransformer(max))
    }

    const onSliderRangeChange = (value: [number, number]) => {
        const [newMin, newMax] = value
        setMinValue(newMin)
        setMaxValue(newMax)
    }

    const onMinInputChange = (event: any) => {
        setMinValue(event.target.value)
    }

    const onMaxInputChange = (event: any) => {
        setMaxValue(event.target.value)
    }

    const onMinInputBlur = (event: any) => {
        if (!event.target.value) {
            setMinValue(min)
        }
    }

    const onMaxInputBlur = (event: any) => {
        if (!event.target.value) {
            setMaxValue(max)
        }
    }

    return (
        <div
            ref={rangeRef}
            css={css`
                position: relative;
                display: block;
                flex: 1;
            `}
        >
            <FilterBox
                onClick={onFilterBoxClick}
                placeholder={placeholder}
                isOpen={isOpen}
                displayedValue={selectedMin || selectedMax ? `${selectedMin} - ${selectedMax}` : ''}
                css={css`
                    width: ${rangeWidth};
                `}
            />
            {isOpen && (
                <DropdownContainer
                    css={css`
                        width: ${dropdownWidth};
                        background-color: ${COLOR_PALETTE.white};
                        width: 100%;
                        ${mqMin[1]} {
                            width: 240px;
                        }
                    `}
                >
                    <div
                        css={css`
                            padding: 17px 18px 11px;
                        `}
                    >
                        <label
                            css={css`
                                width: 100%;
                                display: block;
                                color: ${COLOR_PALETTE.grey_dark};
                                font-size: 12px;
                                line-height: 120%;
                                margin: 0 0 6px;
                            `}
                        >
                            {label}
                        </label>
                        <div
                            css={css`
                                display: flex;
                                align-items: center;
                            `}
                        >
                            <RangeSelectionInput
                                type='number'
                                placeholder={'' + min}
                                value={minValue ? minValue : ''}
                                min={min}
                                max={max}
                                onChange={onMinInputChange}
                                onBlur={onMinInputBlur}
                            />
                            <Slider.Range
                                min={min}
                                max={max}
                                value={[minValue, maxValue ? maxValue : max]}
                                onChange={onSliderRangeChange}
                                {...sliderStyling}
                            />
                            <RangeSelectionInput
                                type='number'
                                placeholder={'' + max}
                                value={maxValue ? maxValue : ''}
                                min={min}
                                max={max}
                                onChange={onMaxInputChange}
                                onBlur={onMaxInputBlur}
                            />
                        </div>
                    </div>
                    <DropdownDivider />
                    <DropdownFooter onApply={onApply} onClearAll={onClearAll} />
                </DropdownContainer>
            )}
        </div>
    )
}

const RangeSelection = DropdownComponent(RangeSelectionBase)

export { RangeSelection }
