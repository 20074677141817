/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { FunctionComponent, useCallback } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { addEmployees, validateEmployeeEmail } from '../../../api/api'
import { AutocompleteSelect } from '../../../components/autocomplete-select/AutocompleteSelect'
import { Button } from '../../../components/Button'
import { Divider } from '../../../components/Divider'
import { getSvg, IconButton } from '../../../components/Icon'
import { Input } from '../../../components/Input'
import { FlexBox, FlexColumn, FlexColumns } from '../../../components/layout/FlexBoxHelpers'
import { FieldWrapper } from '../../../components/layout/FormHelpers'
import { Modal, ModalProps } from '../../../components/Modal'
import { Debug } from '../../../components/utils/Debug'
import { AxiosApiError } from '../../../contracts/common/apiError'
import { Employee } from '../../../contracts/employees/employee'
import { COLOR_PALETTE, mqMin } from '../../../GlobalStyle'
import { useSpecialistRoles } from '../../../redux/permanentDataHooks'
import { Modify } from '../../../types'
import { resolveFieldError } from '../../../utils/errors'

const requiredMessage = 'Fill out the missing data'
const invalidEmailMessage = 'Enter valid e-mail'
const emailTakenMessage = 'This e-mail is taken or duplicated'

const checkLocalUniq = (email: string, context: any) => {
    const fields = context.from[1].value.employees
    const occurrences = fields.filter((field: any) => field.email === email).length
    return occurrences === 1
}

const validationSchema = yup.object().shape({
    employees: yup.array().of(
        yup.object().shape({
            firstName: yup.string().required(requiredMessage),
            lastName: yup.string().required(requiredMessage),
            email: yup
                .string()
                .email(invalidEmailMessage)
                .required(requiredMessage)
                // tslint:disable-next-line:only-arrow-functions
                .test('async-email-unique', emailTakenMessage, function (email = '') {
                    if (!yup.string().email().required().isValidSync(email)) {
                        return false // validate only when initial e-mail validation passes
                    }
                    if (!checkLocalUniq(email, this)) {
                        return false
                    }
                    return validateEmployeeEmail(email)
                        .then(() => true)
                        .catch(() => false)
                }),
            role: yup.string().required(requiredMessage),
        }),
    ),
})

type AddEmployeeModalProps = Modify<
    ModalProps,
    {
        onClose: (saved: number) => void
    }
>

const initialEmployees = [
    {
        firstName: '',
        lastName: '',
        email: '',
        role: '',
    },
]

const AddEmployeesModal: FunctionComponent<AddEmployeeModalProps> = ({ onClose, opened }) => {
    const specialistRoles = useSpecialistRoles()
    const { register, control, handleSubmit, errors, getValues, setError } = useForm({
        validationSchema,
        reValidateMode: 'onSubmit',
        defaultValues: {
            employees: initialEmployees,
        },
    })
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'employees',
    })

    const onAddEmployeesSuccess = useCallback(
        (employees: Array<Employee>) => {
            onClose(employees.length)
        },
        [onClose],
    )

    const onAddEmployeesError = useCallback(
        ({ data: error }: AxiosApiError) => {
            if (error.code === 'EMPLOYEE_EMAIL_EXISTS') {
                const employees = getValues('employees')
                const email = error.data.email // TODO: handle array when backend ready
                const index = employees.findIndex(e => e.email === email)
                if (index > -1) {
                    setError(`employees[${index}].email`, 'required', emailTakenMessage)
                }
            }
        },
        [getValues, setError],
    )

    const onSubmit = useCallback(
        data => {
            addEmployees(data.employees)
                .then(() => {
                    onAddEmployeesSuccess(data.employees)
                })
                .catch(onAddEmployeesError)
        },
        [onAddEmployeesSuccess, onAddEmployeesError],
    )

    return (
        <Modal onClose={() => onClose(0)} opened={opened} maskClosable={false}>
            <h5>Create New Employees</h5>
            <p
                css={css`
                    color: ${COLOR_PALETTE.grey_dark};
                `}
            >
                Create Basic Profiles for New Employees and send invites to let them add more information to their Profiles.
            </p>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                    {fields.map((item, index) => {
                        const role = getValues(`employees[${index}].role`)
                        return (
                            <FlexColumns
                                key={item.id}
                            >
                                <FlexColumn>
                                    <FieldWrapper noLabel>
                                        <Input
                                            placeholder='First name'
                                            name={`employees[${index}].firstName`}
                                            defaultValue={item.firstName}
                                            innerRef={register()}
                                            errorText={resolveFieldError(errors?.employees?.[index]?.firstName)}
                                        />
                                    </FieldWrapper>
                                </FlexColumn>
                                <FlexColumn>
                                    <FieldWrapper noLabel>
                                        <Input
                                            placeholder='Last name'
                                            name={`employees[${index}].lastName`}
                                            defaultValue={item.lastName}
                                            innerRef={register()}
                                            errorText={resolveFieldError(errors?.employees?.[index]?.lastName)}
                                        />
                                    </FieldWrapper>
                                </FlexColumn>
                                <FlexColumn>
                                    <FieldWrapper noLabel>
                                        <Input
                                            placeholder='E-mail'
                                            name={`employees[${index}].email`}
                                            defaultValue={item.email}
                                            innerRef={register()}
                                            errorText={resolveFieldError(errors?.employees?.[index]?.email)}
                                        />
                                    </FieldWrapper>
                                </FlexColumn>
                                <FlexColumn>
                                    <FieldWrapper noLabel>
                                        <Controller
                                            as={
                                                <AutocompleteSelect
                                                    placeholder='Role'
                                                    options={specialistRoles}
                                                    errors={errors?.employees?.[index]?.role}
                                                    defaultValues={[item.role]}
                                                    currentValues={[role]}
                                                    css={css`
                                                        ${mqMin[1]} {
                                                            width: 250px;
                                                        }
                                                    `}
                                                />
                                            }
                                            control={control}
                                            onChange={val => val[0][0]}
                                            onChangeName='onSelectedValuesChange'
                                            name={`employees[${index}].role`}
                                        />
                                    </FieldWrapper>
                                </FlexColumn>
                                <FlexColumn shrinkable minWidth='40px'>
                                    {(fields.length > 1 || index >= 1) && (
                                        <IconButton name='small-close' onClick={() => remove(index)} title='Remove' />
                                    )}
                                </FlexColumn>
                            </FlexColumns>
                        )
                    })}
                </div>
                <Button variant='linkForm' onClick={() => append({ name: 'employees' })}>
                    + Add One More
                </Button>
                <Divider />
                <FlexBox justifyContent='flex-end'>
                    <Button
                        variant='text'
                        css={css`
                            margin-right: 8px;
                        `}
                        onClick={() => onClose(0)}
                    >
                        Close
                    </Button>
                    <Button variant='primary' icon={getSvg('user-add')} type='submit'>
                        Create New Employees
                    </Button>
                </FlexBox>
                <Debug target={{ specialistRoles, values: getValues() }} />
            </form>
        </Modal>
    )
}

export { AddEmployeesModal }
