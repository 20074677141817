/** @jsx jsx */
import { css, jsx, SerializedStyles } from '@emotion/core'
import { ChangeEvent, Fragment } from 'react'
import { COLOR_PALETTE } from '../GlobalStyle'
import { Icon } from './Icon'
import { ErrorText } from './inputs/ErrorText'

const Checkbox = ({
    checked,
    onChange,
    label,
    name = 'checkbox',
    errorText,
    style,
}: {
    label: string
    checked: boolean
    onChange?: (checked: boolean) => void
    name?: string
    errorText?: string
    style?: SerializedStyles
}) => {
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (onChange) {
            onChange(event.target.checked)
        }
    }

    return (
        <Fragment>
            <input
                type='checkbox'
                id={`checkbox_${name}`}
                name={name}
                css={css`
                    display: none;
                    &:checked + label div {
                        background-color: ${COLOR_PALETTE.black};
                        border: 1px solid ${errorText ? COLOR_PALETTE.red : COLOR_PALETTE.black};
                    }
                `}
                checked={checked}
                onChange={handleChange}
            />
            <label
                css={css`
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    ${style}
                `}
                htmlFor={`checkbox_${name}`}
            >
                <div
                    css={css`
                        border: 1px solid ${errorText ? COLOR_PALETTE.red : COLOR_PALETTE.grey};
                        width: 16px;
                        height: 16px;
                        position: relative;
                        background-color: ${COLOR_PALETTE.white};
                    `}
                    id='checkbox-{name}'
                >
                    <Icon
                        name='check-white'
                        style={css`
                            position: absolute;
                            top: 0px;
                            left: 0px;
                            width: 14px;
                            height: 14px;
                            opacity: ${checked ? 1 : 0};
                        `}
                    />
                </div>
                <span
                    css={css`
                        margin-left: 8px;
                        color: ${errorText ? COLOR_PALETTE.red : COLOR_PALETTE.black};
                    `}
                >
                    {label}
                </span>
                {errorText && <ErrorText>{errorText}</ErrorText>}
            </label>
        </Fragment>
    )
}

export { Checkbox }
