import React, { FunctionComponent, useContext, useEffect } from 'react'
import { Route, useHistory, useRouteMatch } from 'react-router-dom'
import { ReduxContext } from '../redux/Store'

const PublicRoute: FunctionComponent<any> = ({ component: Component, ...rest }) => {
    const history = useHistory()
    const { isExact } = useRouteMatch()

    const {
        selectors: { userId },
    } = useContext(ReduxContext)

    useEffect(() => {
        if (isExact) {
            if (userId) {
                history.push('/dashboard')
            }
        }
    }, [userId, history, isExact])

    return <Route {...rest} render={props => <Component {...props} />} />
}

export { PublicRoute }
