import styled from '@emotion/styled'
import { COLOR_PALETTE, mqMin } from '../../../GlobalStyle'

const ApplicationBox = styled.div`
    text-transform: uppercase;
    font-size: 8px;
    line-height: 10px;
    max-width: 60px;
    font-weight: 500;
    color: ${COLOR_PALETTE.grey_dark};

    ${mqMin[1]} {
        text-transform: none;
        border-radius: 2px;
        font-size: 14px;
        line-height: 22px;
        max-width: none;
        color: ${COLOR_PALETTE.white};
        background-color: ${COLOR_PALETTE.black};
        padding: 1px 6px;
    }
`

export { ApplicationBox }
