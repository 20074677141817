/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import styled from '@emotion/styled'
import { FunctionComponent } from 'react'
import { SkillsList } from '../talent/SkillsList'
import { Position } from '../../contracts/profile/position'
import { COLOR_PALETTE, mqMax } from '../../GlobalStyle'

const TimelineEntryContainer = styled.div`
    width: 100%;
    margin: 0 0 24px 24px;
    border: 1px solid ${COLOR_PALETTE.grey_light};
    background-color: ${COLOR_PALETTE.white};
    padding: 26px;

    ${mqMax[1]} {
        margin: 0;
    }
`

const TimelineEntryHeader = styled.div`
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    border-bottom: 1px solid ${COLOR_PALETTE.grey_light};
    padding-bottom: 20px;
`

const TimelineEntryLabeledInfo = ({ label, children, ...other }: { label: string; children: any }) => {
    return (
        <div
            css={css`
                margin-top: 20px;
                font-size: 14px;
            `}
            {...other}
        >
            <div
                css={css`
                    display: flex;

                    align-items: center;
                `}
            >
                <div
                    css={css`
                        margin-right: 10px;
                        color: ${COLOR_PALETTE.grey};
                        flex: 0 0 15%;
                    `}
                >
                    {label}:
                </div>
                <div>{children}</div>
            </div>
        </div>
    )
}

type TimelineEntryProps = { position: Position }

const TimelineEntry: FunctionComponent<TimelineEntryProps> = ({ position }) => {
    return (
        <TimelineEntryContainer>
            <TimelineEntryHeader>
                <div>{position.roles.join(', ')}</div>
                {position.durationInMonths && (
                    <TimelineEntryLabeledInfo
                        label='Duration'
                        css={css`
                            margin-top: 0;
                            font-size: 12px;
                        `}
                    >
                        <div
                            css={css`
                                background-color: ${COLOR_PALETTE.grey_light};
                                border-radius: 55px;
                                padding: 2px 12px;
                            `}
                        >
                            {position.durationInMonths} Months
                        </div>
                    </TimelineEntryLabeledInfo>
                )}
            </TimelineEntryHeader>
            {position.industry && (
                <TimelineEntryLabeledInfo label='Industry'>
                    <p>{position.industry}</p>
                </TimelineEntryLabeledInfo>
            )}
            {position.techStack.length > 0 && (
                <TimelineEntryLabeledInfo label='Stack'>
                    <SkillsList skills={position.techStack} />
                </TimelineEntryLabeledInfo>
            )}
        </TimelineEntryContainer>
    )
}

export { TimelineEntry }
