/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Link } from 'react-router-dom'
import { sendVerificationEmail } from '../../api/firebase'
import { Button } from '../../components/Button'
import { Divider } from '../../components/Divider'
import { Footer } from '../../components/Footer'
import { Icon } from '../../components/Icon'
import { Logo } from '../../components/Logo'
import { useNotifications } from '../../components/notification/NotificationProvider'
import { YellowClouds } from '../../components/YellowClouds'
import { bodyBig, COLOR_PALETTE, mqMax, mqMin } from '../../GlobalStyle'

const RegisterConfirmation = () => {
    const { addSuccess, addError } = useNotifications()

    const handleEmailResend = () => {
        sendVerificationEmail().then(
            () => {
                addSuccess('Verification link sent!')
            },
            () => {
                addError('Failed to send verification link. Please try again.')
            },
        )
    }

    return (
        <section
            css={css`
                display: flex;
                height: 100vh;
                background-color: ${COLOR_PALETTE.white};
                flex-direction: column;
            `}
        >
            <YellowClouds>
                <div
                    css={css`
                        position: absolute;
                        top: 0;
                        left: 0;
                        display: flex;
                        justify-content: space-between;
                        width: 100%;

                        padding: 50px 10px;
                        z-index: 3;
                        box-sizing: border-box;

                        ${mqMin[1]} {
                            padding: 50px 130px;
                        }
                    `}
                >
                    <Logo
                        style={css`
                            height: 38px;
                            margin-left: 0;
                            margin-top: 0;
                        `}
                        type='logoFullMono'
                    />
                    <Link
                        to='/'
                        css={css`
                            ${mqMax[0]} {
                                display: none;
                            }
                        `}
                    >
                        <Button
                            style={css`
                                height: min-content;
                            `}
                            variant='secondary'
                        >
                            Login
                        </Button>
                    </Link>
                </div>

                <div
                    css={css`
                        position: relative;
                        z-index: 3;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;
                        text-align: center;
                        padding: 20px;
                    `}
                >
                    <div
                        css={css`
                            height: 100px;
                            width: 100px;
                            border: 4px solid ${COLOR_PALETTE.white};
                            border-radius: 50%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        `}
                    >
                        <Icon
                            style={css`
                                height: 28px;
                                width: 32px;
                            `}
                            name='mail'
                        />
                    </div>
                    <h2>Thanks for Joining Us</h2>
                    <p
                        css={css`
                            ${bodyBig}
                            margin-top: 4px;
                        `}
                    >
                        An email verification is waiting in your inbox. See you soon!
                    </p>
                    <Divider
                        color={COLOR_PALETTE.yellow_dark}
                        style={css`
                            width: 234px;
                        `}
                    />
                    <p
                        css={css`
                            ${bodyBig}
                        `}
                    >
                        Didn’t get an email?{' '}
                        <strong
                            css={css`
                                cursor: pointer;

                                &:hover {
                                    text-decoration: underline;
                                }
                            `}
                            onClick={handleEmailResend}
                        >
                            Click here
                        </strong>{' '}
                        and we’ll resend it.
                    </p>
                </div>
            </YellowClouds>
            <Footer />
        </section>
    )
}

export { RegisterConfirmation }
