/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { FunctionComponent, SyntheticEvent } from 'react'
import { Link } from 'react-router-dom'
import { AppTileConfig, AppTileStatus } from '../../../contracts/applications'
import { COLOR_PALETTE, mqMin } from '../../../GlobalStyle'
import { Badge } from '../../Badge'
import { AppIcon, IconName } from '../../Icon'
import { Tooltip, TooltipIcon } from '../../Tooltip'

type DashboardTilesProps = {
    tiles?: Array<AppTileConfig>
    margin?: number
}

const DashboardTiles: FunctionComponent<DashboardTilesProps> = ({ children, tiles = [], margin = 10 }) => {
    return (
        <div
            css={css`
                max-width: 1232px;
                display: grid;
                gap: ${2 * margin}px;
                grid-template-columns: repeat(2, 1fr);
                ${mqMin[1]} {
                    grid-template-columns: repeat(3, 1fr);
                }
                ${mqMin[2]} {
                    grid-template-columns: repeat(6, 1fr);
                }
            `}
        >
            {tiles.map(tile => (
                <DashboardTile key={tile.title} {...tile} />
            ))}
            {children}
        </div>
    )
}

const getTileStyles = (status: AppTileStatus) => {
    let styles
    switch (status) {
        case 'available':
            styles = css`
                border-color: ${COLOR_PALETTE.yellow};
                background: ${COLOR_PALETTE.yellow};
                cursor: pointer;
                &:hover {
                    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
                }
            `
            break
        case 'unavailable':
            styles = css`
                cursor: pointer;
                border-color: ${COLOR_PALETTE.grey_light};
                background: transparent;
            `
            break
        default:
            styles = css`
                border-color: ${COLOR_PALETTE.grey_lighter};
                background: ${COLOR_PALETTE.grey_lighter};
                cursor: not-allowed;
            `
    }
    return styles
}

type DashboardTileProps = AppTileConfig & {
    margin?: number
}

const DashboardTile: FunctionComponent<DashboardTileProps> = ({ title, subtitle, hint, to, status, icon, margin = 10 }) => {
    const handleClick = (e: SyntheticEvent) => {
        if (status === 'disabled') {
            e.preventDefault()
        }
    }
    return (
        <Link
            to={to}
            onClick={handleClick}
            css={css`
                position: relative;
                display: inline-flex;
                max-width: 240px;
                height: 213px;
                border-width: 1px;
                border-style: solid;
                border-radius: 2px;
                ${getTileStyles(status)};
            `}
        >
            <span
                css={css`
                    display: block;
                    position: absolute;
                    top: 3px;
                    right: 7px;
                `}
            >
                <Tooltip content={hint}>
                    <TooltipIcon
                        css={css`
                            background-color: ${COLOR_PALETTE.white};
                        `}
                    >
                        ?
                    </TooltipIcon>
                </Tooltip>
            </span>
            <div
                css={css`
                    position: absolute;
                    top: 22%;
                    bottom: 17px;
                    left: 0;
                    right: 0;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: space-between;
                `}
            >
                <AppIcon name={icon as IconName} status={status} />
                <strong
                    css={css`
                        max-width: 60%;
                        text-align: center;
                        color: ${status === 'disabled' ? COLOR_PALETTE.grey : COLOR_PALETTE.black};
                    `}
                >
                    {title}
                </strong>
                <Badge variant={status === 'available' ? 'solid' : 'outlined'} disabled={status === 'disabled'}>
                    {subtitle}
                </Badge>
            </div>
        </Link>
    )
}

export { DashboardTiles, DashboardTile }
