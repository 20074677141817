// _colors.less 1:1
const COLOR_PALETTE = {
    black: '#13100D', // gray_1_50
    grey_darker: '#292929', // gray_1_45
    grey_dark: '#6F6F6F', // gray_1_40
    grey: '#ACACAC', // gray_1_30
    grey_light: '#E8E8E8', // gray_1_20
    grey_lighter: '#F8F8F8', // gray_1_10
    white: '#fff',

    yellow_dark: '#FBD301', // yellow_1_20
    yellow: '#FFE900', // yellow_1_10
    yellow_light: '#F4F198', // yellow_1_30
    yellow_lighter: '#F4F4CC', // yellow_1_10

    green: '#005956', // green_1_30
    green_light: '#00C7C4', // green_1_20
    green_lighter: '#CFF4F3', // green_1_10

    blue_light: '#82B5E4', // blue_2
    blue_lighter: '#BEE0FF', // blue_1

    violet_darker: '#1E0842',
    violet_dark: '#3B307A', // violet_1_40
    violet: '#6C67A0', // violet_1_30,
    violet_light: '#C0B7D1', // violet_1_20
    violet_lighter: '#F0ECF5', // violet_1_10

    red_dark: '#980404',
    red: '#C20000', // red_1
    red_lighter: '#F9E5E5', // red_3
}

export { COLOR_PALETTE }
