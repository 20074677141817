/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Fragment, FunctionComponent, useCallback, useContext, useEffect, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import { getDocuments, getUserDocuments } from '../../api/api'
import { Divider } from '../../components/Divider'
import { DashboardLayout } from '../../components/layout/dashboard/DashboardLayout'
import { ContentLoader } from '../../components/layout/Loader'
import { Document } from '../../contracts/documentsResponse'
import { mqMin } from '../../GlobalStyle'
import { ReduxContext } from '../../redux/Store'

enum DocType {
    privacyPolicy,
    termsOfUse,
    marketingConsents,
}

type DocumentViewProps = {
    type: DocType
}

const DocumentView: FunctionComponent<DocumentViewProps> = ({ type }) => {
    const {
        selectors: { firebaseId, user },
    } = useContext(ReduxContext)

    const [doc, setDoc] = useState<any>(null)

    const findDoc = useCallback(
        docs => {
            let output = null
            switch (type) {
                case DocType.privacyPolicy:
                    output = docs.find((d: Document) => d.type === 'PRIVACY_POLICY')
                    break

                case DocType.termsOfUse:
                    output = docs.find((d: Document) => d.type === 'TERMS_OF_USE')
                    break

                case DocType.marketingConsents:
                    output = docs.find((d: Document) => d.type === 'MARKETING_CONSENTS')
                    break

                default:
                    break
            }

            return output
        },
        [type],
    )

    const handleDocsResponse = useCallback(
        docs => {
            const selectedDoc = findDoc(docs)

            if (selectedDoc) {
                setDoc(selectedDoc)
            }
        },
        [findDoc],
    )

    const getDocName = useCallback(() => {
        let output
        switch (type) {
            case DocType.privacyPolicy:
                output = 'Privacy policy'
                break

            case DocType.termsOfUse:
                output = 'Terms of use'
                break

            case DocType.marketingConsents:
                output = 'Marketing consents'
                break

            default:
                break
        }

        return output
    }, [type])

    useEffect(() => {
        if (user !== null && doc === null) {
            if (firebaseId !== null) {
                getUserDocuments(firebaseId as string).then(handleDocsResponse)
            } else {
                getDocuments().then(handleDocsResponse)
            }
        }
    }, [firebaseId, doc, handleDocsResponse, user])

    return (
        <DashboardLayout showMenuItems={false}>
            <div
                css={css`
                    display: flex;
                    width: 100%;
                    flex-direction: column;
                    margin: 50px auto;
                    padding: 24px;

                    ${mqMin[2]} {
                        width: 70%;
                    }
                `}
            >
                <Fragment>
                    <h4>{getDocName()}</h4>
                    <Divider
                        style={css`
                            margin-bottom: 30px;
                        `}
                    />
                </Fragment>

                {doc ? (
                    <div
                        css={css`
                            padding: 0 0 100px 0;
                        `}
                    >
                        <ReactMarkdown source={doc.markdownContent} className='mark-down' />
                    </div>
                ) : (
                    <ContentLoader />
                )}
            </div>
        </DashboardLayout>
    )
}

export { DocumentView, DocType }
