/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { signInWithEmailAndPassword } from '../api/firebase'
import { COLOR_PALETTE } from '../GlobalStyle'
import { Alert } from './Alert'
import { AlternativeAuthMethods } from './AlternativeAuthMethods'
import { Button } from './Button'
import { Input } from './Input'
import { FieldWrapper } from './layout/FormHelpers'
import { Logo } from './Logo'

const LoginForm = () => {
    const location = useLocation()
    const [showLogoutAlert, setShowLogoutAlert] = useState(location.pathname === '/logout')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [wrongCredentials, setWrongCredentials] = useState<boolean>(false)

    const handleSubmit = () => {
        setShowLogoutAlert(false)
        return signInWithEmailAndPassword(email, password).then(response => {
            if (!response) {
                setWrongCredentials(true)
            }
        })
    }

    return (
        <section
            css={css`
                display: flex;
                justify-content: center;
                padding: 16px;
            `}
        >
            <div
                css={css`
                    width: 408px;
                    padding-top: 28px;
                `}
            >
                <Link to='/'>
                    <Logo
                        type='logoFull'
                        style={css`
                            margin-left: 0;
                            margin-bottom: 36px;
                        `}
                    />
                </Link>

                <div>
                    {wrongCredentials && <Alert content='Incorrect email address or password.' type='error' />}
                    {showLogoutAlert && <Alert content='You have been successfully logged out.' />}
                </div>

                <h3>Login</h3>
                <form
                    css={css`
                        display: flex;
                        flex-direction: column;
                    `}
                >
                    <FieldWrapper>
                        <Input name='email' placeholder='Email' label='Email' value={email} onChange={setEmail} />
                    </FieldWrapper>
                    <FieldWrapper>
                        <Input
                            name='password'
                            placeholder='Password'
                            label='Password'
                            type='password'
                            hasPassword={true}
                            value={password}
                            onChange={setPassword}
                        />
                    </FieldWrapper>

                    <Link
                        to='/recover-password'
                        css={css`
                            margin-bottom: 20px;
                            text-align: right;
                        `}
                    >
                        Forgot your password?
                    </Link>

                    <Button
                        variant='primary'
                        onClick={handleSubmit}
                        style={css`
                            margin-bottom: 16px;
                        `}
                    >
                        Log in
                    </Button>
                </form>
                <p
                    css={css`
                        width: 100%;
                        text-align: center;
                        color: ${COLOR_PALETTE.grey_dark};
                    `}
                >
                    Don’t have access yet? <Link to='/register'>Join Us now</Link>
                </p>
                <AlternativeAuthMethods />
            </div>
        </section>
    )
}

export { LoginForm }
