/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { FunctionComponent, useCallback } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { bodyBold, COLOR_PALETTE, mqMin } from '../../../GlobalStyle'
import { AutocompleteSelect } from '../../autocomplete-select/AutocompleteSelect'

type MenuItemProps = {
    path?: string
    isActive?: boolean
}

const MenuItem: FunctionComponent<MenuItemProps> = ({ path, isActive, children }) => {
    const activeItemStyle = css`
        border-left: 3px solid ${COLOR_PALETTE.yellow};
    `

    return (
        <li
            css={css`
                border-left: 3px solid transparent;
                padding: 6px 20px;

                &:hover {
                    ${activeItemStyle};
                    cursor: ${isActive ? 'default' : 'pointer'};
                }

                ${isActive && activeItemStyle}
            `}
        >
            {children}
        </li>
    )
}

type MenuItemType = {
    title: string
    path: string
}

export type MenuItems = Array<MenuItemType>

type MainMenuProps = {
    menuItems: MenuItems
    label: string
}

const MainMenu: FunctionComponent<MainMenuProps> = ({ menuItems, label }) => {
    const location = useLocation()
    const history = useHistory()

    const convertValuesToStrings = useCallback(() => menuItems.map(item => item.title), [menuItems])

    const getSelectedItem = useCallback(() => menuItems.filter(item => location.pathname === item.path).map(item => item.title), [
        menuItems,
        location.pathname,
    ])

    const handleChange = useCallback(
        (event: Array<string>) => {
            const selectedItem = menuItems.find(item => event.includes(item.title))
            if (selectedItem) {
                history.push(selectedItem.path)
            }
        },
        [history, menuItems],
    )

    return (
        <div
            css={css`
                display: flex;
                flex-direction: column;
                width: 100%;
                background-color: ${COLOR_PALETTE.grey_lighter};
                position: relative;
                top: 57px;
                margin-bottom: 57px;
                ${mqMin[2]} {
                    background-color: ${COLOR_PALETTE.white};
                    top: 0;
                }
            `}
        >
            <div
                css={css`
                    margin-bottom: 5px;
                    padding: 20px 24px;
                    color: ${COLOR_PALETTE.black};

                    ${bodyBold};

                    ${mqMin[2]} {
                        color: ${COLOR_PALETTE.grey};
                    }
                `}
            >
                {label}
            </div>

            <div
                css={css`
                    display: block;
                    margin: 0 20px;

                    ${mqMin[2]} {
                        display: none;
                    }
                `}
            >
                <AutocompleteSelect
                    placeholder='Select section'
                    options={convertValuesToStrings()}
                    currentValues={getSelectedItem()}
                    onSelectedValuesChange={handleChange}
                    canFilter={false}
                />
            </div>

            <ul
                css={css`
                    display: none;
                    list-style: none;
                    padding: 0 0 25px;
                    margin: 0;

                    ${mqMin[2]} {
                        display: block;
                    }
                `}
            >
                {menuItems.map((item, index) => (
                    <MenuItem key={index} isActive={location.pathname === item.path}>
                        <Link to={item.path}>{item.title}</Link>
                    </MenuItem>
                ))}
            </ul>
        </div>
    )
}

export { MainMenu }
