/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Fragment, useContext, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import * as yup from 'yup'
import { editCompany, getCities, getCompanies, getCompanyRoles, getCountries, saveCompany } from '../../../api/api'
import { AutocompleteSelect } from '../../../components/autocomplete-select/AutocompleteSelect'
import { Button } from '../../../components/Button'
import { Divider } from '../../../components/Divider'
import { Input } from '../../../components/Input'
import { DashboardLayout, MenuType } from '../../../components/layout/dashboard/DashboardLayout'
import { FieldWrapper } from '../../../components/layout/FormHelpers'
import { Loader } from '../../../components/layout/Loader'
import { FirstColumn, ResponsiveColumns, SecondColumn } from '../../../components/layout/ResponsiveColumns'
import { Modal } from '../../../components/Modal'
import { useNotifications } from '../../../components/notification/NotificationProvider'
import { Tooltip, TooltipIcon } from '../../../components/Tooltip'
import { COLOR_PALETTE, mqMax } from '../../../GlobalStyle'
import { ReduxContext } from '../../../redux/Store'
import { resolveFieldError } from '../../../utils/errors'
import { emailRegex, websiteRegex } from '../../../utils/regexes'

const validFormatEmail = 'E-mail is invalid'
const validFormatWebsite = 'Website is invalid'
const schema = yup.object().shape({
    legalName: yup.string().required('This field is required'),
    country: yup.string().required('This field is required'),
    city: yup.string().required('This field is required'),
    postalCode: yup.string().required('This field is required'),
    address: yup.string().required('This field is required'),
    registrationNumber: yup.number().typeError('Registration Number is invalid').required('This field is required'),
    website: yup.string().matches(websiteRegex, validFormatWebsite).required('This field is required'),
    workEmailAddress: yup.string().matches(emailRegex, validFormatEmail).required('This field is required'),
    roleInTheCompany: yup.string().required('This field is required'),
})

export interface CompanyAccountFormData {
    legalName: string
    country: string
    city: string
    postalCode: string
    address: string
    registrationNumber: number
    website: string
    workEmailAddress: string
    roleInTheCompany: string
    numberOfNonAdminEmployees?: number
}

const CompanyDetailsForm = () => {
    const {
        selectors: { firebaseId },
    } = useContext(ReduxContext)
    const { addSuccess, addError } = useNotifications()
    const history = useHistory()
    const [editMode, setEditMode] = useState<boolean>(false)
    const [countries, setCountries] = useState<Array<string>>([])
    const [cities, setCities] = useState<Array<string>>([])
    const [companyRoles, setCompanyRoles] = useState<Array<string>>([])
    const [saveChangesModalVisible, setSaveChangesModalVisible] = useState(false)
    const [companyId, setCompanyId] = useState('')
    const [loading, setLoading] = useState(false)
    const { register, handleSubmit, errors, formState, control, setValue, watch, getValues, reset } = useForm({
        validationSchema: schema,
    })

    const watchCountry = watch('country')

    useEffect(() => {
        getCountries().then((data: any) => {
            setCountries(data.map((c: any) => c.name))
        })

        getCompanyRoles().then((data: Array<string>) => {
            setCompanyRoles(data)
        })

        if (firebaseId) {
            getCompanies(firebaseId).then((companies: Array<any>) => {
                if (companies.length > 0) {
                    const company = companies.pop()
                    const newInitialValues = {
                        address: company.address,
                        city: company.city,
                        country: company.country,
                        legalName: company.legalName,
                        numberOfNonAdminEmployees: company.numberOfNonAdminEmployees,
                        postalCode: company.postalCode,
                        registrationNumber: company.registrationNumber,
                        roleInTheCompany: company.roleInTheCompany,
                        userId: company.userId,
                        website: company.website,
                        workEmailAddress: company.workEmailAddress,
                    }

                    setCompanyId(company.id)
                    setEditMode(true)
                    reset(newInitialValues)
                }
            })
        }
    }, [firebaseId, reset])

    const getCitiesForCountry = (country: string): void => {
        getCities(country).then((data: Array<string>) => {
            setCities(data)
        })
    }

    useEffect(() => {
        getCitiesForCountry(watchCountry)

        if (getValues('city') && formState.touched?.country) {
            setValue('city', '')
        }
    }, [watchCountry, getValues, setValue, formState.touched])

    const onSubmit = async (data: any) => {
        setLoading(true)

        if (editMode) {
            editCompany(mapFormToRequestData(data))
                .then(
                    () => {
                        history.push('/dashboard/company-account/company-details')
                        addSuccess('Company form submitted successfuly.')
                    },
                    () => {
                        addError('There was a problem submitting your form. Please try again.')
                    },
                )
                .finally(() => {
                    setLoading(false)
                })
        } else {
            saveCompany(mapFormToRequestData(data))
                .then(
                    () => {
                        history.push('/dashboard/company-account/company-details')
                        addSuccess('Company form submitted successfuly.')
                    },
                    () => {
                        addError('There was a problem submitting your form. Please try again.')
                    },
                )
                .finally(() => {
                    setLoading(false)
                })
        }
    }

    const mapFormToRequestData = (form: CompanyAccountFormData): any => {
        return {
            id: companyId ? companyId : null,
            userId: firebaseId,
            legalName: form.legalName,
            country: form.country,
            city: form.city,
            postalCode: form.postalCode,
            address: form.address,
            registrationNumber: form.registrationNumber,
            website: form.website,
            roleInTheCompany: form.roleInTheCompany,
            workEmailAddress: form.workEmailAddress,
            numberOfNonAdminEmployees: form?.numberOfNonAdminEmployees,
        }
    }

    const handleCancel = (): void => {
        if (formState.dirty) {
            setSaveChangesModalVisible(true)
        } else {
            cancel()
        }
    }

    const cancel = (): void => {
        history.push('/dashboard')
    }

    return (
        <Fragment>
            <DashboardLayout menuType={MenuType.companyAccount}>
                <div
                    css={css`
                        display: flex;
                        width: 100%;
                    `}
                >
                    <section
                        css={css`
                            display: block;
                            max-width: 547px;
                            width: 100%;
                            padding: 32px 0 32px 120px;

                            ${mqMax[2]} {
                                margin-top: 58px;
                            }

                            ${mqMax[1]} {
                                padding: 24px;
                                max-width: none;
                            }
                        `}
                    >
                        <h4>Company Account</h4>
                        <Divider />

                        <section>
                            {loading && <Loader />}
                            <h5>Company Details</h5>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <section>
                                    <ResponsiveColumns>
                                        <FirstColumn>
                                            <FieldWrapper>
                                                <Input
                                                    name='legalName'
                                                    label='Company Legal Name'
                                                    placeholder='Company Legal Name'
                                                    innerRef={register}
                                                    errorText={resolveFieldError(errors.firstName)}
                                                />
                                            </FieldWrapper>
                                        </FirstColumn>
                                        <SecondColumn>
                                            <FieldWrapper>
                                                <Controller
                                                    as={
                                                        <AutocompleteSelect
                                                            label='Country'
                                                            placeholder='Country'
                                                            options={countries}
                                                            errors={errors.country}
                                                            currentValues={[getValues('country')]}
                                                        />
                                                    }
                                                    control={control}
                                                    onChange={val => val[0][0]}
                                                    onChangeName='onSelectedValuesChange'
                                                    name='country'
                                                />
                                            </FieldWrapper>
                                        </SecondColumn>
                                    </ResponsiveColumns>

                                    <ResponsiveColumns>
                                        <FirstColumn>
                                            <FieldWrapper>
                                                <Controller
                                                    as={
                                                        <AutocompleteSelect
                                                            label='City'
                                                            placeholder='City'
                                                            options={cities}
                                                            errors={errors.city}
                                                            currentValues={[getValues('city')]}
                                                            disabled={!watchCountry}
                                                        />
                                                    }
                                                    control={control}
                                                    onChange={val => val[0][0]}
                                                    onChangeName='onSelectedValuesChange'
                                                    name='city'
                                                />
                                            </FieldWrapper>
                                        </FirstColumn>
                                        <SecondColumn>
                                            <FieldWrapper>
                                                <Input
                                                    name='postalCode'
                                                    label='Postal Code'
                                                    placeholder='Postal Code'
                                                    innerRef={register}
                                                    errorText={resolveFieldError(errors.postalCode)}
                                                />
                                            </FieldWrapper>
                                        </SecondColumn>
                                    </ResponsiveColumns>
                                    <FieldWrapper>
                                        <Input
                                            name='address'
                                            label='Company Address (street, no.)'
                                            placeholder='Company Address (street, no.)'
                                            innerRef={register}
                                            errorText={resolveFieldError(errors.address)}
                                        />
                                    </FieldWrapper>
                                    <FieldWrapper>
                                        <Input
                                            name='registrationNumber'
                                            label='Company Registration Number'
                                            placeholder='Company Registration Number'
                                            type='number'
                                            innerRef={register}
                                            errorText={resolveFieldError(errors.registrationNumber)}
                                            tooltip='This is a unique number in the official company registry records. In Poland it is referred to as KRS. If you would like to change your number, please contact our Human Cloud Success Team.'
                                        />
                                    </FieldWrapper>
                                    <Divider />
                                    <p>We’ll use your Personal Details to create your Company Account.</p>
                                </section>

                                <section>
                                    <h6>
                                        More information
                                        <Tooltip content='More info'>
                                            <TooltipIcon>?</TooltipIcon>
                                        </Tooltip>
                                    </h6>
                                    <FieldWrapper>
                                        <Input
                                            name='website'
                                            label='Official Company Website'
                                            placeholder='Official Company Website'
                                            innerRef={register}
                                            errorText={resolveFieldError(errors.website)}
                                        />
                                    </FieldWrapper>
                                    <FieldWrapper>
                                        <Input
                                            name='workEmailAddress'
                                            label='Your Work Email Address'
                                            placeholder='Your Work Email Address'
                                            innerRef={register}
                                            errorText={resolveFieldError(errors.workEmailAddress)}
                                        />
                                    </FieldWrapper>
                                    <FieldWrapper>
                                        <Controller
                                            as={
                                                <AutocompleteSelect
                                                    label='Your Role in the Company'
                                                    placeholder='Your Role in the Company'
                                                    options={companyRoles}
                                                    errors={errors.roleInTheCompany}
                                                    currentValues={[getValues('roleInTheCompany')]}
                                                />
                                            }
                                            control={control}
                                            onChange={v => v[0][0]}
                                            onChangeName='onSelectedValuesChange'
                                            name='roleInTheCompany'
                                        />
                                    </FieldWrapper>
                                    <FieldWrapper>
                                        <Input
                                            name='numberOfNonAdminEmployees'
                                            label='No. of non-admin Employees (optional)'
                                            placeholder='No. of non-admin Employees'
                                            innerRef={register}
                                            tooltip='No. of Employees'
                                        />
                                    </FieldWrapper>
                                    <Divider />
                                </section>

                                <section
                                    css={css`
                                        margin-bottom: 24px;

                                        ${mqMax[1]} {
                                            display: flex;
                                            flex-direction: column-reverse;
                                        }
                                    `}
                                >
                                    <Button
                                        variant='link'
                                        style={css`
                                            margin-right: 24px;

                                            ${mqMax[1]} {
                                                margin: 12px 0 0;
                                            }
                                        `}
                                        onClick={handleCancel}
                                    >
                                        Cancel
                                    </Button>
                                    <Button variant='primary' type='submit' disabled={formState.isSubmitting || !formState.dirty}>
                                        Save Company Account
                                    </Button>
                                </section>
                            </form>
                        </section>
                    </section>
                </div>
            </DashboardLayout>

            <Modal onClose={() => setSaveChangesModalVisible(false)} opened={saveChangesModalVisible}>
                <h5
                    css={css`
                        color: ${COLOR_PALETTE.black};
                    `}
                >
                    Are you sure you want to leave?
                </h5>
                <p
                    css={css`
                        color: ${COLOR_PALETTE.grey_dark};
                    `}
                >
                    The changes you made will not be saved.
                </p>
                <Divider />
                <div
                    css={css`
                        display: flex;
                        justify-content: flex-end;
                    `}
                >
                    <Button
                        css={css`
                            margin-right: 29px;
                        `}
                        variant='link'
                        onClick={() => setSaveChangesModalVisible(false)}
                    >
                        Go Back
                    </Button>
                    <Button variant='primary' onClick={cancel}>
                        Leave
                    </Button>
                </div>
            </Modal>
        </Fragment>
    )
}

export { CompanyDetailsForm }
