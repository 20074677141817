import { EmployeesFilters } from '../../contracts/employees/employeesFilters'
import { countArrayFilters } from '../../utils/filters'

const countSelectedFilters = (filters: EmployeesFilters): number => {
    let counter = 0
    const { remote } = filters

    counter += countArrayFilters(filters, ['employmentTypes', 'roles', 'seniorities', 'skills', 'locations'])

    if (remote) {
        counter++
    }

    return counter
}

export { countSelectedFilters }
