/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Fragment, FunctionComponent } from 'react'
import { Button } from '../../../components/Button'
import { SectionHeaderWithActions } from '../shared/HeaderWithActions'
import { SectionDataNotFound, SectionWrapper } from './SectionHelpers'

const EducationTab: FunctionComponent = () => {
    return (
        <Fragment>
            <SectionWrapper>
                <SectionHeaderWithActions header='Qualification' divider />
                <SectionDataNotFound description='Add data to inform about your Education.'>
                    <Button>Add Education</Button>
                </SectionDataNotFound>
            </SectionWrapper>
            <SectionWrapper>
                <SectionHeaderWithActions header='Languages' divider />
                <SectionDataNotFound description='Currently you have no languages added.'>
                    <Button>Add Languages</Button>
                </SectionDataNotFound>
            </SectionWrapper>
        </Fragment>
    )
}

export { EducationTab }
