/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import RcPagination, { PaginationProps } from 'rc-pagination'
import 'rc-pagination/assets/index.css'
import React, { FunctionComponent } from 'react'
import { COLOR_PALETTE } from '../../GlobalStyle'

const defaultLocale = {
    items_per_page: '/ page',
    jump_to: 'Go to',
    jump_to_confirm: 'confirm',
    page: '',
    prev_page: 'Previous Page',
    next_page: 'Next Page',
    prev_5: 'Previous 5 Pages',
    next_5: 'Next 5 Pages',
    prev_3: 'Previous 3 Pages',
    next_3: 'Next 3 Pages',
}

const PaginationElementStylesBase = css`
    height: 24px;
    min-width: 24px;
    line-height: 24px;
    font-size: 12px;
    margin-right: 4px;
    border-radius: 2px;
    border: none;
`

const PaginationElementShadow = css`
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.08);
`

const PaginationStyles = css`
    .rc-pagination-item {
        ${PaginationElementStylesBase}
        ${PaginationElementShadow}
        padding: 0 4px;
        background-color: ${COLOR_PALETTE.white};
        &:hover {
            background-color: ${COLOR_PALETTE.grey_darker};
            & a {
                color: white;
                text-decoration: none;
            }
        }
    }
    .rc-pagination-item-active {
        background-color: ${COLOR_PALETTE.black};
        & a {
            color: white;
            text-decoration: none;
        }
    }
    .rc-pagination-jump-prev,
    .rc-pagination-jump-next {
        ${PaginationElementStylesBase}
        color: ${COLOR_PALETTE.black};
        &:after {
            margin-top: 4px;
            content: '...';
            display: block;
            color: ${COLOR_PALETTE.black};
        }
    }
    .rc-pagination-prev,
    .rc-pagination-next {
        ${PaginationElementStylesBase}
        ${PaginationElementShadow}
        padding: 0 8px;
        background-color: ${COLOR_PALETTE.black};
        color: white;
        &:hover {
            background-color: ${COLOR_PALETTE.grey_darker};
            color: white;
        }
    }
    .rc-pagination-disabled,
    .rc-pagination-disabled:hover {
        background-color: ${COLOR_PALETTE.grey_light};
        color: ${COLOR_PALETTE.grey}
    }
`

type ItemRenderer = (page: number, type: string, element: React.ReactNode) => React.ReactNode

const customItemRender: ItemRenderer = (current, type, element) => {
    if (type === 'prev') {
        element = 'Prev'
    } else if (type === 'next') {
        element = 'Next'
    } else if (type === 'jump-prev' || type === 'jump-next') {
        element = null
    }
    return element
}

const Pagination: FunctionComponent<PaginationProps> = ({ itemRender = customItemRender, locale = defaultLocale, ...props }) => {
    return <RcPagination css={PaginationStyles} locale={defaultLocale} itemRender={itemRender} {...props} />
}

export { Pagination }
