/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import * as yup from 'yup'
import { confirmPasswordReset } from '../../api/firebase'
import { Alert } from '../../components/Alert'
import { BackLink } from '../../components/BackLink'
import { Button } from '../../components/Button'
import { Input } from '../../components/Input'
import { FieldWrapper } from '../../components/layout/FormHelpers'
import { ResponsiveWrapper } from '../../components/layout/ResponsiveWrapper'
import {
    extractStrengthIndicators,
    NO_CAPITAL,
    NO_DIGIT,
    PasswordStrength,
    TOO_LONG,
    TOO_SHORT,
} from '../../components/password/PasswordStrength'
import { resolveFieldError } from '../../utils/errors'

const createNewPasswordSchema = yup.object({
    password: yup
        .string()
        .required('Password is required')
        .min(8, TOO_SHORT)
        .max(24, TOO_LONG)
        .test('oneCapitalTest', NO_CAPITAL, value => /[A-Z]/.test(value))
        .test('oneDigitTest', NO_DIGIT, value => /[\d]/.test(value)),
    passwordConfirm: yup
        .string()
        .oneOf([yup.ref('password')], 'Passwords must match')
        .required('Passwords must match'),
})

const resolveApiError = (error: any) => {
    if (error.code === 'auth/invalid-action-code') {
        return error.message
    }
    return 'There was an error.'
}

const RecoverPasswordNewPassword = () => {
    const history = useHistory()
    const [apiError, setApiError] = useState('')
    const { register, handleSubmit, errors, formState } = useForm({
        validationSchema: createNewPasswordSchema,
        validateCriteriaMode: 'all',
        mode: 'onChange',
    })

    const code = 'foo' // TBD: get code from router after changing password-reset flow
    const onSubmit = async (data: any) => {
        const { password } = data
        try {
            await confirmPasswordReset(code, password)
            history.push('/?notification=password-reset-success')
        } catch (error) {
            setApiError(resolveApiError(error))
        }
    }
    const { indicators, other: otherPasswordErrors } = extractStrengthIndicators(
        errors.password,
        formState.dirty || formState.touched.password,
    )
    return (
        <ResponsiveWrapper withLogo>
            <form onSubmit={handleSubmit(onSubmit)}>
                <BackLink path='/' text='Back to Login' />
                <h1>Recover Password</h1>
                {apiError && <Alert type='error' content={apiError} />}
                <p>Please enter the business e-mail address you used to set up your Talent Alpha account.</p>
                <FieldWrapper>
                    <Input
                        name='password'
                        label='Password'
                        placeholder='Password'
                        type='password'
                        hasPassword={true}
                        innerRef={register}
                        errorText={resolveFieldError(otherPasswordErrors)}
                    />
                </FieldWrapper>
                <FieldWrapper>
                    <Input
                        name='passwordConfirm'
                        label='Repeat password'
                        placeholder='Repeat password'
                        type='password'
                        hasPassword={true}
                        innerRef={register}
                        errorText={resolveFieldError(errors.passwordConfirm)}
                    />
                </FieldWrapper>
                <PasswordStrength indicators={indicators} />
                <Button variant='primary' type='submit' disabled={formState.isSubmitting}>
                    Confirm New Password
                </Button>
            </form>
        </ResponsiveWrapper>
    )
}

export { RecoverPasswordNewPassword }
