/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Fragment, FunctionComponent } from 'react'
import cloudIcon from '../../../assets/icons/cloud.svg'
import google from '../../../assets/logos/google.svg'
import linkedin_color from '../../../assets/logos/linkedin_color.svg'
import microsoft from '../../../assets/logos/microsoft.svg'
import { Button, ButtonSize, ButtonVariant } from '../../../components/Button'
import { ExpandableSectionProps, MarginBox, More } from '../components/PresentationHelpers'

const Buttons: FunctionComponent<ExpandableSectionProps> = ({ opened }) => {
    const buttonVariants: Array<ButtonVariant> = [
        'primary',
        'secondary',
        'tertiary',
        'talent',
        'delete',
        'sso',
        'text',
        'link',
        'linkDelete',
        'linkForm',
    ]
    const buttonSizes: Array<ButtonSize> = ['medium', 'small']
    return (
        <Fragment>
            <h5>Buttons</h5>
            <Button icon={cloudIcon} variant='primary'>
                test
            </Button>
            <h6>Button SSO</h6>
            <Button
                icon={microsoft}
                variant='sso'
                css={css`
                    margin-bottom: 16px;
                `}
            >
                Continue with Microsoft
            </Button>
            <br />
            <Button
                icon={google}
                variant='sso'
                css={css`
                    margin-bottom: 16px;
                `}
            >
                Continue with Google
            </Button>
            <br />
            <Button icon={linkedin_color} variant='sso'>
                Continue with Linkedin
            </Button>
            <br />
            <More opened={opened}>
                {buttonVariants.map(variant => (
                    <Fragment key={variant}>
                        {buttonSizes.map(size => (
                            <MarginBox key={`k$${size}`}>
                                <Button variant={variant} size={size}>
                                    {variant}
                                </Button>{' '}
                                <Button disabled variant={variant} size={size}>
                                    {variant}
                                </Button>{' '}
                                <Button variant={variant} size={size} icon={cloudIcon}>
                                    {variant}
                                </Button>{' '}
                                <Button disabled variant={variant} size={size} icon={cloudIcon}>
                                    {variant}
                                </Button>{' '}
                            </MarginBox>
                        ))}
                    </Fragment>
                ))}
            </More>
        </Fragment>
    )
}

export { Buttons }
