import Slider from 'rc-slider'
import 'rc-slider/assets/index.css'
import { COLOR_PALETTE } from '../../GlobalStyle'

const sliderStyling = {
    trackStyle: [{ backgroundColor: COLOR_PALETTE.black }],
    handleStyle: [
        {
            backgroundColor: COLOR_PALETTE.black,
            borderColor: COLOR_PALETTE.black,
            boxShadow: 'none',
        },
        {
            backgroundColor: COLOR_PALETTE.black,
            borderColor: COLOR_PALETTE.black,
            boxShadow: 'none',
        },
    ],
    railStyle: { backgroundColor: COLOR_PALETTE.grey_light },
}

export { Slider, sliderStyling }
