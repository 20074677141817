/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Fragment, FunctionComponent, useState } from 'react'
import { Link } from 'react-router-dom'
import { DatePicker } from '../../../components/antd/index'

import { AutocompleteSelect } from '../../../components/autocomplete-select/AutocompleteSelect'
import { Checkbox } from '../../../components/Checkbox'
import { Input } from '../../../components/Input'
import { Slider, sliderStyling } from '../../../components/inputs/Slider'
import { PasswordStrength } from '../../../components/password/PasswordStrength'
import { PhoneInput } from '../../../components/PhoneInput'
import { Textarea } from '../../../components/Textarea'

const { RangePicker } = DatePicker

const Inputs: FunctionComponent = () => {
    const [checked, setChecked] = useState(true)
    const [rangeMin, setRangeMin] = useState(3)
    const [rangeMax, setRangeMax] = useState(5)
    const handleRangeChange = ([min, max]: [number, number]) => {
        setRangeMin(min)
        setRangeMax(max)
    }
    return (
        <Fragment>
            <h5>Inputs</h5>
            <Input name='test' label='Label' placeholder='Placeholder' />
            <h6>Phone Input</h6>
            <PhoneInput name='phone' />
            <h6>Input password</h6>
            <Input name='password' label='Password' placeholder='Password' hasPassword={true} type='password' />
            <h6>Password strength</h6>
            <PasswordStrength indicators={{ minMax: true, oneCapital: false, oneDigit: true }} />
            <h6>Input disabled</h6>
            <Input name='disabled' disabled label='Disabled' placeholder='Disabled' />
            <h6>Input error</h6>
            <Input name='error' value='bad text' errorText='Test error message' label='Error' placeholder='Error' />
            <h6>Textarea</h6>
            <Textarea name='test' label='Label' placeholder='Placeholder' />
            <h6>Autocomplete select</h6>
            <AutocompleteSelect
                placeholder='Choose Skills'
                options={['sql', 'mysql', 'mongo']}
                currentValues={[]}
                onSelectedValuesChange={(event: Array<string>) => console.warn(event)}
                canFilter={false}
            />

            <AutocompleteSelect
                placeholder='Choose Skills'
                options={['sql', 'mysql', 'mongo']}
                currentValues={[]}
                onSelectedValuesChange={(event: Array<string>) => console.warn(event)}
            />

            <AutocompleteSelect
                placeholder='Choose Skills'
                options={['sql', 'mysql', 'mongo']}
                currentValues={[]}
                onSelectedValuesChange={(event: Array<string>) => console.warn(event)}
                multiple
            />
            <AutocompleteSelect
                placeholder='Supplemental search/labels'
                options={[
                    { key: '+48', supplement: 'Poland' },
                    { key: '+49', supplement: 'Germany' },
                    {
                        key: '+150',
                        supplement: 'Gabu',
                    },
                ]}
                selectedLabelTransformer={(opt: any) => `${opt.key}`}
                labelTransformer={(opt: any) => `${opt.key} ${opt.supplement}`}
                searchTransformer={(opt: any) => `${opt.key} ${opt.supplement}`}
                currentValues={[]}
                onSelectedValuesChange={(event: Array<string>) => console.log(event)}
            />
            <h6>Checkbox</h6>
            <Checkbox checked={checked} onChange={setChecked} label='Checkbox label' name='test-checkbox'
                      errorText='Test error message' />
            <h6>Slider</h6>
            <strong>
                Range: {rangeMin} - {rangeMax}
            </strong>
            <Slider.Range min={0} max={10} value={[rangeMin, rangeMax]}
                          onChange={handleRangeChange} {...sliderStyling} />
            <h6>Date, date picker, time picker, range picker</h6>
            <DatePicker name='antDate' format='DD-MM-YYYY' />
            <RangePicker name='antRange' format='DD-MM-YYYY' /> <br/>
            <span>for advanced scenarios, check <Link to='/playuground/form'>form layout with validation</Link> </span>
        </Fragment>
    )
}

export { Inputs }
