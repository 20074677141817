import { countArrayFilters } from '../../utils/filters'
import { Filters } from './specialists-search/FiltersBar'

const countSelectedFilters = (filters: Filters): number => {
    let counter = 0
    const { experience, remote } = filters

    counter += countArrayFilters(filters, ['seniorities', 'skills', 'industries', 'languages', 'projects', 'locations'])

    if (experience && (experience.gte || experience.lte)) {
        counter++
    }

    if (remote) {
        counter++
    }

    return counter
}

export { countSelectedFilters }
