/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { FunctionComponent, useEffect, useState } from 'react'
import { Button } from '../Button'
import { Divider } from '../Divider'
import { Input } from '../Input'
import { Modal, ModalProps } from '../Modal'
import { useNotifications } from '../notification/NotificationProvider'
import { ButtonContainer, GreyParagraph } from '../shared/shared-styles'

type ShareProfileModalProps = ModalProps & { shareLinkId: string }

const ShareProfileModal: FunctionComponent<ShareProfileModalProps> = ({ shareLinkId, onClose, opened }) => {
    const { addSuccess } = useNotifications()
    const [shareLink, setShareLink] = useState<string>('')

    useEffect(() => {
        if (shareLinkId) {
            const publicProfileURL = `${window.location.host}/public/profile/${shareLinkId}`

            setShareLink(publicProfileURL)
        }
    }, [shareLinkId])

    const copyToClipboard = (): void => {
        navigator.clipboard.writeText(shareLink)
        addSuccess('Link copied!')

        onClose()
    }

    return (
        <Modal onClose={onClose} opened={opened}>
            <h5>Share the link to the anonymized specialist profile</h5>
            <GreyParagraph>Let others take a look at this specialist profile.</GreyParagraph>
            <GreyParagraph>Shareable Link:</GreyParagraph>
            <Input name='sharableLink' value={shareLink} disabled={true}></Input>
            <Divider />
            <ButtonContainer>
                <Button
                    css={css`
                        margin-right: 28px;
                    `}
                    variant='link'
                    onClick={onClose}
                >
                    Go Back
                </Button>
                <Button onClick={copyToClipboard} variant='primary'>
                    Copy & Close
                </Button>
            </ButtonContainer>
        </Modal>
    )
}

export { ShareProfileModal }
