/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Fragment, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getPublicSpecialistProfile } from '../../../api/api'
import { Profile } from '../../../contracts/profile/profile'
import { Nullable } from '../../../types'
import { SpecialistProfile } from '../shared/SpecialistProfile'

const PublicProfile = () => {
    const [profile, setProfile] = useState<Nullable<Profile>>(null)
    const { linkId } = useParams()

    useEffect(() => {
        if (linkId) {
            getPublicSpecialistProfile(linkId).then((specialistProfile: any) => {
                setProfile(specialistProfile)
            })
        }
    }, [linkId])

    return <Fragment>{profile && <SpecialistProfile specialistProfile={profile} isPublic={true} />}</Fragment>
}

export { PublicProfile }
