/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useEffect, useState, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { getShareProfileLinkId, getSpecialistProfile } from '../../../api/api'
import { DashboardLayout } from '../../../components/layout/dashboard/DashboardLayout'
import { Loader } from '../../../components/layout/Loader'
import { Profile } from '../../../contracts/profile/profile'
import { Nullable } from '../../../types'
import { applicationName, menuItems } from '../../virtual-bench/virtualBenchApp'
import { SpecialistProfile } from '../shared/SpecialistProfile'

const InternalProfile = () => {
    const [loading, setLoading] = useState(true)
    const [profile, setProfile] = useState<Nullable<Profile>>(null)
    const [shareLinkId, setShareLinkId] = useState<string>('')
    const [refetchProfile, setRefetchProfile] = useState(false)
    const { specialistId } = useParams()

    const fetchSpecialistProfile: () => void = useCallback(() => {
        if (specialistId) {
            setLoading(true)

            getSpecialistProfile(specialistId).then((specialistProfile: any) => {
                setProfile(specialistProfile)
            })

            getShareProfileLinkId(specialistId).then(data => {
                setShareLinkId(data.linkId)
            })

            setLoading(false)
        }
    }, [specialistId])

    useEffect(() => {
        fetchSpecialistProfile()
    }, [fetchSpecialistProfile, specialistId])

    useEffect(() => {
        if (refetchProfile) {
            fetchSpecialistProfile()
            setRefetchProfile(false)
        }
    }, [fetchSpecialistProfile, refetchProfile])

    return (
        <DashboardLayout applicationName={applicationName} applicationMenuItems={menuItems}>
            {loading && <Loader />}
            {profile && (
                <SpecialistProfile
                    specialistProfile={profile}
                    shareProfileLinkId={shareLinkId}
                    setRefetchProfile={setRefetchProfile}
                    setLoading={setLoading}
                />
            )}
        </DashboardLayout>
    )
}

export { InternalProfile }
