/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import styled from '@emotion/styled'
import { FunctionComponent } from 'react'
import { COLOR_PALETTE } from '../GlobalStyle'

export type BadgeVariant = 'solid' | 'outlined'

const BadgeBase = styled.span`
    display: inline-block;
    border-width: 1px;
    border-style: solid;
    border-radius: 55px;
    font-size: 12px;
    line-height: 16px;
`

const getBadgeStyles = (variant: BadgeVariant, disabled: boolean) => {
    let styles = css`
        border-color: ${COLOR_PALETTE.grey_light};
        color: ${disabled ? COLOR_PALETTE.grey : COLOR_PALETTE.black};
    `
    if (variant === 'solid') {
        styles = css`
            background-color: ${COLOR_PALETTE.black};
            border-color: ${COLOR_PALETTE.black};
            color: ${COLOR_PALETTE.white};
        `
    }
    return styles
}

type BadgeProps = {
    variant?: BadgeVariant
    disabled?: boolean
}

const Badge: FunctionComponent<BadgeProps> = ({ children, variant = 'outlined', disabled = false }) => {
    return (
        <BadgeBase
            css={css`
                padding: 4px 12px;
                ${getBadgeStyles(variant, disabled)}
            `}
        >
            {children}
        </BadgeBase>
    )
}

export type TalentBadgeVariant = 'primary' | 'secondary' | 'tertiary'

const TalentBadgeBase = styled(BadgeBase)`
    padding: 1px 8px;
    text-transform: capitalize;
    border-color: ${COLOR_PALETTE.green_light};
    color: ${COLOR_PALETTE.green_light};
`

const getTalentBadgeStyles = (variant: TalentBadgeVariant) => {
    let styles
    switch (variant) {
        case 'secondary':
            styles = css`
                opacity: 0.8;
            `
            break
        case 'tertiary':
            styles = css`
                opacity: 0.6;
            `
            break
        default:
            styles = null
    }
    return styles
}

type TalentBadgeProps = {
    variant?: TalentBadgeVariant
    text?: string
}

const TalentBadge: FunctionComponent<TalentBadgeProps> = ({ children, variant = 'primary', text = '' }) => {
    return (
        <TalentBadgeBase css={getTalentBadgeStyles(variant)}>
            {text}
            {children}
        </TalentBadgeBase>
    )
}

export { Badge, TalentBadge }
