/** @jsx jsx */
import { jsx } from '@emotion/core'
import styled from '@emotion/styled'
import { FunctionComponent } from 'react'

const ToggleOnHover = styled.div`
    .showOnHover {
        display: none;
    }
    .hideOnHover {
        display: block;
    }
    &:hover {
        .showOnHover {
            display: block;
        }
        .hideOnHover {
            display: none;
        }
    }
`
const ShowOnHover: FunctionComponent = ({ children }) => <div className='showOnHover'>{children}</div>
const HideOnHover: FunctionComponent = ({ children }) => <div className='hideOnHover'>{children}</div>

export { ToggleOnHover, ShowOnHover, HideOnHover }
