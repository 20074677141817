/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import styled from '@emotion/styled'
import { FunctionComponent, useContext } from 'react'
import { COLOR_PALETTE, mqMin } from '../../../GlobalStyle'
import { ReduxContext } from '../../../redux/Store'
import { ApplicationMenu, ApplicationMenuItemEntry } from './ApplicationMenu'
import { Header, TabType } from './Header'
import { LeftMenu } from './LeftMenu'
import { MainMenu } from './MainMenu'

const Col = styled.div`
    display: flex;

    ${mqMin[2]} {
        overflow: auto;
        height: 100%;
    }
`

enum MenuType {
    myAccount,
    companyAccount,
    leftMenu,
}

type DashboardLayoutProps = {
    menuType?: MenuType
    showMenuItems?: boolean
    applicationName?: string
    applicationMenuItems?: Array<ApplicationMenuItemEntry>
    backgroundColor?: string
}

const DashboardLayout: FunctionComponent<DashboardLayoutProps> = ({
    children,
    menuType,
    applicationName,
    applicationMenuItems,
    showMenuItems = true,
    backgroundColor = COLOR_PALETTE.white,
}) => {
    const {
        selectors: { layoutActiveTab },
    } = useContext(ReduxContext)

    return (
        <section
            css={css`
                min-height: 100vh;
                height: 100%;
                background-color: ${backgroundColor};
                display: flex;
                justify-content: flex-start;
                flex-direction: column;
            `}
        >
            <Header showMenuItems={showMenuItems} applicationName={applicationName}>
                {applicationMenuItems && <ApplicationMenu items={applicationMenuItems} />}
            </Header>

            <section
                css={css`
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                    padding-top: 56px;
                    ${mqMin[2]} {
                        padding-top: 0;
                        flex-direction: row;
                        justify-content: space-between;
                    }
                `}
            >
                <Col
                    css={css`
                        display: ${layoutActiveTab === TabType.leftMenu || menuType !== undefined ? 'block' : 'none'};
                        width: 100%;
                        border-right: 1px solid ${COLOR_PALETTE.grey_light};
                        height: auto;

                        ${mqMin[2]} {
                            width: calc(100vw / 4);
                            max-width: 300px;
                        }
                    `}
                >
                    {layoutActiveTab === TabType.leftMenu && <LeftMenu />}

                    {layoutActiveTab !== TabType.leftMenu && menuType === MenuType.myAccount && (
                        <MainMenu
                            label='My Account'
                            menuItems={[
                                { title: 'Personal Details', path: '/dashboard/my-account/personal-details' },
                                { title: 'Login Settings', path: '/dashboard/my-account/login-settings' },
                            ]}
                        />
                    )}

                    {layoutActiveTab !== TabType.leftMenu && menuType === MenuType.companyAccount && (
                        <MainMenu
                            label='Company Account'
                            menuItems={[
                                {
                                    title: 'Company Details',
                                    path: '/dashboard/company-account/company-details',
                                },
                            ]}
                        />
                    )}
                </Col>
                <div
                    css={css`
                        width: 100%;
                    `}
                >
                    {applicationMenuItems && (
                        <div
                            css={css`
                                display: block;
                                padding: 0 8px;
                                ${mqMin[1]} {
                                    display: none;
                                }
                            `}
                        >
                            <ApplicationMenu items={applicationMenuItems} />
                        </div>
                    )}
                    {children}
                </div>
            </section>
        </section>
    )
}

export { DashboardLayout, MenuType }
