/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { DashboardLayout, MenuType } from '../../../components/layout/dashboard/DashboardLayout'
import { AccountLoginSettings } from '../../account/AccountLoginSettings'

const LoginSettings = () => {
    return (
        <DashboardLayout menuType={MenuType.myAccount}>
            <div
                css={css`
                    display: flex;
                    width: 100%;
                `}
            >
                <AccountLoginSettings />
            </div>
        </DashboardLayout>
    )
}

export { LoginSettings }
