/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Alert } from '../../components/Alert'
import { BackLink } from '../../components/BackLink'
import { Button } from '../../components/Button'
import { Divider } from '../../components/Divider'
import { Hyperlink } from '../../components/Hyperlink'
import { Info } from '../../components/Info'
import { Input } from '../../components/Input'
import { ContentLoader, Loader } from '../../components/layout/Loader'
import { FirstColumn, ResponsiveColumns, SecondColumn } from '../../components/layout/ResponsiveColumns'
import { ResponsiveWrapper } from '../../components/layout/ResponsiveWrapper'
import { Logo, LogoType } from '../../components/Logo'
import { NoData } from '../../components/NoData'
import { CircularProgress } from '../../components/progress/CircularProgress'
import { Tooltip } from '../../components/Tooltip'
import { Debug } from '../../components/utils/Debug'
import { ExampleContent, More } from './components/PresentationHelpers'
import { Badges } from './sections/Badges'
import { Buttons } from './sections/Buttons'
import { Educations } from './sections/Educations'
import { Icons } from './sections/Icons'
import { Inputs } from './sections/Inputs'
import { Modals } from './sections/Modals'
import { Notifications } from './sections/Notifications'
import { Paginations } from './sections/Paginations'
import { SkillMatrixPlayground } from './sections/SkillMatrix'
import { PlaygroundTabs } from './sections/Tabs'
import { Tiles } from './sections/Tiles'
import { Timelines } from './sections/Timelines'

const Playground = () => {
    const history = useHistory()
    const [opened, setOpened] = useState(history.location.search.includes('openAll'))
    const [reloading, setReloading] = useState(false)
    const toggleOpenAll = () => {
        setReloading(true)
        setOpened(!opened)
        setTimeout(() => {
            setReloading(false)
        }, 256)
    }
    if (reloading) {
        return <Loader />
    }
    return (
        <main
            css={css`
                h1,
                h2,
                h3,
                h4,
                h5 {
                    margin-top: 1em;
                }
                h6 {
                    margin-top: 1em;
                }
            `}
        >
            <div
                css={css`
                    padding: 10px;
                `}
            >
                <h2>The list of currently available components</h2>
                <hr />
            </div>
            <ResponsiveWrapper>
                <Button variant='linkForm' onClick={toggleOpenAll}>
                    Toggle All (with Loader)
                </Button>
                <h5>Logo</h5>
                <Logo />
                <More opened={opened}>
                    {[undefined, 'logo', 'logoMono', 'logoInverted', 'logoFull', 'logoFullMono', 'logoFullInverted'].map(type => (
                        <span
                            key={`k${type}`}
                            css={css`
                                display: inline-block;
                                background: ${type?.includes('Inverted') ? 'black' : 'transparent'};
                            `}
                        >
                            <Logo type={type as LogoType} title={type} />
                        </span>
                    ))}
                </More>
                <Icons opened={opened} />
                <Buttons opened={opened} />
                <Inputs />
                <h5>Divider</h5>
                <Divider text='with text' />
                <Divider text='or' />
                <Divider />
                <Badges />
                <h5>Hyperlink</h5>
                <Hyperlink url='http://www.talent-alpha.com'>Saved Teams</Hyperlink>
                <h5>Back Link</h5>
                <BackLink path='/' text='Back to Login' />
                <Modals />
                <Notifications opened={opened} />
                <h5>Alerts</h5>
                <Alert content='Incorrect email address or password.' type='error' />
                <br />
                <Alert content='You have been successfully <i>logged out</i>.' />
                <h5>Info</h5>
                <Info>
                    <h5>Some header</h5>
                    <p>And other regular JSX syntax can be nested here</p>
                    <a href='#yep'>like this</a>
                </Info>
                <h5>Tooltip</h5>
                <span>
                    Hover or tap{' '}
                    <Tooltip content='It will appear very smoothly ... and disappear rapidly'>
                        <Hyperlink url='#tooltip'>
                            <strong>here</strong>
                        </Hyperlink>
                    </Tooltip>{' '}
                    or{' '}
                    <Tooltip content='Here'>
                        <Hyperlink url='#tooltip'>
                            <strong>|</strong>
                        </Hyperlink>
                    </Tooltip>
                    . You can also make it{' '}
                    <Tooltip content='Like that!' opened>
                        <strong>initially opened</strong>
                    </Tooltip>
                </span>
                <h5>Form Grid</h5>
                <Input name='example' label='Not Wrapped' />
                <ResponsiveColumns
                    firstCol={<Input name='fc1' label='First column' />}
                    secondCol={<Input name='sc1' label='Second column' />}
                />
                <ResponsiveColumns>
                    <FirstColumn>
                        <Input name='fc2' label='First name' />
                    </FirstColumn>
                    <SecondColumn>
                        <Input name='sc2' label='Second name' />
                    </SecondColumn>
                </ResponsiveColumns>
                <span>
                    for advanced scenarios, check <Link to='/playground/form'>form layout with validation page</Link>{' '}
                </span>
                <h5>Tabs</h5>
                <PlaygroundTabs />
                <h5>Paginations</h5>
                <ExampleContent>
                    <Paginations />
                </ExampleContent>
                <h5>Loaders, progress</h5>
                <ContentLoader />
                <CircularProgress percent={55} />
                <h5>No Data component</h5>
                <NoData />
                <h5>Data Tables</h5>
                <span>
                    check <Link to='/playground/data-tables'>specific data tales page</Link>{' '}
                </span>
                <h5>Utils</h5>
                <Debug target={{ some: 'field' }} />
            </ResponsiveWrapper>
            <div
                css={css`
                    margin: 0 auto;
                    max-width: 960px;
                `}
            >
                <Tiles />
                <h5>Education components</h5>
                <Educations />
                <h5>Skill Matrix</h5>
                <SkillMatrixPlayground />
                <h5>Timeline</h5>
                <Timelines />
            </div>
        </main>
    )
}

export { Playground }
