/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Fragment, useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { signOut } from '../../api/firebase'
import { ReduxContext } from '../../redux/Store'

const LogoutUser = () => {
    const {
        actions: { userLogout },
        selectors: { user, userId },
    } = useContext(ReduxContext)

    const history = useHistory()

    useEffect(() => {
        signOut().then(userLogout)
    }, [userLogout])

    useEffect(() => {
        if (user !== null && !userId) {
            localStorage.removeItem('accessToken')
            history.push('/logout')
        }
    }, [userId, user, history])

    return <Fragment></Fragment>
}

export { LogoutUser }
