/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Fragment, FunctionComponent, useCallback, useState } from 'react'
import { AppIcon, blackToGrayFilter, Icon, IconButton, IconName } from '../../../components/Icon'
import { ExpandableSectionProps, More } from '../components/PresentationHelpers'

const icons: Array<IconName> = [
    'alert',
    'aplication-tracking',
    'arrow-down',
    'arrow-up',
    'back',
    'bench',
    'bookmark',
    'calendar',
    'check-grey',
    'check-white',
    'check-red',
    'check',
    'close',
    'cloud',
    'cog',
    'contractor',
    'doc',
    'dollar',
    'double-arrow-down',
    'double-arrow-up',
    'download',
    'edit',
    'eye-hide',
    'eye',
    'filter',
    'flag',
    'grid',
    'hamburger',
    'list',
    'lock',
    'mag-glass',
    'mail',
    'more',
    'no-data',
    'notification',
    'plane',
    'profile-details',
    'psychometrics',
    'sales',
    'save',
    'search',
    'share',
    'small-close',
    'syndicate',
    'time',
    'tooltip',
    'trash',
    'user-add',
    'user-remove',
    'user',
    'users',
]

const Icons: FunctionComponent<ExpandableSectionProps> = ({ opened }) => {
    const [pressed, setPressed] = useState(false)
    const handlePressed = useCallback(() => {
        setPressed(!pressed)
    }, [pressed])
    return (
        <Fragment>
            <h5>Icons</h5>
            <Icon name='cloud' title='default' />
            <Icon name='bench' title='size=30' size={30} />
            <Icon name='plane' title='size=60' size={60} />
            <Icon
                name='close'
                css={css`
                    filter: ${blackToGrayFilter};
                `}
                size={50}
            />
            <h6>App Icons</h6>
            <AppIcon name='cloud' title='default' />
            <AppIcon name='bench' status='available' title='available' />
            <AppIcon name='cloud' status='unavailable' title='unavailable' />
            <AppIcon name='cloud' status='disabled' title='disabled' />
            <AppIcon name='cloud' status='unavailable' title='unavailable, size=90, innerSize=45' size={90} innerSize={45} />
            <h6>Icon Buttons</h6>
            <IconButton name='filter' pressed={pressed} onClick={handlePressed} size={32} title='standard, size=32' />
            <IconButton name='flag' pressed={pressed} onClick={handlePressed} size={40} title='standard, size=40' />
            <IconButton name='profile-details' variant='outlined' title='outlined' pressed={pressed} onClick={handlePressed} />
            <More opened={opened}>
                {icons.map(name => (
                    <Icon key={name} name={name} size={32} title={name} />
                ))}
            </More>
        </Fragment>
    )
}

export { Icons }
