/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { COLOR_PALETTE } from './../../GlobalStyle'
import { Button } from './../Button'

const DropdownFooter = ({
    onApply,
    onClearAll,
    applyButtonText = 'Apply',
    clearAllButtonText = 'Clear All',
    buttonsDisabled = false,
}: {
    onApply: () => void
    onClearAll: () => void
    applyButtonText?: string
    clearAllButtonText?: string
    buttonsDisabled?: boolean
}) => (
    <div
        css={css`
            display: flex;
            justify-content: space-between;
            width: 100%;
            padding: 16px 18px;
            align-items: center;
        `}
    >
        <span
            onClick={onClearAll}
            css={css`
                font-weight: 500;
                font-size: 12px;
                line-height: 140%;
                cursor: pointer;
                color: ${COLOR_PALETTE.violet};

                ${buttonsDisabled &&
                css`
                    color: ${COLOR_PALETTE.grey};
                `}
            `}
        >
            {clearAllButtonText}
        </span>
        <Button
            onClick={onApply}
            css={css`
                margin: 0;

                ${buttonsDisabled &&
                `
                        background-color: ${COLOR_PALETTE.grey_light} !important;
                        border-color: ${COLOR_PALETTE.grey_light};
                        color: ${COLOR_PALETTE.grey} !important;
                    `}
            `}
        >
            {applyButtonText}
        </Button>
    </div>
)

export { DropdownFooter }
