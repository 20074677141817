/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import styled from '@emotion/styled'
import { FunctionComponent } from 'react'
import { COLOR_PALETTE, mqMin } from '../../GlobalStyle'
import { FlexBox } from '../layout/FlexBoxHelpers'
import { ColumnProps } from '../layout/ResponsiveColumns'

export type FiltersBarWrapperProps = {
    filtersOpened: boolean
}

const FiltersBarWrapper: FunctionComponent<FiltersBarWrapperProps> = ({ children, filtersOpened }) => {
    return (
        <div
            css={css`
                margin-bottom: 8px;
                display: ${filtersOpened ? 'block' : 'none'};
            `}
        >
            {children}
        </div>
    )
}

type FiltersClearAllProps = {
    onClear: () => void
    quantity: number
}

const FiltersClearTrigger: FunctionComponent<FiltersClearAllProps> = ({ onClear, quantity }) => {
    return (
        <FlexBox alignItems='center' justifyContent='flex-end'>
            <span
                css={css`
                    cursor: pointer;
                    position: relative;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 16px;
                    color: ${COLOR_PALETTE.violet};
                `}
                onClick={onClear}
            >
                Clear All
                {quantity !== 0 && <span> ({quantity})</span>}
            </span>
        </FlexBox>
    )
}

const FiltersContainer = styled.div`
    margin: 0 -6px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
`

const FilterColumn = styled.div<ColumnProps>`
    display: block;
    padding: 0 6px;
    width: 100%;
    margin-bottom: 16px;
    ${mqMin[1]} {
        width: ${props => props.width || 20}%;
    }
`

export { FiltersBarWrapper, FiltersContainer, FilterColumn, FiltersClearTrigger }
