/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { FunctionComponent, useEffect, useMemo, useState } from 'react'
import { AutocompleteSelect } from '../../../components/autocomplete-select/AutocompleteSelect'
import { Checkbox } from '../../../components/Checkbox'
import {
    FilterColumn,
    FiltersBarWrapper,
    FiltersClearTrigger,
    FiltersContainer,
} from '../../../components/filters/FiltersBarHelpers'
import { RangeSelection } from '../../../components/filters/RangeSelection'
import { FirstColumn, ResponsiveColumns, SecondColumn } from '../../../components/layout/ResponsiveColumns'
import { SearchLocation } from '../../../contracts/common/searchLocation'
import { FiltersResponse } from '../../../contracts/search/specialistsFilters'
import { SpecialistsSearchRequest } from '../../../contracts/search/specialistsSearch'
import { mqMin } from '../../../GlobalStyle'
import { toMonths, toYears } from '../../../utils/searchFilters'
import { countSelectedFilters } from '../virtualBenchUtils'

export interface Filters {
    seniorities?: Array<string>
    skills?: Array<string>
    industries?: Array<string>
    languages?: Array<string>
    projects?: Array<string>
    experience?: {
        gte: number
        lte: number
    }
    locations?: Array<SearchLocation>
    remote?: boolean
}

const initialFilters: SpecialistsSearchRequest = {
    seniorities: [],
    skills: [],
    industries: [],
    languages: [],
    projects: [],
    locations: [],
    remote: false,
}

type FiltersBarProps = {
    onChangeFilters: (filters: SpecialistsSearchRequest) => void
    onClearFilters: () => void
    selectedFilters: SpecialistsSearchRequest
    possibleFiltersValues: FiltersResponse
    filtersOpened: boolean
}

const FiltersBar: FunctionComponent<FiltersBarProps> = ({
    onChangeFilters,
    selectedFilters,
    onClearFilters,
    possibleFiltersValues,
    filtersOpened,
}) => {
    const [filters, setFilters] = useState<Filters>(selectedFilters)

    const [seniorities, setSeniorities] = useState<Array<string> | undefined>([])
    const [skills, setSkills] = useState<Array<string>>([])
    const [industries, setIndustries] = useState<Array<string>>([])
    const [languages, setLanguages] = useState<Array<string>>([])
    const [projects, setProjects] = useState<Array<string>>([])
    const [minPossibleExperience] = useState(0)
    const [maxPossibleExperience, setMaxPossibleExperience] = useState(40)

    const countries = useMemo(() => {
        const locations = possibleFiltersValues.locations
        return locations ? locations.map(location => location.country) : []
    }, [possibleFiltersValues.locations])

    const cities = useMemo(() => {
        const possible = possibleFiltersValues.locations
        const country = filters.locations && filters.locations.length ? filters.locations[0].country : null
        if (country && possible) {
            const foundLocation = possible.find(location => location.country === country)
            if (foundLocation) {
                return foundLocation.cities
            }
        }
        return []
    }, [possibleFiltersValues.locations, filters.locations])

    const setSortedValues = (values: Array<string> | undefined, setter: (vals: Array<string>) => void) => {
        if (values) {
            const sortedValues = [...values]
            sortedValues.sort()
            setter(sortedValues)
        }
    }

    useEffect(() => {
        setSeniorities(possibleFiltersValues.seniorities)
        setSortedValues(possibleFiltersValues.skills, setSkills)
        setSortedValues(possibleFiltersValues.industries, setIndustries)
        setSortedValues(possibleFiltersValues.languages, setLanguages)
        setSortedValues(possibleFiltersValues.projects, setProjects)
        if (possibleFiltersValues.maxExperience) {
            setMaxPossibleExperience(Math.ceil(possibleFiltersValues.maxExperience))
        }
    }, [possibleFiltersValues])

    const onSelectFilterChange = (values: Array<string>, filterName: any) => {
        // TODO: type
        const currentFilters: any = { ...filters } // TODO: type
        if (currentFilters[filterName]) {
            currentFilters[filterName] = values
        } else if (filterName.startsWith('locations')) {
            // temporary solution with one location
            if (filterName === 'locations.country') {
                currentFilters.locations = [{ country: values[0], cities: [] }]
            } else if (filterName === 'locations.country.cities') {
                const country = currentFilters.locations[0].country
                currentFilters.locations = [{ country, cities: values }]
            }
        }

        setFilters(currentFilters)
        onChangeFilters(currentFilters)
    }

    const onRangeFilterChange = (gte: number, lte: number) => {
        const experience = { gte, lte }
        const currentFilters = { ...filters }
        currentFilters.experience = experience

        setFilters(currentFilters)
        onChangeFilters(currentFilters)
    }

    const onRemoteFilterChange = () => {
        const currentFilters = { ...filters }
        currentFilters.remote = !currentFilters.remote

        setFilters(currentFilters)
        onChangeFilters(currentFilters)
    }

    const onClearAll = () => {
        setFilters(initialFilters)
        onClearFilters()
    }

    return (
        <FiltersBarWrapper filtersOpened={filtersOpened}>
            <ResponsiveColumns>
                <FirstColumn width={55} padding={6}>
                    <FiltersContainer>
                        <FilterColumn>
                            <AutocompleteSelect
                                multiple
                                placeholder='Seniority'
                                options={seniorities}
                                filterName='seniorities'
                                currentValues={filters.seniorities}
                                onSelectedValuesChange={onSelectFilterChange}
                            />
                        </FilterColumn>
                        <FilterColumn>
                            <AutocompleteSelect
                                multiple
                                placeholder='Skills'
                                options={skills}
                                filterName='skills'
                                currentValues={filters.skills}
                                virtualized
                                dropdownWidth={280}
                                onSelectedValuesChange={onSelectFilterChange}
                            />
                        </FilterColumn>
                        <FilterColumn>
                            <AutocompleteSelect
                                multiple
                                placeholder='Industry'
                                options={industries}
                                filterName='industries'
                                currentValues={filters.industries}
                                onSelectedValuesChange={onSelectFilterChange}
                            />
                        </FilterColumn>
                        <FilterColumn>
                            <AutocompleteSelect
                                multiple
                                placeholder='Language'
                                options={languages}
                                filterName='languages'
                                currentValues={filters.languages}
                                onSelectedValuesChange={onSelectFilterChange}
                            />
                        </FilterColumn>
                        <FilterColumn>
                            <RangeSelection
                                placeholder='Experience'
                                label='Experience (In Years)'
                                min={toYears(minPossibleExperience)}
                                max={toYears(maxPossibleExperience)}
                                valueTransformer={toMonths}
                                onRangeChange={onRangeFilterChange}
                                selectedMin={filters.experience ? toYears(filters.experience.gte) : null}
                                selectedMax={filters.experience ? toYears(filters.experience.lte) : null}
                            />
                        </FilterColumn>
                    </FiltersContainer>
                </FirstColumn>
                <SecondColumn width={45} padding={6}>
                    <FiltersContainer>
                        <FilterColumn>
                            <AutocompleteSelect
                                multiple
                                placeholder='Projects'
                                options={projects}
                                filterName='projects'
                                currentValues={filters.projects}
                                onSelectedValuesChange={onSelectFilterChange}
                            />
                        </FilterColumn>
                        <FilterColumn width={60}>
                            <div
                                css={css`
                                    display: flex;
                                    align-items: center;
                                    justify-content: flex-start;
                                    flex-wrap: wrap;
                                    ${mqMin[1]} {
                                        flex-wrap: nowrap;
                                    }
                                `}
                            >
                                <AutocompleteSelect
                                    placeholder='Country'
                                    options={countries}
                                    filterName='locations.country'
                                    currentValues={
                                        filters && filters.locations && filters.locations.length ? [filters.locations[0].country] : []
                                    }
                                    onSelectedValuesChange={onSelectFilterChange}
                                />
                                <AutocompleteSelect
                                    multiple
                                    placeholder='City'
                                    options={cities}
                                    filterName='locations.country.cities'
                                    currentValues={
                                        filters && filters.locations && filters.locations.length ? filters.locations[0].cities : []
                                    }
                                    onSelectedValuesChange={onSelectFilterChange}
                                    css={css`
                                        margin: 0 12px 0 0;
                                    `}
                                />
                                <div>
                                    <Checkbox onChange={onRemoteFilterChange} checked={!!filters.remote} label='Remote' />
                                </div>
                            </div>
                        </FilterColumn>
                        <FilterColumn>
                            <FiltersClearTrigger onClear={onClearAll} quantity={countSelectedFilters(filters)} />
                        </FilterColumn>
                    </FiltersContainer>
                </SecondColumn>
            </ResponsiveColumns>
        </FiltersBarWrapper>
    )
}

export { FiltersBar, initialFilters }
