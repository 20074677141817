/** @jsx jsx */
import { css, jsx, SerializedStyles } from '@emotion/core'
import { COLOR_PALETTE } from '../GlobalStyle'
import { Icon } from './Icon'

enum CheckItemColors {
    GREY,
    BLACK,
    RED,
}

const CheckItem = ({
    children,
    color = CheckItemColors.GREY,
    style,
}: {
    children: any
    color?: CheckItemColors
    style?: SerializedStyles
}) => {
    const getColorStyle = () => {
        switch (color) {
            case CheckItemColors.BLACK:
                return `color: ${COLOR_PALETTE.black};`
            case CheckItemColors.GREY:
                return `color: ${COLOR_PALETTE.grey};`
            case CheckItemColors.RED:
                return `color: ${COLOR_PALETTE.red};`
        }
    }

    return (
        <span
            css={css`
                display: flex;
                align-items: center;
                ${getColorStyle()}

                ${style}
            `}
        >
            {color === CheckItemColors.BLACK && (
                <Icon
                    name='check'
                    style={css`
                        margin-right: 6px;
                    `}
                />
            )}
            {color === CheckItemColors.GREY && (
                <Icon
                    name='check-grey'
                    style={css`
                        margin-right: 6px;
                    `}
                />
            )}
            {color === CheckItemColors.RED && (
                <Icon
                    name='check-red'
                    style={css`
                        margin-right: 6px;
                    `}
                />
            )}
            {children}
        </span>
    )
}

export { CheckItem, CheckItemColors }
