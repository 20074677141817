/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import styled from '@emotion/styled'
import { Fragment, FunctionComponent } from 'react'
import { SpecialistsSearchResponse } from '../../contracts/search/specialistsSearch'
import { COLOR_PALETTE } from '../../GlobalStyle'

const SearchResultsInfoParagraph = styled.p`
    font-weight: 500;
    font-size: 14px;
    line-height: 130%;
    margin: 0 0 4px;
    color: ${COLOR_PALETTE.black};
    width: 100%;
`

const SearchResultsInfoSubText = styled.p`
    font-size: 12px;
    line-height: 120%;
    width: 100%;
    color: ${COLOR_PALETTE.grey_dark};
`

export interface SearchResultsInfoProps {
    searchResults: SpecialistsSearchResponse
    filtersCount: number
    favorites: boolean
    searchText?: string
}

const SearchResultsInfo: FunctionComponent<SearchResultsInfoProps> = ({ searchResults, filtersCount, searchText = '', favorites }) => {
    const isQueryNotMatchInformationVisible = () => {
        return searchResults && searchResults.matchingCount === 0
    }

    const isTotalNumberVisible = () => {
        return searchResults && searchResults.total && searchResults.matchingCount !== 0
    }

    const renderSearchResultsInfo = () => {
        if (isQueryNotMatchInformationVisible()) {
            return (
                <Fragment>
                    <SearchResultsInfoParagraph>
                        Your search <strong>"{searchText}"</strong>
                        {filtersCount > 0 && ' and the above selected filters'} did not match one of the Specialist Profiles{' '}
                        {favorites ? 'in your Bookmarked Talent' : 'currently on our Human Cloud'}.
                    </SearchResultsInfoParagraph>
                    {!favorites && (
                        <SearchResultsInfoSubText>
                            Please take a look at some examples of Specialist Profiles below and refine your search according to specific
                            skills, roles, industries etc.
                        </SearchResultsInfoSubText>
                    )}
                </Fragment>
            )
        }

        if (isTotalNumberVisible()) {
            return (
                <SearchResultsInfoParagraph>
                    {searchResults.total} Result{searchResults.total > 1 && 's'} Found
                </SearchResultsInfoParagraph>
            )
        }

        return null
    }

    if (searchResults) {
        return (
            <div
                css={css`
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    margin-bottom: 16px;
                `}
            >
                {renderSearchResultsInfo()}
            </div>
        )
    }

    return null
}

export { SearchResultsInfo }
