/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import styled from '@emotion/styled'
import { Fragment, FunctionComponent, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { useCountries } from '../../redux/permanentDataHooks'
import { resolveAntValidationProps, resolveFieldError } from '../../utils/errors'
import { phoneRegex } from '../../utils/regexes'
import { DatePicker, Form as AntForm, Radio } from '../antd/index'
import { Button } from '../Button'
import { Checkbox } from '../Checkbox'
import { Divider } from '../Divider'
import { Input, InputLabel } from '../Input'
import { FlexBox } from '../layout/FlexBoxHelpers'
import { FieldWrapper } from '../layout/FormHelpers'
import { FirstColumn, ResponsiveColumns, SecondColumn } from '../layout/ResponsiveColumns'
import { PhoneInput } from '../PhoneInput'
import { ButtonContainer, GreyParagraph } from '../shared/shared-styles'
import { Textarea } from '../Textarea'
import { ContactFormProps } from './ContactSalesModal'

const RadioContainer = styled.div`
    flex: 3;
    margin-bottom: 16px;
`

const UnknownScopeContainer = styled.div`
    margin-bottom: 5px;
`

const requiredText = 'This field is required'

const schema = yup.object().shape({
    projectDuration: yup.mixed().when(['unknownScope'], {
        is: false,
        then: yup.number().typeError(requiredText).required(requiredText),
    }),
    idealProjectStartDate: yup.string().when(['unknownScope'], { is: false, then: yup.string().required(requiredText) }),
    latestProjectStartDate: yup.string().when(['unknownScope'], { is: false, then: yup.string().required(requiredText) }),
    firstName: yup.string().required(requiredText),
    lastName: yup.string().required(requiredText),
    phone: yup.string().matches(phoneRegex, 'This is not a valid phone number').required(requiredText),
    preferredContactTime: yup.string(),
    additionalInfo: yup.string(),
})

const FirstContactModal: FunctionComponent<ContactFormProps> = ({ onClose, userData, sendForm }) => {
    const countries = useCountries()
    const [unknownScope] = useState<boolean>(false)
    const { register, handleSubmit, errors, formState, reset, control, watch, getValues } = useForm({
        validationSchema: schema,
    })

    const watchUnknownScope = watch('unknownScope')

    const onSubmit = (formData: any) => {
        sendForm(formData)
        reset()
    }

    useEffect(() => {
        reset({ ...userData, unknownScope: false })
    }, [userData, reset])

    return (
        <Fragment>
            <h5>Book this Specialist Now!</h5>
            <GreyParagraph>
                Please fill in all the info about your project and one of our Talent Experts will get back to you as soon as we can confirm
                the availability of the Specialist. If you are interested in booking more than one Specialist for your project, please write
                the estimated number of Specialists required and one of our Talent Experts will contact you to set up a scoping call for
                your project.
            </GreyParagraph>

            <form
                onSubmit={handleSubmit(onSubmit)}
                css={css`
                    padding-bottom: 24px;
                `}
            >
                <InputLabel>What is the expected project duration?</InputLabel>
                <FlexBox alignItems='center'>
                    <div>
                        <FieldWrapper noLabel>
                            <Input
                                name='projectDuration'
                                placeholder='Number'
                                type='number'
                                innerRef={register}
                                errorText={resolveFieldError(errors.projectDuration)}
                                disabled={watchUnknownScope}
                                style={css`
                                    margin-right: 12px;
                                    max-width: 105px;
                                `}
                            />
                        </FieldWrapper>
                    </div>
                    <RadioContainer>
                        <Controller
                            as={
                                <Radio.Group value={getValues('projectDurationUnit')}>
                                    <Radio value='MONTHS' disabled={watchUnknownScope}>
                                        Months
                                    </Radio>
                                    <Radio value='WEEKS' disabled={watchUnknownScope}>
                                        Weeks
                                    </Radio>
                                    <Radio value='DAYS' disabled={watchUnknownScope}>
                                        Days
                                    </Radio>
                                </Radio.Group>
                            }
                            control={control}
                            onChange={(e: any[]) => e[0].target.value}
                            onChangeName='onChange'
                            name='projectDurationUnit'
                        />
                    </RadioContainer>
                </FlexBox>

                <FieldWrapper>
                    <AntForm.Item
                        style={{ width: '210px' }}
                        label='What is the ideal project start date?'
                        colon={false}
                        {...resolveAntValidationProps(errors.idealProjectStartDate)}
                    >
                        <Controller
                            control={control}
                            name='idealProjectStartDate'
                            onChange={([dateObject]) => dateObject}
                            as={
                                <DatePicker
                                    name='idealProjectStartDate'
                                    placeholder='Add Date'
                                    format='DD.MM.YYYY'
                                    style={{ width: '210px' }}
                                    disabled={watchUnknownScope}
                                />
                            }
                        />
                    </AntForm.Item>
                </FieldWrapper>

                <FieldWrapper>
                    <AntForm.Item
                        style={{ width: '210px' }}
                        label='What is the latest project start date?'
                        colon={false}
                        {...resolveAntValidationProps(errors.latestProjectStartDate)}
                    >
                        <Controller
                            control={control}
                            name='latestProjectStartDate'
                            onChange={([dateObject]) => dateObject}
                            as={
                                <DatePicker
                                    name='latestProjectStartDate'
                                    placeholder='Add Date'
                                    format='DD.MM.YYYY'
                                    style={{ width: '210px' }}
                                    disabled={watchUnknownScope}
                                />
                            }
                        />
                    </AntForm.Item>
                </FieldWrapper>

                <UnknownScopeContainer>
                    <Controller
                        as={<Checkbox label='Not sure yet, I need your help in scoping the Project' checked={unknownScope} />}
                        control={control}
                        onChange={v => v[0]}
                        onChangeName='onChange'
                        name='unknownScope'
                    />
                </UnknownScopeContainer>

                <Divider />

                <ResponsiveColumns>
                    <FirstColumn>
                        <FieldWrapper>
                            <Input
                                name='firstName'
                                placeholder='First Name'
                                label='First Name'
                                innerRef={register}
                                errorText={resolveFieldError(errors.firstName)}
                            />
                        </FieldWrapper>
                    </FirstColumn>
                    <SecondColumn>
                        <FieldWrapper>
                            <Input
                                name='lastName'
                                placeholder='Last Name'
                                label='Last Name'
                                innerRef={register}
                                errorText={resolveFieldError(errors.lastName)}
                            />
                        </FieldWrapper>
                    </SecondColumn>
                </ResponsiveColumns>
                <FieldWrapper>
                    <PhoneInput
                        countryAreaCodes={countries ? countries : []}
                        name='phone'
                        label='Phone'
                        placeholder='Phone number'
                        type='text'
                        innerRef={register}
                        errorText={resolveFieldError(errors.phone)}
                        defaultValue={userData?.phone}
                    />
                </FieldWrapper>

                <p>
                    Pro Tip: Make sure to update your personal details in your settings to avoid filling them in every time you make a
                    booking.
                </p>

                <Divider />
                <FieldWrapper>
                    <Input
                        label='Preferred time & date to speak with one of our Talent Experts (optional)'
                        name='preferredContactTime'
                        placeholder='Monday, March 23rd, 11 AM (EST)'
                        innerRef={register}
                        errorText={resolveFieldError(errors.preferredContactTime)}
                    />
                </FieldWrapper>

                <Textarea
                    label='Additional Information (optional)'
                    name='additionalInfo'
                    innerRef={register}
                    placeholder='I need a Team of Java Developers...'
                />

                <Divider />

                <ButtonContainer>
                    <Button
                        css={css`
                            margin-right: 28px;
                        `}
                        variant='link'
                        onClick={onClose}
                    >
                        Cancel
                    </Button>
                    <Button type='submit' variant='primary' disabled={formState.isSubmitting || !formState.dirty}>
                        Send
                    </Button>
                </ButtonContainer>
            </form>
        </Fragment>
    )
}

export { FirstContactModal }
