/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import styled from '@emotion/styled'
import { FunctionComponent } from 'react'
import { FlexBox } from '../../../components/layout/FlexBoxHelpers'
import { COLOR_PALETTE } from '../../../GlobalStyle'

const GreyTitle = styled.div`
    font-size: 14px;
    color: ${COLOR_PALETTE.grey_dark};
    width: 50%;
    max-width: 144px;
`
const BlackTitle = styled.div`
    font-size: 14px;
    color: ${COLOR_PALETTE.black};
`
const GeneralInfoDivider = styled.div`
    width: 100%;
    border-bottom: 1px solid #eeeeee;
    margin: 9px 0;
`

const GeneralInformation: FunctionComponent = () => {
    return (
        <div
            css={css`
                margin-bottom: 62px;
            `}
        >
            <h6>General information</h6>
            <FlexBox>
                <GreyTitle>Experience</GreyTitle>
                <BlackTitle>+4 years</BlackTitle>
            </FlexBox>
            <GeneralInfoDivider />
            <FlexBox>
                <GreyTitle>Recent Roles</GreyTitle>
                <BlackTitle>Senior Java Developer</BlackTitle>
            </FlexBox>
            <GeneralInfoDivider />
            <FlexBox>
                <GreyTitle>Recent Industries</GreyTitle>
                <BlackTitle>Agriculture</BlackTitle>
            </FlexBox>
            <GeneralInfoDivider />
            <FlexBox>
                <GreyTitle>Recent Industries</GreyTitle>
                <BlackTitle>Saas for managing crip distribution</BlackTitle>
            </FlexBox>
        </div>
    )
}

export { GeneralInformation }
