/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import axios, { AxiosResponse } from 'axios'
import { useRef, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { Link, useHistory } from 'react-router-dom'
import { signDocuments } from '../../../api/api'
import { createUserWithEmailAndPassword, sendVerificationEmail } from '../../../api/firebase'
import { AlternativeAuthMethods } from '../../../components/AlternativeAuthMethods'
import { Button } from '../../../components/Button'
import { Checkbox } from '../../../components/Checkbox'
import { Input } from '../../../components/Input'
import { FieldWrapper } from '../../../components/layout/FormHelpers'
import { Logo } from '../../../components/Logo'
import { PasswordStrength } from '../../../components/password/PasswordStrength'
import { COLOR_PALETTE } from '../../../GlobalStyle'
import { emailRegex } from '../../../utils/regexes'
import { DocumentType } from './../../../contracts/documentsResponse'
import config from './../../../environment/dev.config.json'

const RegisterForm = () => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [termsAccepted, setTermsAccepted] = useState(false)
    const [newsletterAccepted, setNewsletterAccepted] = useState(false)

    const [emailErrorText, setEmailErrorText] = useState('')
    const [passwordErrorText, setPasswordErrorText] = useState('')
    const [passwordLengthValid, setPasswordLengthValid] = useState<boolean | null>(null)
    const [passwordDigitValid, setPasswordDigitValid] = useState<boolean | null>(null)
    const [passwordCapitalLetterValid, setPasswordCapitalLetterValid] = useState<boolean | null>(null)
    const [termsAcceptedError, setTermsAcceptedError] = useState('')

    const history = useHistory()
    const recaptchaRef = useRef(null)

    const onSubmit = () => {
        if (validateForm()) {
            ;(recaptchaRef.current as any).reset()
            ;(recaptchaRef.current as any).execute()
        }
    }

    const handleCaptchaChange = async (token: string | null) => {
        if (token) {
            const captchaValid = await validateCaptcha(token)
            if (captchaValid) {
                handleSubmit()
            }
        }
    }

    const handleSubmit = async (): Promise<void> => {
        const docs: Array<DocumentType> = ['PRIVACY_POLICY', 'TERMS_OF_USE']

        if (newsletterAccepted) {
            docs.push('MARKETING_CONSENTS')
        }

        signDocuments(docs, email).then(() => {
            createUserWithEmailAndPassword(email, password).then(response => {
                if (response) {
                    sendVerificationEmail().then(() => {
                        history.push(`/register/confirmation`)
                    })
                }
            })
        })
    }

    const validateCaptcha = (token: string): Promise<boolean> => {
        return axios.get(`${config.verifyCaptchaEndpoint}?token=${token}`).then((response: AxiosResponse<{ success: boolean }>) => {
            return response.data.success
        })
    }

    const validateForm = (): boolean => {
        let isValid = true

        if (!validateEmail()) {
            isValid = false
        }

        if (!validatePassword()) {
            isValid = false
        }

        if (!validateTermsAccepted()) {
            isValid = false
        }

        return isValid
    }

    const validateEmail = (): boolean => {
        let isValid = true

        if (email.length === 0) {
            setEmailErrorText('You must enter your email')
            isValid = false
        } else if (!emailRegex.test(email)) {
            setEmailErrorText('This email is invalid')
            isValid = false
        }

        return isValid
    }

    const validatePassword = (): boolean => {
        let isValid = true

        if (password.length < 8 || password.length > 24) {
            setPasswordLengthValid(false)
            isValid = false
        }

        if (!/\d/.test(password)) {
            setPasswordDigitValid(false)
            isValid = false
        }

        if (!/[A-Z]/.test(password)) {
            setPasswordCapitalLetterValid(false)
            isValid = false
        }

        if (!isValid) {
            setPasswordErrorText(' ')
        }

        return isValid
    }

    const validateTermsAccepted = (): boolean => {
        if (!termsAccepted) {
            setTermsAcceptedError(' ')
            return false
        }

        return true
    }

    const handlePasswordChange = (value: string): void => {
        setPassword(value)

        if (passwordErrorText) {
            setPasswordErrorText('')
        }

        if (value.length >= 8 && value.length <= 24) {
            setPasswordLengthValid(true)
        } else {
            setPasswordLengthValid(null)
        }

        if (/\d/.test(value)) {
            setPasswordDigitValid(true)
        } else {
            setPasswordDigitValid(null)
        }

        if (/[A-Z]/.test(value)) {
            setPasswordCapitalLetterValid(true)
        } else {
            setPasswordCapitalLetterValid(null)
        }
    }

    const handleEmailChange = (value: string): void => {
        setEmail(value)

        if (emailErrorText) {
            setEmailErrorText('')
        }
    }

    const handleTermsChange = (value: boolean): void => {
        setTermsAccepted(value)

        if (termsAcceptedError) {
            setTermsAcceptedError('')
        }
    }

    return (
        <section
            css={css`
                display: flex;
                justify-content: center;
                padding: 16px;
            `}
        >
            <ReCAPTCHA
                sitekey={config.captchaSitekey}
                size='invisible'
                ref={recaptchaRef}
                badge='bottomright'
                onChange={handleCaptchaChange}
            />
            <div
                css={css`
                    width: 408px;
                    padding-top: 28px;
                `}
            >
                <Link to='/'>
                    <Logo
                        type='logoFull'
                        style={css`
                            margin-left: 0;
                            margin-bottom: 36px;
                        `}
                    />
                </Link>

                <h3>Join Us</h3>
                <form
                    css={css`
                        display: flex;
                        flex-direction: column;
                    `}
                >
                    <FieldWrapper>
                        <Input name='email' placeholder='Email' label='Email' onChange={handleEmailChange} errorText={emailErrorText} />
                    </FieldWrapper>
                    <FieldWrapper>
                        <Input
                            name='password'
                            placeholder='Password'
                            label='Password'
                            type='password'
                            hasPassword={true}
                            errorText={passwordErrorText}
                            onChange={handlePasswordChange}
                        />
                    </FieldWrapper>
                    <PasswordStrength
                        indicators={{
                            minMax: passwordLengthValid,
                            oneCapital: passwordCapitalLetterValid,
                            oneDigit: passwordDigitValid,
                        }}
                    />
                    <small
                        css={css`
                            margin-top: 8px;
                            color: ${COLOR_PALETTE.grey_dark};
                        `}
                    >
                        This site is protected by reCAPTCHA and the Google
                    </small>
                    <small
                        css={css`
                            color: ${COLOR_PALETTE.grey_dark};
                            margin-bottom: 22px;
                        `}
                    >
                        <Link to='/documents/privacy-policy' target='_blank'>
                            Privacy Policy
                        </Link>{' '}
                        and{' '}
                        <Link to='/documents/terms-of-use' target='_blank'>
                            Terms of Service
                        </Link>{' '}
                        apply
                    </small>

                    <Checkbox
                        name='terms'
                        style={css`
                            margin-bottom: 12px;
                        `}
                        label='*I agree with Terms of Use'
                        checked={termsAccepted}
                        onChange={handleTermsChange}
                        errorText={termsAcceptedError}
                    />
                    <Checkbox
                        name='newsletter'
                        style={css`
                            margin-bottom: 22px;
                        `}
                        label='I want to sign up for the newsletter'
                        checked={newsletterAccepted}
                        onChange={setNewsletterAccepted}
                    />

                    <Button
                        variant='primary'
                        onClick={onSubmit}
                        style={css`
                            margin-bottom: 16px;
                        `}
                    >
                        Join Us
                    </Button>
                    <AlternativeAuthMethods />
                </form>

                <p
                    css={css`
                        width: 100%;
                        text-align: center;
                        color: ${COLOR_PALETTE.grey_dark};
                    `}
                >
                    Already have an account? <Link to='/'>Log In</Link>
                </p>
            </div>
        </section>
    )
}

export { RegisterForm }
