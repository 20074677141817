/** @jsx jsx */
import { css, jsx, SerializedStyles } from '@emotion/core'
import { FunctionComponent, useCallback } from 'react'
import { COLOR_PALETTE } from '../GlobalStyle'
import { Icon } from './Icon'

export type ModalProps = {
    opened?: boolean
    onClose: () => void
    maskClosable?: boolean
    style?: SerializedStyles
}

const Modal: FunctionComponent<ModalProps> = ({ opened = false, onClose, maskClosable = true, style, children }) => {
    const onOutsideClick = useCallback((): void => {
        if (maskClosable) {
            onClose()
        }
    }, [maskClosable, onClose])

    const onInsideClick = useCallback((event: React.MouseEvent<HTMLDivElement>): void => {
        event.stopPropagation()
    }, [])

    return (
        <div
            onClick={onOutsideClick}
            css={css`
                position: fixed;
                top: 0;
                left: 0;
                background-color: rgba(0, 0, 0, 0.5);
                z-index: 10;
                width: 100%;
                height: 100%;
                display: ${opened ? 'block' : 'none'};
            `}
        >
            <div
                css={css`
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                `}
            >
                <div
                    onClick={onInsideClick}
                    css={css`
                        position: relative;
                        padding: 32px 40px;
                        max-width: 1024px;
                        background-color: ${COLOR_PALETTE.white};
                        max-height: 90%;
                        overflow-y: auto;

                        ${style}
                    `}
                >
                    <Icon
                        name='close-grey'
                        onClick={() => onClose()}
                        css={css`
                            color: ${COLOR_PALETTE.grey};
                            position: absolute;
                            top: 8px;
                            right: 8px;
                            height: 30px;
                            width: 30px;
                            cursor: pointer;
                        `}
                    />
                    {children}
                </div>
            </div>
        </div>
    )
}

export { Modal }
