/** @jsx jsx */
import { css, jsx } from '@emotion/core'

const off = process.env.NODE_ENV === 'production'

const Debug = (props: any) => {
    const target = props.target || props.children
    return off ? null : (
        <pre
            css={css`
                background: lightgray;
            `}
        >
            {JSON.stringify(target, null, 2)}
        </pre>
    )
}

export { Debug }
