
export interface Specialist {
    availability: string
    experienceInMonths: number
    favorite: any
    industries: Array<string>
    languages: Array<string>
    location: string
    nickname: string
    projects: Array<string>
    recentRoles: Array<string>
    rolesInfo: Array<SpecialistRole>
    specialistId: string
    tribe: string
}


export enum RoleType {
    PRIMARY = 'PRIMARY',
    SECONDARY = 'SECONDARY',
}

export enum RoleSeniority {
    JUNIOR = 'JUNIOR',
    REGULAR = 'REGULAR',
    SENIOR = 'SENIOR',
}

export interface SpecialistRole {
    role: string
    roleSeniority: RoleSeniority
    roleType: RoleType
    topSkillsForRole: Array<string>
}
