/** @jsx jsx */
import { css, jsx, SerializedStyles } from '@emotion/core'
import { COLOR_PALETTE } from '../GlobalStyle'

const Hyperlink = ({ url, disabled, style, children }: { url: string; disabled?: boolean; style?: SerializedStyles; children: any }) => {
    const handleClick = (event: any) => {
        if (disabled) {
            event.preventDefault()
        }
    }

    return (
        <a
            css={css`
                text-decoration: none;
                color: ${COLOR_PALETTE.black};
                ${disabled
                    ? css`
                          color: ${COLOR_PALETTE.grey};
                          text-decoration: none !important;
                          cursor: not-allowed;
                      `
                    : ''}
                &:hover {
                    text-decoration: underline;
                    text-decoration-color: ${COLOR_PALETTE.yellow};
                }
                ${style}
            `}
            onClick={handleClick}
            href={url}
        >
            {children}
        </a>
    )
}

export { Hyperlink }
