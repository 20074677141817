import styled from '@emotion/styled'
import { COLOR_PALETTE } from '../../GlobalStyle'

const GreyParagraph = styled.p`
    color: ${COLOR_PALETTE.grey_dark};
`

const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`

const NoDataContainer = styled.div`
    margin-top: 24px;
`

export { GreyParagraph, ButtonContainer, NoDataContainer }
