/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Fragment, FunctionComponent, useCallback, useEffect, useState } from 'react'
import { Country } from '../contracts/country'
import { AutocompleteSelect } from './autocomplete-select/AutocompleteSelect'
import { Input, InputLabel, InputProps } from './Input'

const defaultCodes: Array<Country> = [
    { phonePrefix: '+48', name: 'Poland' },
    { phonePrefix: '+49', name: 'Germany' },
    {
        phonePrefix: '+150',
        name: 'Gabu',
    },
    {
        phonePrefix: '+1939',
        name: 'Puerto Rico',
    },
]

const isPlusCode = (phone: string, firstGap: number) => {
    return phone && phone[0] === '+' && firstGap >= 2 && firstGap <= 5
}

const isZeroCode = (phone: string, firstGap: number) => {
    return phone && phone.startsWith('00') && firstGap >= 3 && firstGap <= 6
}


type PhoneInputProps = InputProps & {
    countryAreaCodes?: Array<Country>
}

const PhoneInput: FunctionComponent<PhoneInputProps> = ({
    name,
    label,
    value,
    defaultValue,
    onChange,
    innerRef,
    countryAreaCodes = defaultCodes,
    ...rest
}) => {
    const [fullPhone, setFullPhone] = useState('')
    const [area, setArea] = useState('')
    const [currentArea, setCurrentArea] = useState<Array<Country>>([])
    const [internalPhone, setInternalPhone] = useState('')
    const handleAreaCodeChange = (options: any) => {
        const [{ phonePrefix }] = options
        setArea(phonePrefix)
    }
    const handleInternalPhoneChange = useCallback((newPhone: string) => {
        const firstGap = newPhone.indexOf(' ')
        // like +1 up to +1939
        const isPlus = isPlusCode(newPhone, firstGap)
        const isZero = isZeroCode(newPhone, firstGap)

        if (isPlus || isZero) {
            const rawCode = newPhone.slice(0, firstGap)
            const code = isZero ? rawCode.replace('00', '+') : rawCode
            const internal = newPhone.slice(firstGap + 1, newPhone.length)
            const newCurrentArea = countryAreaCodes.find(a => a.phonePrefix === code)
            if (newCurrentArea) {
                setCurrentArea([newCurrentArea])
                setArea(code)
                setInternalPhone(internal)
            } else {
                setInternalPhone(newPhone)
            }
        } else {
            setInternalPhone(newPhone)
        }
    }, [countryAreaCodes])
    useEffect(() => {
        if (defaultValue) {
            handleInternalPhoneChange(defaultValue)
        }
    }, [defaultValue, handleInternalPhoneChange])
    useEffect(() => {
        setFullPhone(`${area} ${internalPhone}`)
    }, [area, internalPhone])

    return (
        <Fragment>
            <input
                hidden
                name={name}
                value={fullPhone}
                ref={innerRef}
                onChange={() => {
                    return
                }}
            />
            {label && <InputLabel>{label}</InputLabel>}
            <div
                css={css`
                    display: flex;
                `}
            >
                <div
                    css={css`
                        flex-shrink: 0;
                        width: 110px;
                    `}
                >
                    <AutocompleteSelect
                        placeholder='Area'
                        options={countryAreaCodes}
                        currentValues={currentArea}
                        selectedLabelTransformer={(opt: Country) => `${opt.phonePrefix}`}
                        labelTransformer={(opt: Country) => `${opt.phonePrefix} ${opt.name}`}
                        onSelectedValuesChange={handleAreaCodeChange}
                        selectWidth='100px'
                    />
                </div>
                <div
                    css={css`
                        flex-grow: 1;
                    `}
                >
                    <Input name='internalPhone' onChange={handleInternalPhoneChange} value={internalPhone} {...rest} />
                </div>
            </div>
        </Fragment>
    )
}

export { PhoneInput }
