import update from 'immutability-helper'
import React, { Context, createContext, Dispatch, FunctionComponent, useEffect, useReducer, useRef, useState } from 'react'
import { TabType } from '../components/layout/dashboard/Header'
import { Application } from '../contracts/applications'
import { Country } from '../contracts/country'
import { Nullable } from '../types'
import { firebase } from './../api/firebase'

const { connectViaExtension } = require('remotedev')

const ACTIONS = {
    USER_LOGIN: 'USER_LOGIN',
    USER_LOGOUT: 'USER_LOGOUT',
    LAYOUT_TOGGLE_ACTIVE_TAB: 'LAYOUT_TOGGLE_ACTIVE_TAB',
    LAYOUT_TOGGLE_LOADER: 'LAYOUT_TOGGLE_LOADER',
    PERMANENT_DATA_SET: 'PERMANENT_DATA_SET',
}

type User = Nullable<any>

type Layout = {
    activeTab: Nullable<TabType>
    showLoader: boolean
}

type PermanentData = {
    applications: Nullable<Array<Application>>
    countries: Nullable<Array<Country>>
    specialistRoles: Nullable<Array<string>>
}

export type State = {
    user: User
    layout: Layout
    permanentData: PermanentData
}

type Action = {
    type: string
    payload?: any
}

const initialState = {
    user: null,
    layout: {
        activeTab: null,
        showLoader: true,
    },
    permanentData: {
        applications: null,
        countries: null,
        specialistRoles: null
    },
}

type PermanentDataName = 'applications' | 'countries' | 'specialistRoles' // later will be "countries", "skills", etc...

type PermanentDataPayload = {
    name: PermanentDataName
    data: any
}

const reducer = (state: State, action: Action): State => {
    let output

    switch (action.type) {
        case ACTIONS.USER_LOGIN:
            output = update(state, { $merge: { user: action.payload } })
            break

        case ACTIONS.USER_LOGOUT:
            output = update(state, { $merge: { user: {} } })
            break

        case ACTIONS.LAYOUT_TOGGLE_ACTIVE_TAB:
            if (state.layout.activeTab === action.payload) {
                output = update(state, { $merge: { layout: { ...state.layout, activeTab: null } } })
            } else {
                output = update(state, { $merge: { layout: { ...state.layout, activeTab: action.payload } } })
            }
            break

        case ACTIONS.LAYOUT_TOGGLE_LOADER:
            output = update(state, { $merge: { layout: { ...state.layout, showLoader: action.payload } } })
            break
        case ACTIONS.PERMANENT_DATA_SET:
            output = update(state, {
                $merge: {
                    permanentData: {
                        ...state.permanentData,
                        [action.payload.name]: action.payload.data,
                    },
                },
            })
            break

        default:
            output = state
            break
    }

    return output
}

type Actions = {
    userLogin: (user: User) => void
    userLogout: () => void
    layoutToggleActiveTab: (tabType: TabType) => void
    layoutToggleLoader: (state: boolean) => void
    permanentDataSet: (dataPayload: PermanentDataPayload) => void
}

const useActions = (dispatch: Dispatch<Action>) => {
    return {
        userLogin: (user: User) => dispatch({ type: ACTIONS.USER_LOGIN, payload: user }),
        userLogout: () => dispatch({ type: ACTIONS.USER_LOGOUT }),
        layoutToggleActiveTab: (tabType: TabType) =>
            dispatch({
                type: ACTIONS.LAYOUT_TOGGLE_ACTIVE_TAB,
                payload: tabType,
            }),
        layoutToggleLoader: (state: boolean) => dispatch({ type: ACTIONS.LAYOUT_TOGGLE_LOADER, payload: state }),
        permanentDataSet: (dataPayload: PermanentDataPayload) =>
            dispatch({
                type: ACTIONS.PERMANENT_DATA_SET,
                payload: dataPayload,
            }),
    }
}

type Selectors = {
    user: Nullable<User>
    userId: Nullable<string>
    firebaseId: Nullable<string>
    layoutActiveTab: Nullable<TabType>
    showLoader: boolean
    permanentData: PermanentData
}

const useSelectors = (state: State): Selectors => {
    return {
        user: state?.user || null,
        userId: state?.user?.uid || null,
        firebaseId: state?.user?.firebaseId || null,
        layoutActiveTab: state.layout.activeTab,
        showLoader: state.layout.showLoader,
        permanentData: state.permanentData,
    }
}

type ReduxContextProps = {
    state: State
    dispatch?: Dispatch<Action>
    actions: Actions
    selectors: Selectors
}

const ReduxContext: Context<ReduxContextProps> = createContext<ReduxContextProps>({
    state: initialState,
    selectors: useSelectors(initialState),
    actions: useActions(() => null),
})

const ReduxProvider: FunctionComponent = ({ children }): any => {
    const [state, dispatch] = useReducer(reducer, initialState)
    const selectors = useSelectors(state)
    const remoteDevRef = useRef<any>()
    const [actionToLog, setActionToLog] = useState<Nullable<Action>>(null)

    useEffect(() => {
        remoteDevRef.current = connectViaExtension({ ...initialState })
    }, [remoteDevRef])

    useEffect(() => {
        if (actionToLog) {
            remoteDevRef.current.send(actionToLog, state)
            setActionToLog(null)
        }
    }, [actionToLog, state])

    const devToolsMiddleware = (dispatchHandler: Dispatch<Action>) => (action: Action) => {
        dispatchHandler(action)
        setActionToLog(action)
    }

    const actionsRef = useRef<Actions>(useActions(devToolsMiddleware(dispatch)))

    useEffect(() => {
        firebase.auth().onAuthStateChanged((user: any) => {
            const userData = user?.providerData[0] // @todo do something if there is more than a single provider
            if (userData) {
                userData.firebaseId = user.uid
                userData.emailVerified = user.emailVerified
                ;(firebase.auth().currentUser as firebase.User).getIdToken(true).then(accessToken => {
                    localStorage.setItem('accessToken', accessToken)
                    userData.accessToken = accessToken
                    actionsRef.current.userLogin(userData)
                })
            } else {
                actionsRef.current.userLogout()
            }
            actionsRef.current.layoutToggleLoader(false)
        })
    }, [actionsRef])

    return <ReduxContext.Provider value={{ state, selectors, actions: actionsRef.current }}>{children}</ReduxContext.Provider>
}

export { ReduxProvider, ReduxContext }
