import * as firebase from 'firebase/app'
import 'firebase/auth'
import config from './../environment/dev.config.json'

if (!firebase.apps.length) {
    firebase.initializeApp(config.firebaseConfig)
}

const providerGoogle = new firebase.auth.GoogleAuthProvider()
providerGoogle.addScope('https://www.googleapis.com/auth/contacts.readonly')

const providerMicrosoft = new firebase.auth.OAuthProvider('microsoft.com')

providerMicrosoft.setCustomParameters({})

providerMicrosoft.addScope('mail.read')
providerMicrosoft.addScope('calendars.read')

firebase.auth().tenantId = config.gcpTenantId
firebase.auth().useDeviceLanguage()

const handleError = (error: any): void => {
    console.warn('Firebase error: ', error)
}

const signInWithPopup = async (popupProvider: any): Promise<any> => {
    let response: any

    await firebase
        .auth()
        .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
        .then(async () => {
            await firebase
                .auth()
                .signInWithPopup(popupProvider)
                .then((r: any) => (response = r))
                .catch(handleError)
        })
        .catch(handleError)

    return response
}

const signInWithGoogle = async (): Promise<any> => {
    return signInWithPopup(providerGoogle)
}

const signInWithMicrosoft = async (): Promise<any> => {
    return signInWithPopup(providerMicrosoft)
}

const signOut = async (): Promise<null> => {
    await firebase.auth().signOut().then().catch(handleError)

    return null
}

const signInWithEmailAndPassword = async (email: string, password: string): Promise<any> => {
    const response = await firebase.auth().signInWithEmailAndPassword(email, password).catch(handleError)

    return response
}

const createUserWithEmailAndPassword = async (email: string, password: string): Promise<any> => {
    const response = await firebase.auth().createUserWithEmailAndPassword(email, password).catch(handleError)

    return response
}

const sendPasswordResetEmail = async (email: string): Promise<any> => {
    return firebase.auth().sendPasswordResetEmail(email).catch(handleError)
}

const sendVerificationEmail = async (): Promise<any> => {
    const user = firebase.auth().currentUser
    let response

    if (user && !user.emailVerified) {
        response = await user.sendEmailVerification().catch(handleError)
    }

    return response
}

const confirmPasswordReset = async (code: string, newPassword: string): Promise<any> => {
    return firebase.auth().confirmPasswordReset(code, newPassword)
}

export {
    firebase,
    signInWithGoogle,
    signOut,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    sendPasswordResetEmail,
    confirmPasswordReset,
    signInWithMicrosoft,
    sendVerificationEmail,
}
