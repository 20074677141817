export enum EmployeeTabType {
    PERSONAL,
    PROJECTS,
    EDUCATION,
    TECHNICAL,
}

const employeeTabsUtils = ['Personal', 'Projects', 'Education', 'Technical Skills']

export { employeeTabsUtils }
