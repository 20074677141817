/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Fragment } from 'react'
import { Pagination } from '../../../components/inputs/Pagination'



const Paginations = () => {

    return (
        <Fragment>
            <Pagination pageSize={20} defaultCurrent={5} total={222} />
        </Fragment>
    )
}

export { Paginations }
