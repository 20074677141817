/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Fragment, useState } from 'react'
import { Tabs, TabsColor } from '../../../components/Tabs'

const PlaygroundTabs = () => {
    const [selectedTab, setSelectedTab] = useState('Tab 1')
    const [availableTabs] = useState<string[]>(['Tab 1', 'Tab 2', 'Tab 3'])

    return (
        <Fragment>
            <Tabs
                tabs={availableTabs}
                onTabChange={newTab => {
                    setSelectedTab(availableTabs[newTab])
                }}
                mode={TabsColor.BLACK}
            />
            <Tabs
                tabs={availableTabs}
                onTabChange={newTab => {
                    setSelectedTab(availableTabs[newTab])
                }}
                mode={TabsColor.YELLOW}
            />
            {selectedTab === 'Tab 1' && <p>Tab 1 content</p>}
            {selectedTab === 'Tab 2' && <p>Tab 2 content</p>}
            {selectedTab === 'Tab 3' && <p>Tab 3 content</p>}
        </Fragment>
    )
}

export { PlaygroundTabs }
