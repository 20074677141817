/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import styled from '@emotion/styled'
import { FunctionComponent, SyntheticEvent, useCallback, useContext, useEffect, useMemo } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { getApplications } from '../../../api/api'
import { app2TileGroups, AppTileConfig, AppTileGroup } from '../../../contracts/applications'
import { COLOR_PALETTE, mqMin } from '../../../GlobalStyle'
import { ReduxContext } from '../../../redux/Store'
import { AppIcon, Icon, IconButton } from '../../Icon'
import { TabType } from './Header'

type LeftMenuProps = {
    label?: string
}

type AppLinkProps = {
    app: AppTileConfig
    current: boolean
}

const AppLink: FunctionComponent<AppLinkProps> = ({ app, current }) => {
    const handleClick = (e: SyntheticEvent) => {
        if (app.status === 'disabled') {
            e.preventDefault()
        }
    }
    
    return (
        <Link
            to={app.to}
            onClick={handleClick}
            css={css`
                display: flex;
                min-height: 48px;
                margin-left: 17px;
                align-items: center;
                cursor: ${app.status !== 'disabled' ? 'pointer' : 'not-allowed'};
                &:hover {
                    .icon {
                        background-color: ${app.status === 'available' ? COLOR_PALETTE.yellow : 'inherit'};
                    }
                }
            `}
        >
            <AppIcon
                size={30}
                name={app.icon}
                status={app.status}
                variant='menu'
                className='icon'
                css={css`
                    flex-shrink: 0;
                    background-color: ${current && app.status !== 'disabled' ? COLOR_PALETTE.yellow : 'inherit'};
                `}
            />
            <span
                className='text'
                css={css`
                    margin-left: 14px;
                    color: ${app.status === 'available' ? COLOR_PALETTE.black : COLOR_PALETTE.grey};
                    font-weight: ${current ? '500' : 'inherit'};
                `}
            >
                {app.title}
            </span>
        </Link>
    )
}

const HomeLink = () => (
    <Link
        to='/dashboard'
        css={css`
            display: flex;
            min-height: 48px;
            margin-top: 16px;
            margin-bottom: 10px;
            margin-left: 17px;
            align-items: center;
            cursor: pointer;
        `}
    >
        <IconButton
            size={30}
            name='cloud'
            variant='primary'
            css={css`
                flex-shrink: 0;
            `}
        />
        <span
            css={css`
                margin-left: 14px;
                color: ${COLOR_PALETTE.black};
            `}
        >
            Home
        </span>
    </Link>
)
type AppListProps = AppTileGroup & { currentPath: string }

const AppList: FunctionComponent<AppListProps> = ({ apps, currentPath }) => {
    return (
        <ul
            css={css`
                list-style: none;
                padding: 0 0 22px;
                margin: 0;
            `}
        >
            {apps &&
                apps.map((app, index) => (
                    <li key={index}>
                        <AppLink app={app} current={currentPath !== '/' && currentPath.startsWith(app.to)} />
                    </li>
                ))}
        </ul>
    )
}

type AppGroupsProps = {
    groups: Array<AppTileGroup>
}

const AppGroupWrapper = styled.div`
    border-top: 1px solid ${COLOR_PALETTE.grey_light};
`

const AppGroupName = styled.div`
    display: block;
    padding: 20px 0;
    margin-left: 17px;
`

const AppGroups: FunctionComponent<AppGroupsProps> = ({ groups }) => {
    const currentPath: string = useLocation().pathname
    return (
        <div>
            <HomeLink />
            {groups.map(group => (
                <AppGroupWrapper key={group.name}>
                    <AppGroupName>{group.name}</AppGroupName>
                    <AppList apps={group.apps} currentPath={currentPath} />
                </AppGroupWrapper>
            ))}
        </div>
    )
}

const LeftMenu: FunctionComponent<LeftMenuProps> = ({ label = 'Your apps:' }) => {
    const {
        actions: { layoutToggleActiveTab, permanentDataSet },
        selectors: {
            userId,
            permanentData: { applications },
        },
    } = useContext(ReduxContext)
    useEffect(() => {
        if (userId && applications === null) {
            const fetchData = async () => {
                const data = await getApplications(userId)
                permanentDataSet({ name: 'applications', data })
            }
            fetchData().finally()
        }
    }, [userId, permanentDataSet, applications])
    const appTileGroups = useMemo(() => {
        return applications ? app2TileGroups(applications) : null
    }, [applications])

    const closeMenu = useCallback(() => {
        layoutToggleActiveTab(TabType.leftMenu)
    }, [layoutToggleActiveTab])

    return (
        <div
            css={css`
                display: flex;
                flex-direction: column;
                width: 100%;
                background-color: ${COLOR_PALETTE.grey_lighter};
                position: fixed;
                min-height: calc(100vh - 57px);
                height: 100%;
                top: 57px;
                left: 0;
                z-index: 2;

                ${mqMin[2]} {
                    background-color: ${COLOR_PALETTE.white};
                    position: relative;
                    top: 0px;
                }
            `}
        >
            <Icon
                name='close'
                size={24}
                css={css`
                    opacity: 0.6;
                    position: absolute;
                    top: 5px;
                    right: 5px;

                    &:hover {
                        cursor: pointer;
                        opacity: 1;
                    }
                `}
                onClick={closeMenu}
            />
            {appTileGroups && <AppGroups groups={appTileGroups} />}
        </div>
    )
}

export { LeftMenu }
