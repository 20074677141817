/** @jsx jsx */
import { jsx } from '@emotion/core'
import styled from '@emotion/styled'
import { Fragment, FunctionComponent } from 'react'
import { Profile } from '../../contracts/profile/profile'
import { COLOR_PALETTE } from '../../GlobalStyle'
import { NoData } from '../NoData'
import { NoDataContainer } from '../shared/shared-styles'

const ZeroMarginH6 = styled.h6`
    margin: 0;
`

const LanguagesContainer = styled.div`
    margin-top: 26px;
    background-color: ${COLOR_PALETTE.grey_lighter};
    padding: 0 24px;
`

const LanguageRow = styled.div`
    display: flex;
    padding: 14px 0;
    border-bottom: 1px solid ${COLOR_PALETTE.grey_light};
    align-items: center;

    &:last-of-type {
        border: none;
    }
`

const LanguageTitle = styled.p`
    color: ${COLOR_PALETTE.black};
    flex: 1;
    margin: 0;
    font-weight: 500;
`

const LanguageLevel = styled.p`
    flex: 2;
    margin: 0;
`

type LanguagesProps = { profile: Profile }

const Languages: FunctionComponent<LanguagesProps> = ({ profile }) => {
    return (
        <Fragment>
            <ZeroMarginH6>Languages</ZeroMarginH6>

            {profile.languages && (
                <LanguagesContainer>
                    {profile.languages.map(language => {
                        return (
                            <LanguageRow key={language.name}>
                                <LanguageTitle>{language.name}</LanguageTitle>
                                <LanguageLevel>{language.level}</LanguageLevel>
                            </LanguageRow>
                        )
                    })}
                </LanguagesContainer>
            )}

            {profile.languages.length === 0 && (
                <NoDataContainer>
                    <NoData />
                </NoDataContainer>
            )}
        </Fragment>
    )
}

export { Languages }
