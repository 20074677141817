import styled from '@emotion/styled'

type FieldWrapperProps = {
    noLabel?: boolean
    noError?: boolean
}

const getMinHeight = ({ noLabel, noError }: FieldWrapperProps) => {
    let height = 84
    if (noLabel) {
        height -= 24
    }
    if (noError) {
        height -= 20
    }
    return height
}

const FieldWrapper = styled.div<FieldWrapperProps>`
    width: 100%;
    min-height: ${props => getMinHeight(props)}px;
`

export { FieldWrapper }
