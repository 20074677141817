/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { sendPasswordResetEmail } from '../../api/firebase'
import { BackLink } from '../../components/BackLink'
import { Button } from '../../components/Button'
import { Input } from '../../components/Input'
import { FieldWrapper } from '../../components/layout/FormHelpers'
import { ResponsiveWrapper } from '../../components/layout/ResponsiveWrapper'
import { resolveFieldError } from '../../utils/errors'
import { RecoverPasswordEmailSent } from './RecoverPasswordEmailSent'

const schema = yup.object().shape({
    email: yup.string().email('Please enter valid e-mail').required('This is a required field'),
})

const RecoverPassword = () => {
    const { register, handleSubmit, errors, formState } = useForm({
        validationSchema: schema,
    })
    const [emailSent, setEmailSent] = useState(false)

    const onSubmit = async (data: any) => {
        const { email } = data
        await sendPasswordResetEmail(email)
        setEmailSent(true)
    }
    if (emailSent) {
        return <RecoverPasswordEmailSent />
    }

    return (
        <ResponsiveWrapper withLogo>
            <form onSubmit={handleSubmit(onSubmit)}>
                <BackLink path='/' text='Back to Login' />
                <h1>Recover Password</h1>
                <p>Please enter the business e-mail address you used to set up your Talent Alpha account.</p>
                <FieldWrapper>
                    <Input
                        name='email'
                        label='E-mail'
                        placeholder='E-mail address'
                        type='text'
                        innerRef={register}
                        errorText={resolveFieldError(errors.email)}
                    />
                </FieldWrapper>
                <Button variant='primary' type='submit' disabled={formState.isSubmitting}>
                    Reset my Password
                </Button>
            </form>
        </ResponsiveWrapper>
    )
}

export { RecoverPassword }
