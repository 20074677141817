/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Fragment, FunctionComponent, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { convertTimestampToDateString } from '../../utils/dates'
import { Button } from '../Button'
import { Divider } from '../Divider'
import { ButtonContainer, GreyParagraph } from '../shared/shared-styles'
import { Textarea } from '../Textarea'
import { ContactFormProps } from './ContactSalesModal'

const schema = yup.object().shape({
    additionalInfo: yup.string().required('This field is required'),
})

const RepeatedContactForm: FunctionComponent<ContactFormProps> = ({ onClose, profile, sendForm }) => {
    const [contactDate, setContactDate] = useState<string>('')
    const { register, handleSubmit, formState, reset } = useForm({
        validationSchema: schema,
    })

    useEffect(() => {
        if (profile?.contactedAt) {
            setContactDate(convertTimestampToDateString(profile?.contactedAt, 'dd.MM.yyyy'))
        }
    }, [profile])

    const onSubmit = (formData: any) => {
        sendForm(formData)
        reset()
    }

    return (
        <Fragment>
            <h5>Thanks For Your Patience</h5>

            <GreyParagraph>
                We are still processing the booking you made on {contactDate}. Our Talent Experts are hard at work checking the availability
                of the Specialist(s) you requested.
            </GreyParagraph>

            <GreyParagraph>
                Please let us know if you’d like to add any additional information about your project or the number of Specialists you would
                like to book.
            </GreyParagraph>

            <form onSubmit={handleSubmit(onSubmit)}>
                <Textarea
                    name='additionalInfo'
                    innerRef={register}
                    placeholder='I need a Team of Java Developers...'
                    label='Additional Information'
                />

                <Divider />

                <ButtonContainer>
                    <Button
                        css={css`
                            margin-right: 28px;
                        `}
                        variant='link'
                        onClick={onClose}
                    >
                        Cancel
                    </Button>
                    <Button type='submit' variant='primary' disabled={formState.isSubmitting || !formState.dirty}>
                        Send
                    </Button>
                </ButtonContainer>
            </form>
        </Fragment>
    )
}

export { RepeatedContactForm }
