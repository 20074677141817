/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import linkedIn from '../assets/logos/linked-in.svg'
import { mqMax } from '../GlobalStyle'
import { Logo } from './Logo'

const Footer = () => {
    return (
        <div
            css={css`
                height: 80px;
                padding: 0 96px;
                display: flex;
                justify-content: space-between;
                align-items: center;

                ${mqMax[0]} {
                    display: none;
                }
            `}
        >
            <Logo />
            <div
                css={css`
                    display: inline-flex;
                    justify-content: space-between;
                `}
            >
                <div
                    css={css`
                        margin-right: 128px;
                        display: flex;
                        flex-direction: column;
                    `}
                >
                    <small>Krakow Technology Park,</small>
                    <small>ul. Podole 76, 30-394 Kraków, Poland</small>
                </div>
                <div
                    css={css`
                        display: flex;
                        flex-direction: column;
                    `}
                >
                    <small>+48 506 466 655</small>
                    <small>contact@talent-alpha.com</small>
                </div>
            </div>
            <img alt='Linked In' src={linkedIn} />
        </div>
    )
}

export { Footer }
