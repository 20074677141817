/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { FunctionComponent, useContext } from 'react'
import { Link } from 'react-router-dom'
import { COLOR_PALETTE, mqMin } from '../../../GlobalStyle'
import { ReduxContext } from '../../../redux/Store'
import { Icon } from '../../Icon'
import { Logo } from '../../Logo'
import { ApplicationBox } from './ApplicationBox'
import { ContextMenu } from './ContextMenu'
import { HeaderBox } from './HeaderBox'

const iconStyles = css`
    width: 20px;
    height: 20px;
`

enum TabType {
    leftMenu = 1,
    settings = 2,
    userMenu = 3,
}

type HeaderProps = {
    applicationName?: string
    showMenuItems?: boolean
}

const Header: FunctionComponent<HeaderProps> = ({ applicationName, children, showMenuItems = true }) => {
    const {
        actions: { layoutToggleActiveTab },
        selectors: { layoutActiveTab, user },
    } = useContext(ReduxContext)

    const toggleActiveTab = (tab: TabType) => {
        layoutToggleActiveTab(tab)
    }

    return (
        <header
            css={css`
                background-color: ${COLOR_PALETTE.white};
                box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-bottom: 1px solid ${COLOR_PALETTE.grey_light};
                position: fixed;
                top: 0;
                left: 0;
                width: 100%;
                z-index: 10;

                ${mqMin[2]} {
                    position: relative;
                }
            `}
        >
            <div
                css={css`
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                `}
            >
                {showMenuItems && (
                    <HeaderBox onClick={() => toggleActiveTab(TabType.leftMenu)} isActive={layoutActiveTab === TabType.leftMenu}>
                        <Icon name='grid' style={iconStyles} />
                    </HeaderBox>
                )}

                <HeaderBox
                    style={css`
                        margin-left: ${showMenuItems ? '0px' : '60px'};
                    `}
                >
                    <Link to='/dashboard'>
                        <Logo
                            type='logoFull'
                            style={css`
                                width: 79px;
                                height: 28px;
                            `}
                        />
                    </Link>
                </HeaderBox>

                {applicationName && (
                    <ApplicationBox
                        css={css`
                            margin-left: 8px;
                        `}
                    >
                        {applicationName}
                    </ApplicationBox>
                )}

                <div
                    css={css`
                        margin-left: 70px;
                        display: none;
                        justify-content: center;
                        align-items: center;
                        ${mqMin[1]} {
                            display: flex;
                        }
                    `}
                >
                    {children}
                </div>
            </div>

            {showMenuItems && (
                <div
                    css={css`
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    `}
                >
                    <HeaderBox onClick={() => toggleActiveTab(TabType.settings)} isActive={layoutActiveTab === TabType.settings}>
                        <Icon name='cog' style={iconStyles} />
                        {layoutActiveTab === TabType.settings && (
                            <ContextMenu
                                items={[
                                    {
                                        path: '/dashboard/my-account/personal-details',
                                        title: 'My Account',
                                        showDivider: true,
                                    },
                                    {
                                        path: '/dashboard/company-account/company-details',
                                        title: 'Company Account',
                                    },
                                ]}
                            />
                        )}
                    </HeaderBox>

                    <div
                        css={css`
                            display: flex;
                            width: 1px;
                            height: 56px;
                            background-color: ${COLOR_PALETTE.grey_light};
                        `}
                    ></div>

                    <HeaderBox onClick={() => toggleActiveTab(TabType.userMenu)} isActive={layoutActiveTab === TabType.userMenu}>
                        <Icon name='user' style={iconStyles} />
                        {layoutActiveTab === TabType.userMenu && (
                            <ContextMenu
                                items={[
                                    {
                                        title: user.email ? user.email : undefined,
                                    },

                                    {
                                        path: '/documents/terms-of-use',
                                        title: 'Terms of use',
                                        isExternal: true,
                                    },
                                    {
                                        path: '/documents/privacy-policy',
                                        title: 'Privacy Policy',
                                        showDivider: true,
                                        isExternal: true,
                                    },
                                    {
                                        path: '/logout/user',
                                        title: 'Log out',
                                    },
                                ]}
                            />
                        )}
                    </HeaderBox>
                </div>
            )}
        </header>
    )
}

export { Header, TabType }
