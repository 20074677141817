import { ApplicationMenuItemEntry } from '../../components/layout/dashboard/ApplicationMenu'
import { getAppPath } from '../../contracts/applications'

const applicationName = 'Talent Management'

const menuItems: Array<ApplicationMenuItemEntry> = [
    {
        path: getAppPath('TALENT_MANAGEMENT'),
        title: 'My Employees',
    },
]

export { applicationName, menuItems }
