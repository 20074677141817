/** @jsx jsx */
import { jsx } from '@emotion/core'
import styled from '@emotion/styled'
import { Fragment, FunctionComponent, useState } from 'react'
import { Profile } from '../../contracts/profile/profile'
import { Education } from '../Education'
import { NoData } from '../NoData'
import { NoDataContainer } from '../shared/shared-styles'
import { Tabs, TabsColor } from '../Tabs'
import { TimelineGroup } from '../timeline/TimelineGroup'

const TitleH6 = styled.h6`
    margin: 0 0 26px;
`

type ExperienceProps = { profile: Profile }

const Experience: FunctionComponent<ExperienceProps> = ({ profile }) => {
    const [selectedExperienceTab, setSelectedExperienceTab] = useState<string>('Projects')
    const [availableExperienceTabs] = useState<string[]>(['Projects', 'Education'])

    return (
        <Fragment>
            <TitleH6>Experience</TitleH6>
            <Tabs
                tabs={availableExperienceTabs}
                onTabChange={newTab => {
                    setSelectedExperienceTab(availableExperienceTabs[newTab])
                }}
                mode={TabsColor.BLACK}
            />
            {selectedExperienceTab === 'Projects' && (
                <Fragment>
                    {profile.projects.length > 0 && (
                        <Fragment>
                            {profile.projects.map((project, index) => (
                                <TimelineGroup key={`project-${index}`} companyName={project.companyName} positions={project.positions} />
                            ))}
                        </Fragment>
                    )}
                    {profile.projects.length === 0 && (
                        <NoDataContainer>
                            <NoData />
                        </NoDataContainer>
                    )}
                </Fragment>
            )}
            {selectedExperienceTab === 'Education' && (
                <Fragment>
                    {profile.education.length > 0 && (
                        <Fragment>
                            {profile.education.map((education, index) => (
                                <Education key={`education-${index}`} education={education} />
                            ))}
                        </Fragment>
                    )}
                    {profile.education.length === 0 && (
                        <NoDataContainer>
                            <NoData />
                        </NoDataContainer>
                    )}
                </Fragment>
            )}
        </Fragment>
    )
}

export { Experience }
