/** @jsx jsx */
import { jsx } from '@emotion/core'
import { FunctionComponent } from 'react'
import { IconButton } from '../../../components/Icon'
import { SectionHeaderWithActions } from '../shared/HeaderWithActions'
import { PersonalTabReadOnly } from './PersonalTabReadOnly'
import { SectionWrapper } from './SectionHelpers'

type SocialLink = {
    url: string
    label: string
}
// Todo move to contracts

export interface PersonalData {
    birthDate: number
    gender: string
    city: string
    country: string
    street: string
    phone: string
    postalCode: string
    socialLinks: Array<SocialLink>
}

const data: PersonalData = {
    birthDate: 741571201,
    gender: 'MALE',
    city: 'Krakow',
    country: 'Poland',
    street: 'Podole 76',
    phone: '+48 517 866 840',
    postalCode: '80-808',
    socialLinks: [
        {
            label: 'LinkedIn',
            url: 'https://www.linkedin.com/in/lukasz-zeromski/',
        },
        {
            label: 'Github',
            url: 'https://github.com/lukzerom',
        },
        {
            label: 'Portfolio',
            url: 'https://portfolio.com',
        },
    ],
}

const PersonalTab: FunctionComponent = () => {
    return (
        <SectionWrapper>
            <SectionHeaderWithActions header='Personal' divider>
                <IconButton name='edit' size={32} />
            </SectionHeaderWithActions>
            <PersonalTabReadOnly personalData={data} />
        </SectionWrapper>
    )
}

export { PersonalTab }
