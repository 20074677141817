/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Fragment } from 'react'
import { Education } from '../../../components/Education'
import { Education as EducationModel } from '../../../contracts/profile/education'
import { randomString } from '../../../utils/strings'

const educations: Array<EducationModel> = [
    {
        type: 'CERTIFICATE',
        school: 'Oracle',
        educationDegree: null,
        name: 'OCP',
        specialization: null,
        faculty: null,
    },
    {
        type: 'COURSE',
        school: 'Niebezpiecznik',
        educationDegree: null,
        name: 'Security training',
        specialization: null,
        faculty: null,
    },
    {
        type: 'EDUCATION',
        school: 'Jagiellonian University',
        educationDegree: null,
        name: null,
        specialization: 'Computer Science',
        faculty: 'Math Department',
    },
]

const Educations = () => {
    return (
        <Fragment>
            {educations.map(education => (
                <Education key={randomString()} education={education} />
            ))}
        </Fragment>
    )
}

export { Educations }
