/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { underlinedText } from '../GlobalStyle'
import awards from './../assets/images/awards.png'

const Awards = () => {
    return (
        <div
            css={css`
                display: flex;
                flex-direction: column;
            `}
        >
            <h4>Close Your Tech Talent Gap With Our</h4>
            <div>
                <h2 css={underlinedText}>Human Cloud Platform</h2>
            </div>
            <img
                css={css`
                    max-width: 470px;
                `}
                src={awards}
                alt='Our awards'
            />
        </div>
    )
}

export { Awards }
