/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import styled from '@emotion/styled'
import { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'
import { COLOR_PALETTE, mqMax, mqMin } from '../../GlobalStyle'
import { Logo } from '../Logo'

export type ResponsiveWrapperProps = {
    withLogo?: boolean
}

const maxContentWidth = 408
const horizontalContentPadding = 16
const verticalContentPadding = 20
const desktopVerticalContentPadding = 40

const ContentWrapper = styled.div`
    padding: ${verticalContentPadding}px ${horizontalContentPadding}px;
    ${mqMin[1]} {
        padding-top: ${desktopVerticalContentPadding}px;
    }
`

const WrapperBase = styled.div`
    margin: 18px auto;
    padding: 0 16px;
    width: 100%;
    ${mqMin[2]} {
        padding: 0 24px;
    }
`

const FullPageWrapper = styled(WrapperBase)`
    max-width: 1440px;
`

const ApplicationsWrapper = styled(WrapperBase)`
    max-width: ${1232 + verticalContentPadding}px;
`
const SettingsWrapper = styled(WrapperBase)`
    width: 100%;
    ${mqMin[2]} {
        margin: 0 calc(100% / 12);
        width: 546px;
        padding: ${desktopVerticalContentPadding}px 0 0 0;
    }
`

const ResponsiveWrapper: FunctionComponent<ResponsiveWrapperProps> = ({ children, withLogo = false }) => {
    return (
        <div
            css={css`
                background-color: ${COLOR_PALETTE.white};

                ${mqMax[1]} {
                    width: 100%;
                }
            `}
        >
            <div
                css={css`
                    max-width: ${maxContentWidth + horizontalContentPadding}px;
                    margin: 0 auto;

                    ${mqMax[1]} {
                        max-width: none;
                    }
                `}
            >
                <ContentWrapper>
                    {withLogo && (
                        <Link to='/'>
                            <Logo type='logoFull' />
                        </Link>
                    )}
                    <div
                        css={css`
                            margin-top: ${withLogo ? 30 : 0}px;
                        `}
                    >
                        {children}
                    </div>
                </ContentWrapper>
            </div>
        </div>
    )
}

export { ResponsiveWrapper, ContentWrapper, FullPageWrapper, ApplicationsWrapper, SettingsWrapper }
