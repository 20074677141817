/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { FunctionComponent, ReactElement } from 'react'
import { Icon, IconName } from '../../../components/Icon'
import { COLOR_PALETTE } from '../../../theme/colors'

export type DataNotFoundProps = {
    title: string
    description?: string | ReactElement
    iconName?: IconName
}

const DataNotFound: FunctionComponent<DataNotFoundProps> = ({ title, description = '', iconName, children, ...rest }) => {
    return (
        <div
            css={css`
                display: flex;
                justify-content: center;
                align-items: center;
            `}
            {...rest}
        >
            <div
                css={css`
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                `}
            >
                {iconName && (
                    <Icon
                        name={iconName}
                        size={32}
                        css={css`
                            margin-bottom: 24px;
                        `}
                    />
                )}
                <h5>{title}</h5>
                <p
                    css={css`
                        color: ${COLOR_PALETTE.grey};
                        text-align: center;
                        margin: 0 auto 22px;
                    `}
                >
                    {description}
                </p>
                {children}
            </div>
        </div>
    )
}

export { DataNotFound }
