/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { FunctionComponent, useContext, useEffect, useState } from 'react'
import { getUser, sendContactForm } from '../../api/api'
import { ContactForm } from '../../contracts/profile/contactForm'
import { Profile } from '../../contracts/profile/profile'
import { ReduxContext } from '../../redux/Store'
import { Nullable } from '../../types'
import { Modal, ModalProps } from '../Modal'
import { useNotifications } from '../notification/NotificationProvider'
import { FirstContactModal } from './FirstContactModal'
import { RepeatedContactForm } from './RepeatedContactForm'

export type ContactSalesModalProps = ModalProps & {
    profile: Nullable<Profile>
    setRefetchProfile?: (val: boolean) => void
}

export type ContactFormProps = ContactSalesModalProps & {
    userData: Nullable<any>
    sendForm: (formData: ContactForm) => void
}

const ContactSalesModal: FunctionComponent<ContactSalesModalProps> = ({ onClose, opened, profile, setRefetchProfile }) => {
    const {
        selectors: { user },
    } = useContext(ReduxContext)
    const { addSuccess, addError } = useNotifications()
    const [userData, setUserData] = useState<Nullable<any>>(null)
    const [firstContact, setFirstContact] = useState(profile?.contactedAt === null)

    useEffect(() => {
        const runEffect = async () => {
            if (user?.uid) {
                const data = await getUser(user.firebaseId)
                setUserData(data)
                data.projectDurationUnit = 'MONTHS'
            }
        }
        runEffect().finally()
    }, [user])

    useEffect(() => {
        if (profile?.contactedAt) {
            setFirstContact(profile.contactedAt === null)
        }
    }, [profile])

    const sendForm = (formData: ContactForm) => {
        const requestBody = mapFormDataToRequest(formData)

        sendContactForm(requestBody).then(
            () => {
                if (setRefetchProfile) {
                    setRefetchProfile(true)
                }

                if (firstContact) {
                    addSuccess('Your booking request has been sent. One of our Talent Experts will contact you as soon as possible.')
                } else {
                    addSuccess('Your message was sent successfully.')
                }

                onClose()
            },
            () => {
                addError('There was a problem sending your contact form. Please try again.')
            },
        )
    }

    const mapFormDataToRequest = (contactForm: ContactForm): any => {
        const requestBody: { [key: string]: any } = {}

        Object.entries(contactForm).forEach(([key, value]) => {
            if (value !== null && value !== '') {
                requestBody[key] = value
            }
        })

        if (requestBody.unknownScope === true) {
            delete requestBody.idealProjectStartDate
            delete requestBody.latestProjectStartDate
            delete requestBody.projectDuration
            delete requestBody.projectDurationUnit
        } else if (requestBody.unknownScope === false) {
            requestBody.idealProjectStartDate = new Date(contactForm.idealProjectStartDate).getTime() / 1000
            requestBody.latestProjectStartDate = new Date(contactForm.latestProjectStartDate).getTime() / 1000
        }

        requestBody.firstName = userData.firstName
        requestBody.lastName = userData.lastName
        requestBody.phone = userData.phone
        requestBody.specialistId = profile?.specialistId

        return requestBody
    }

    return (
        <Modal
            onClose={onClose}
            opened={opened}
            style={css`
                max-width: 544px;
            `}
        >
            {firstContact ? (
                <FirstContactModal onClose={onClose} opened={opened} userData={userData} profile={profile} sendForm={sendForm} />
            ) : (
                <RepeatedContactForm onClose={onClose} opened={opened} userData={userData} profile={profile} sendForm={sendForm} />
            )}
        </Modal>
    )
}

export { ContactSalesModal }
