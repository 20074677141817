/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { COLOR_PALETTE } from '../GlobalStyle'

const Alert = ({ content, type = 'success' }: { content: string; type?: 'error' | 'success' }) => {
    const errorStyles = css`
        background-color: #ffe9e9;
        color: ${COLOR_PALETTE.red};
    `

    const successStyles = css`
        background-color: ${COLOR_PALETTE.yellow};
        color: ${COLOR_PALETTE.black};
    `

    const getStyles = () => {
        switch (type) {
            case 'error':
                return errorStyles

            case 'success':
                return successStyles

            default:
                throw new Error('Undefined alert type!')
        }
    }

    return (
        <div
            css={css`
                position: relative;
                text-align: center;
                padding: 8px;
                border-radius: 4px;
                font-size: 12px;

                ${getStyles()}
            `}
            dangerouslySetInnerHTML={{ __html: content }}
        ></div>
    )
}

export { Alert }
