/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import styled from '@emotion/styled'
import { ColumnsType } from 'antd/lib/table/interface'
import React, { FunctionComponent, ReactElement, useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { getEmployees, getEmployeesFilters } from '../../api/api'
import { Table } from '../../components/antd'
import { Button } from '../../components/Button'
import { getSvg, IconButton } from '../../components/Icon'
import { Pagination } from '../../components/inputs/Pagination'
import { DashboardLayout } from '../../components/layout/dashboard/DashboardLayout'
import { HideOnHover, ShowOnHover, ToggleOnHover } from '../../components/layout/DataTableHelpers'
import { OverlayContentLoader } from '../../components/layout/Loader'
import { FullPageWrapper } from '../../components/layout/ResponsiveWrapper'
import { useNotifications } from '../../components/notification/NotificationProvider'
import { CircularProgress } from '../../components/progress/CircularProgress'
import { SearchInput } from '../../components/SearchInput'
import { getAppPath } from '../../contracts/applications'
import { Employee, mapEmploymentType } from '../../contracts/employees/employee'
import { EmployeesFilters, EmployeesFiltersOptions } from '../../contracts/employees/employeesFilters'
import { NullableArray } from '../../types'
import { AddEmployeesModal } from './employee-search/AddEmployeesModal'
import { EmployeesFiltersBar } from './employee-search/EmployeesFiltersBar'
import { DataNotFound } from './shared/DataNotFound'
import { HeaderWithActions } from './shared/HeaderWithActions'
import { applicationName, menuItems } from './talentManagementApp'
import { TalentManagementGuard } from './TalentManagementGuard'
import { countSelectedFilters } from './talentManagementUtils'

type EmployeesResultsWrapperProps = {
    pending?: boolean
    total: number
    filtered: boolean
    AddEmployeeButton: ReactElement
}

const EmployeesNotFound = styled(DataNotFound)`
    margin-top: calc(10vh);
`

const EmployeesResultsWrapper: FunctionComponent<EmployeesResultsWrapperProps> = ({
    pending,
    total,
    filtered,
    AddEmployeeButton,
    children,
}) => {
    return (
        <div
            css={css`
                position: relative;
            `}
        >
            {!pending && !total && filtered && <EmployeesNotFound title='No Employees Found' description='Try changing your filters or query' />}
            {!pending && !total && !filtered && (
                <EmployeesNotFound
                    iconName='no-data'
                    title='No Employees Added'
                    description={
                        <span>
                            Currently you have no specialists on your list. <br />
                            Click button below to add specialists.
                        </span>
                    }
                >
                    {AddEmployeeButton}
                </EmployeesNotFound>
            )}
            {total > 0 && <div>{children}</div>}
            {pending && <OverlayContentLoader />}
        </div>
    )
}
const defaultPageSize = 10

const initialPossibleFilters: EmployeesFiltersOptions = {
    employmentTypes: [],
    roles: [],
    seniorities: [],
    skills: [],
    locations: [],
}

const initialSelectedFilters: EmployeesFilters = {
    employmentTypes: [],
    roles: [],
    seniorities: [],
    skills: [],
    locations: [],
    remote: false,
}

const EmployeesSearchPage: FunctionComponent = () => {
    const { addSuccess } = useNotifications()
    const [filtersOpened, setFiltersOpened] = useState(false)
    const [addEmployeesOpened, setAddEmployeesOpened] = useState(false)
    const [pending, setPending] = useState(false)
    const [searchText, setSearchText] = useState('')
    const [possibleFilters, setPossibleFilters] = useState(initialPossibleFilters)
    const [selectedFilters, setSelectedFilters] = useState(initialSelectedFilters)
    const [paging, setPaging] = useState({ page: 1, size: defaultPageSize })
    const [sorting, setSorting] = useState<Array<any>>([])
    const [employees, setEmployees] = useState<NullableArray<Employee>>(null)
    const [total, setTotal] = useState(0)

    const onToggleFilters = useCallback(() => {
        setFiltersOpened(!filtersOpened)
    }, [filtersOpened])

    const fetchEmployees = useCallback(() => {
        setPending(true)
        const request = { searchText, ...selectedFilters, paging, sorting }
        getEmployees(request)
            .then(data => {
                setEmployees(data.employees)
                setTotal(data.total)
            })
            .finally(() => {
                setPending(false)
            })
    }, [searchText, selectedFilters, paging, sorting])

    const fetchPossibleFilters = useCallback(() => {
        const request = { searchText }
        getEmployeesFilters(request)
            .then(data => {
                setPossibleFilters(data)
            })
            .finally()
    }, [searchText])
    useEffect(fetchPossibleFilters, [searchText])

    const onChangeSearchText = useCallback((text: string) => {
        setSearchText(text)
        setSelectedFilters(initialSelectedFilters)
        setPaging({ page: 1, size: defaultPageSize })
    }, [])

    const onChangeFilters = useCallback((newFilters: EmployeesFilters) => {
        setSelectedFilters(newFilters)
    }, [])

    const onClickAddEmployee = useCallback(() => {
        setAddEmployeesOpened(true)
    }, [])
    const onCloseAddEmployeesModal = useCallback(
        (saved: number) => {
            setAddEmployeesOpened(false)
            if (saved) {
                addSuccess(`New Employee${saved > 1 ? 's were' : ' was'} created successfully.`)
                fetchEmployees()
            }
        },
        [fetchEmployees, addSuccess],
    )

    useEffect(fetchEmployees, [searchText, selectedFilters, paging, sorting])

    const filtered = searchText !== '' && countSelectedFilters(selectedFilters) > 0
    const AddEmployeeButton = React.memo(() => (
        <Button variant='primary' icon={getSvg('user-add')} onClick={onClickAddEmployee}>
            Create New Employees
        </Button>
    ))

    const onChangeTable = useCallback((_, __, newSorter) => {
        if (Array.isArray(newSorter)) {
            const newSorting = newSorter.filter(({ order }) => order).map(({ field, order }) => ({ field, order }))
            setSorting(newSorting)
        } else if (newSorter) {
            const { field, order } = newSorter
            const newSorting = order ? [{ field, order }] : []
            setSorting(newSorting)
        }
    }, [])

    const onChangePage = useCallback((page, size) => {
        setPaging({ page, size })
    }, [])

    const onClearFilters = useCallback(() => {
        setSelectedFilters(initialSelectedFilters)
    }, [])

    return (
        <TalentManagementGuard>
            <DashboardLayout applicationName={applicationName} applicationMenuItems={menuItems}>
                <FullPageWrapper>
                    <HeaderWithActions header='My Employees'>
                        <AddEmployeeButton />
                    </HeaderWithActions>
                    <SearchInput
                        initialText={searchText}
                        onSubmit={onChangeSearchText}
                        filtersOpened={filtersOpened}
                        onToggleFilters={onToggleFilters}
                        placeholder='Skills, tools, frameworks, etc.'
                    />
                    <EmployeesFiltersBar
                        opened={filtersOpened}
                        possibleFilters={possibleFilters}
                        selectedFilters={selectedFilters}
                        onChangeFilters={onChangeFilters}
                        onClearAll={onClearFilters}
                    />
                    <EmployeesResultsWrapper pending={pending} total={total} filtered={filtered} AddEmployeeButton={<AddEmployeeButton />}>
                        <Table rowKey='id' dataSource={employees || []} columns={columns} pagination={false} onChange={onChangeTable} />
                        <Pagination
                            pageSize={defaultPageSize}
                            total={total}
                            onChange={onChangePage}
                            current={paging.page}
                            css={css`
                                margin-top: 40px;
                            `}
                        />
                    </EmployeesResultsWrapper>
                </FullPageWrapper>
                {addEmployeesOpened && <AddEmployeesModal onClose={onCloseAddEmployeesModal} opened={addEmployeesOpened} />}
            </DashboardLayout>
        </TalentManagementGuard>
    )
}

const ProfileActionsRenderer = (record: any) => {
    const profileDetails = `${getAppPath('TALENT_MANAGEMENT')}/employee/${record.id}`
    return (
        <ToggleOnHover
            css={css`
                min-height: 40px;
                display: flex;
                align-items: center;
            `}
        >
            <ShowOnHover>
                <IconButton name='plane' />
                <Link to={profileDetails}>
                    <IconButton name='profile-details' />
                </Link>
            </ShowOnHover>
            <HideOnHover>
                <CircularProgress percent={record.profileStrength || 0} />
            </HideOnHover>
        </ToggleOnHover>
    )
}
type ComparerCreator<RecordType = any> = (fieldName: keyof RecordType) => (a: RecordType, b: RecordType) => number
const createComparer: ComparerCreator = fieldName => (a, b) => a[fieldName] - b[fieldName]

const columns: ColumnsType<Employee> = [
    {
        title: 'First name',
        dataIndex: 'firstName',
        sorter: {
            compare: createComparer('firstName'),
            multiple: 1,
        },
        showSorterTooltip: false,
    },
    {
        title: 'Last name',
        dataIndex: 'lastName',
        sorter: {
            compare: createComparer('lastName'),
            multiple: 2,
        },
        showSorterTooltip: false,
    },
    {
        title: 'Type',
        dataIndex: 'employmentType',
        render: mapEmploymentType,
    },
    {
        title: 'Role',
        dataIndex: 'role',
        sorter: {
            compare: createComparer('role'),
            multiple: 3,
        },
        showSorterTooltip: false,
    },
    {
        title: 'Seniority',
        dataIndex: 'seniority',
    },
    {
        title: 'Country',
        dataIndex: 'country',
    },
    {
        title: 'City',
        dataIndex: 'city',
    },
    {
        title: 'Profile Strength',
        render: ProfileActionsRenderer,
    },
]

export { EmployeesSearchPage }
