/** @jsx jsx */
import { css, jsx, SerializedStyles } from '@emotion/core'
import { ChangeEvent, useEffect, useState } from 'react'
import { COLOR_PALETTE } from '../GlobalStyle'
import { InputLabel } from './Input'
import { ErrorText } from './inputs/ErrorText'
import { Tooltip, TooltipIcon } from './Tooltip'

const Textarea = ({
    name,
    onChange,
    value = '',
    errorText,
    label,
    placeholder,
    disabled = false,
    style,
    innerRef,
    tooltip,
    defaultValue,
}: {
    name: string
    onChange?: (value: string) => void
    value?: string
    errorText?: string
    label?: string
    placeholder?: string
    disabled?: boolean
    style?: SerializedStyles
    defaultValue?: string
    innerRef?: any
    tooltip?: string
}) => {
    const [valueState, setValueState] = useState(value)

    useEffect(() => {
        setValueState(value)
    }, [value])

    const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        setValueState(event.target.value)

        if (onChange) {
            onChange(event.target.value)
        }
    }

    const controlProps = !innerRef
        ? {
              value: valueState,
              onChange: handleChange,
          }
        : {}

    const addErrorStyles = () => {
        return errorText
            ? css`
                  textarea {
                      border: 1px solid ${COLOR_PALETTE.red};
                  }
              `
            : null
    }

    return (
        <div
            css={[
                css`
                    display: flex;
                    flex-direction: column;
                    position: relative;
                    margin-bottom: 16px;

                    ${style}
                `,
                addErrorStyles(),
            ]}
        >
            {label && (
                <InputLabel>
                    {label}{' '}
                    {tooltip && (
                        <Tooltip content={tooltip}>
                            <TooltipIcon>?</TooltipIcon>
                        </Tooltip>
                    )}
                </InputLabel>
            )}
            <textarea
                css={css`
                    width: 100%;
                    height: 144px;
                    border: 1px solid ${COLOR_PALETTE.grey_light};
                    border-radius: 2px;
                    padding: 9px 16px;
                    outline: none;
                    color: ${COLOR_PALETTE.black};

                    &::placeholder {
                        color: ${COLOR_PALETTE.grey};
                    }

                    &[disabled] {
                        background-color: ${COLOR_PALETTE.grey_lighter};
                        color: ${COLOR_PALETTE.grey};
                    }

                    &:focus {
                        border-color: ${COLOR_PALETTE.grey};
                    }
                `}
                name={name}
                placeholder={placeholder}
                disabled={disabled}
                ref={innerRef}
                defaultValue={defaultValue}
                {...controlProps}
            />
            {errorText && <ErrorText>{errorText}</ErrorText>}
        </div>
    )
}

export { Textarea }
