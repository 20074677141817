/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import styled from '@emotion/styled'
import { FunctionComponent, useState } from 'react'

const GrayBox = styled.div`
    border: 1px solid lightgray;
    width: fit-content;
    height: fit-content;
`

const MarginBox = styled.div`
    margin: 4px;
`

const ExampleContent = styled.div`
    background: #eee;
    width: 100%;
    padding: 0.5em;
    text-align: center;
`

const More: FunctionComponent<{ opened?: boolean }> = ({ children, opened = false }) => {
    const [open, setOpen] = useState(opened)
    const toggleOpen = () => setOpen(!open)
    return (
        <div>
            <span
                css={css`
                    color: lightgray;
                    cursor: pointer;
                `}
                onClick={toggleOpen}
            >
                {open ? 'less...' : 'more...'}
            </span>
            {open && <div>{children}</div>}
        </div>
    )
}

export type ExpandableSectionProps = {
    opened?: boolean
}

export { GrayBox, MarginBox, ExampleContent, More }
