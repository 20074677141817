/** @jsx jsx */
import { jsx } from '@emotion/core'
import styled from '@emotion/styled'
import { Fragment, FunctionComponent } from 'react'
import { Profile } from '../../contracts/profile/profile'
import { COLOR_PALETTE, mqMax } from '../../GlobalStyle'
import { SkillsList } from '../talent/SkillsList'

const MoreInfoRow = styled.div`
    display: flex;
    padding: 18px 0;
    border-bottom: 1px solid ${COLOR_PALETTE.grey_lighter};

    &:last-of-type {
        border: none;
    }

    ${mqMax[1]} {
        flex-direction: column;
    }
`

const MoreInfoTitle = styled.p`
    color: ${COLOR_PALETTE.grey_dark};
    flex: 1;
    margin: 0;

    ${mqMax[1]} {
        margin-bottom: 12px;
    }
`

const ItemsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex: 2;
`

type MoreInfoProps = { profile: Profile }

const MoreInfo: FunctionComponent<MoreInfoProps> = ({ profile }) => {
    const reduceArrayToString = (array: Array<string>): string => {
        return array.reduce((prev: string, curr: string) => {
            let result
            if (prev) {
                result = `${prev}, ${curr}`
            } else {
                result = `${curr}`
            }

            return result
        }, '')
    }

    return (
        <Fragment>
            <MoreInfoRow>
                <MoreInfoTitle>Top skills in Primary Role:</MoreInfoTitle>
                <ItemsContainer>
                    <SkillsList skills={profile.primaryTopSkills} />
                </ItemsContainer>
            </MoreInfoRow>
            <MoreInfoRow>
                <MoreInfoTitle>Top skills in Secondary Role:</MoreInfoTitle>
                <ItemsContainer>
                    {profile.secondaryTopSkills.length > 0 ? <SkillsList skills={profile.secondaryTopSkills} /> : '-'}
                </ItemsContainer>
            </MoreInfoRow>
            <MoreInfoRow>
                <MoreInfoTitle>Recent Roles:</MoreInfoTitle>
                <ItemsContainer>
                    {profile.recentRoles.length > 0 ? <Fragment>{reduceArrayToString(profile.recentRoles)}</Fragment> : '-'}
                </ItemsContainer>
            </MoreInfoRow>
            <MoreInfoRow>
                <MoreInfoTitle>Recent Industries:</MoreInfoTitle>
                <ItemsContainer>
                    {profile.recentIndustries.length > 0 ? <Fragment>{reduceArrayToString(profile.recentIndustries)}</Fragment> : '-'}
                </ItemsContainer>
            </MoreInfoRow>

            <MoreInfoRow>
                <MoreInfoTitle>Recent Projects:</MoreInfoTitle>
                <ItemsContainer>
                    {profile.recentProjects.length > 0 ? <Fragment>{reduceArrayToString(profile.recentProjects)}</Fragment> : '-'}
                </ItemsContainer>
            </MoreInfoRow>

            <MoreInfoRow>
                <MoreInfoTitle>Location:</MoreInfoTitle>
                <ItemsContainer>{profile.location ? <Fragment>{profile.location}</Fragment> : '-'}</ItemsContainer>
            </MoreInfoRow>
        </Fragment>
    )
}

export { MoreInfo }
