/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import styled from '@emotion/styled'
import { FunctionComponent } from 'react'
import { Specialist } from '../../contracts/search/specialist'
import { COLOR_PALETTE, mqMin } from '../../GlobalStyle'
import { toYears } from '../../utils/searchFilters'
import { TalentBoxBottomInfo } from './TalentBoxBottomInfo'
import { TalentBoxRolesInfo } from './TalentBoxRolesInfo'

export const TalentBoxLabelBlack = styled.label`
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: ${COLOR_PALETTE.black};
`

export const TalentBoxLabelGrey = styled.label`
    font-size: 14px;
    line-height: 120%;
    font-weight: normal;
    width: 100%;
    display: block;
    padding: 0 0 5px;
    color: ${COLOR_PALETTE.grey_dark};
`

const TalentBoxRow = styled.div`
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid ${COLOR_PALETTE.grey_light};
`

const TalentResultBoxContainer = styled.div`
    display: grid;
    gap: 16px;
    grid-template-columns: repeat(1, 1fr);
    ${mqMin[1]} {
        grid-template-columns: repeat(2, 1fr);
    }
    ${mqMin[2]} {
        grid-template-columns: repeat(3, 1fr);
    }
`

const formatExperience = (months: number) => {
    const years = toYears(months)
    return `${years}+ ${years >= 2 ? 'years' : 'year'}`
}

export interface TalentResultBoxProps {
    talent?: Specialist
    searchWords: Array<string>
    onClick?: () => void
    onToggleFavourite: (favorite: boolean, specialistId: string) => Promise<any>
}

const TalentResultBox: FunctionComponent<TalentResultBoxProps> = ({ talent, searchWords, onClick, onToggleFavourite }) => {
    if (!talent) {
        return null
    }
    return (
        <div
            onClick={onClick}
            css={css`
                    background-color: ${COLOR_PALETTE.white};
                    //border: 1px solid ${COLOR_PALETTE.grey_light};
                    border-radius: 2px;
                    width: 100%;
                    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
                    transition: all linear 0.3s;

                    &:hover {
                        box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
                        transition: all linear 0.3s;

                        .contact-us-button {
                            opacity: 1;
                            transition: all linear 0.3s;
                        }
                    }
                `}
        >
            <TalentBoxRolesInfo talent={talent} searchWords={searchWords} onToggleFavorite={onToggleFavourite} />
            <TalentBoxRow>
                <div
                    css={css`
                        width: 100%;
                        padding: 8px 16px 5px;
                        border-right: 1px solid ${COLOR_PALETTE.grey_light};
                    `}
                >
                    <TalentBoxLabelGrey>Experience:</TalentBoxLabelGrey>
                    <TalentBoxLabelBlack>{formatExperience(talent?.experienceInMonths)}</TalentBoxLabelBlack>
                </div>
            </TalentBoxRow>
            <TalentBoxRow>
                <div
                    css={css`
                        width: 44%;
                        padding: 12px 16px 5px;
                        border-right: 1px solid ${COLOR_PALETTE.grey_light};
                    `}
                >
                    <TalentBoxLabelGrey>{talent.nickname || '-'}</TalentBoxLabelGrey>
                </div>
                <div
                    css={css`
                        width: 54%;
                        padding: 12px 16px 5px;
                    `}
                >
                    <TalentBoxLabelGrey>{talent.tribe || '-'}</TalentBoxLabelGrey>
                </div>
            </TalentBoxRow>
            <TalentBoxBottomInfo talent={talent} searchWords={searchWords} />
        </div>
    )
}

export { TalentResultBox, TalentResultBoxContainer }
