/** @jsx jsx */
import { jsx } from '@emotion/core'
import { FunctionComponent, Fragment } from 'react'
import { Button } from '../../../components/Button'
import { EmployeeProject } from '../../../contracts/employees/employeeProject'
import { SectionHeaderWithActions } from '../shared/HeaderWithActions'
import { SectionDataNotFound, SectionWrapper } from './SectionHelpers'
import { ProjectBox } from '../../../components/employee/ProjectBox'

const projectsMock: Array<EmployeeProject> = [
    {
        id: 'asdadasdasdsadasdad',
        role: 'Senior Frontend Developer',
        from: 1596180689,
        to: 1596180689,
        project: 'SaaS for managing crop distribution',
        industry: 'SaaS for managing crop distribution',
        stack: ['Java', 'Python', 'CSS'],
        description:
            'This project will develop a module offered to level 2 Undergraduate students and will seek to develop student’s skills in collaborative working and information literacy while still advancing their discipline knowledge.',
        country: 'Poland',
        company: 'SaaS for managing crop distribution',
    },
    {
        id: 'asdasdasdasdasdasdasdddsd',
        role: 'Senior Frontend Developer',
        from: 1596180689,
        to: 1596180689,
        project: 'SaaS for managing crop distribution',
        industry: 'SaaS for managing crop distribution',
        stack: ['Java', 'Python', 'CSS'],
        description:
            'This project will develop a module offered to level 2 Undergraduate students and will seek to develop student’s skills in collaborative working and information literacy while still advancing their discipline knowledge.',
        country: 'Poland',
        company: 'SaaS for managing crop distribution',
    },
]

const ProjectsTab: FunctionComponent = () => {
    const onEditClicked = (projectId: string): void => {
        console.log('edit ', projectId)
    }

    const onDeleteClicked = (projectId: string): void => {
        console.log('delete ', projectId)
    }

    return (
        <SectionWrapper>
            <SectionHeaderWithActions header='Projects' divider />

            {projectsMock.length > 0 ? (
                <Fragment>
                    {projectsMock.map((project, index) => {
                        return (
                            <ProjectBox
                                key={project.id}
                                project={project}
                                onEditClicked={onEditClicked}
                                onDeleteClicked={onDeleteClicked}
                            />
                        )
                    })}
                </Fragment>
            ) : (
                <SectionDataNotFound description='Currently there are no Projects added.'>
                    <Button>Add Projects</Button>
                </SectionDataNotFound>
            )}
        </SectionWrapper>
    )
}

export { ProjectsTab }
