/** @jsx jsx */
import { jsx } from '@emotion/core'
import { FunctionComponent } from 'react'
import { Button } from '../../../components/Button'
import { SectionHeaderWithActions } from '../shared/HeaderWithActions'
import { SectionDataNotFound, SectionWrapper } from './SectionHelpers'

const TechnicalSkillsTab: FunctionComponent = () => {
    return (
            <SectionWrapper>
                <SectionHeaderWithActions header='Technical Skills' divider />
                <SectionDataNotFound description='Currently there are no Technical Skills added.'>
                    <Button>Add Technical Skills</Button>
                </SectionDataNotFound>
            </SectionWrapper>
    )
}

export { TechnicalSkillsTab }
