/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Fragment } from 'react'
import { Table } from '../../../components/antd'
import { Pagination } from '../../../components/inputs/Pagination'

const names = ['Foo Bar', 'Bar Baz', 'Baz Bax']

const getEntries = (count: number) => {
    const entries = []
    for (let i = 0; i < count; i++) {
        entries.push({
            key: `${i}`,
            name: names[i % 3],
            age: i * 7,
            address: `${i} Downing Street`,
        })
    }
    return entries
}

const columns = [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: 'Age',
        dataIndex: 'age',
        key: 'age',
    },
    {
        title: 'Address',
        dataIndex: 'address',
        key: 'address',
    },
]

const dataSource = getEntries(10)

const DataTables = () => {

    return (
        <Fragment>
            <Table dataSource={dataSource} columns={columns} pagination={false} />
            <Pagination pageSize={20} defaultCurrent={5} total={222} />
        </Fragment>
    )
}

export { DataTables }
