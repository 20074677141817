/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { FunctionComponent } from 'react'
import { useHistory } from 'react-router-dom'
import { HeaderBox } from './HeaderBox'

export type ApplicationMenuItemEntry = {
    path: string
    title: string
}

type ApplicationMenuProps = {
    items: Array<ApplicationMenuItemEntry>
}

const ApplicationMenu: FunctionComponent<ApplicationMenuProps> = ({ items }) => {
    const history = useHistory()
    return (
        <div
            css={css`
                display: flex;
            `}
        >
            {items.map(item => {
                const isActive = history.location.pathname === item.path
                return (
                    <HeaderBox
                        key={item.path}
                        isActive={isActive}
                        onClick={() => {
                            history.push(item.path)
                        }}
                    >
                        {isActive ? <strong>{item.title}</strong> : <span>{item.title}</span>}
                    </HeaderBox>
                )
            })}
        </div>
    )
}

export { ApplicationMenu }
