/** @jsx jsx */
import { jsx } from '@emotion/core'
import styled from '@emotion/styled'
import { Fragment, FunctionComponent, useState } from 'react'
import { Profile } from '../../contracts/profile/profile'
import { SkillMatrix } from '../SkillMatrix'
import { Tabs, TabsColor } from '../Tabs'

const TitleH6 = styled.h6`
    margin: 0 0 26px;
`

type TechnicalSkillsProps = { profile: Profile }

const TechnicalSkills: FunctionComponent<TechnicalSkillsProps> = ({ profile }) => {
    const [selectedTechnicalSkillsTab, setSelectedTechnicalSkillsTab] = useState<string>('Skill Matrix')
    const [availableTechnicalSkillsTabs] = useState<string[]>(['Skill Matrix' /* 'Skill Graph' */])

    return (
        <Fragment>
            <TitleH6>Technical Skills</TitleH6>

            <Tabs
                tabs={availableTechnicalSkillsTabs}
                onTabChange={newTab => {
                    setSelectedTechnicalSkillsTab(availableTechnicalSkillsTabs[newTab])
                }}
                mode={TabsColor.BLACK}
            />

            {selectedTechnicalSkillsTab === 'Skill Matrix' && <SkillMatrix skillMatrix={profile.skillMatrix} />}
            {/* {selectedTechnicalSkillsTab === 'Skill Graph' && <p>Skill Graph comming soon!</p>} */}
        </Fragment>
    )
}

export { TechnicalSkills }
