/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import styled from '@emotion/styled'
import { Fragment, FunctionComponent, useEffect, useState } from 'react'
import { addSpecialistProfileToFavorites, removeSpecialistProfileFromFavorites } from '../../api/api'
import shareIcon from '../../assets/icons/share.svg'
import { getAppPath } from '../../contracts/applications'
import { Profile } from '../../contracts/profile/profile'
import { COLOR_PALETTE, mqMax } from '../../GlobalStyle'
import { convertTimestampToDateString } from '../../utils/dates'
import { BackLink } from '../BackLink'
import { TalentBadge } from '../Badge'
import { Button } from '../Button'
import { Icon, IconButton } from '../Icon'
import { FlexBox } from '../layout/FlexBoxHelpers'
import { Logo } from '../Logo'
import { useNotifications } from '../notification/NotificationProvider'
import { GreyParagraph } from '../shared/shared-styles'

const ProfileNameRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0 24px;

    ${mqMax[1]} {
        flex-direction: column;
        align-items: flex-start;
    }
`

const GeneralProfileDataContainer = styled.div`
    display: flex;
    background-color: ${COLOR_PALETTE.white};
    padding: 25px 30px;
    border: 1px solid ${COLOR_PALETTE.grey_light};
    border-radius: 2px;

    ${mqMax[1]} {
        flex-direction: column;
    }
`

const RolesContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`

const RolesTitle = styled.small`
    color: ${COLOR_PALETTE.grey};
    margin-bottom: 12px;
`

const GreySmall = styled.small`
    color: ${COLOR_PALETTE.grey};
`

const PrimaryRole = styled.h6`
    margin: 0 6px 0 0;
    margin-right: 6px;
`

const SecondaryRole = styled.p`
    margin: 0;
    margin-right: 6px;
`

const GeneralData = styled.div`
    flex: 2;
    margin-top: 20px;
`

const GeneralDataBlock = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 114px;
    justify-content: center;
    border-right: 1px solid ${COLOR_PALETTE.grey_light};
    padding-right: 24px;

    ${mqMax[1]} {
        flex-direction: row;
        align-items: center;
        border-top: 1px solid ${COLOR_PALETTE.grey_light};
        border-right: none;
        max-width: none;
        padding-top: 18px;
        justify-content: flex-start;

        & > * {
            flex: 1;
        }
    }
`

const FlexContainer = styled.div`
    display: flex;
    align-items: center;
    margin: 4px 0;

    ${mqMax[1]} {
        margin: 12px 0;
    }
`

const RoleContainer = styled.div`
    display: flex;
    align-items: center;
    margin: 4px 0;
`

const TitleH3 = styled.h3`
    margin: 0 16px 0 0;
`

const ZeroMarginH6 = styled.h6`
    margin: 0;
`

type ProfileHeaderProps = {
    profile: Profile
    setShareModal: (show: boolean) => void
    setContactModal: (show: boolean) => void
    isPublic?: boolean
    setLoading?: (val: boolean) => void
}

const ProfileHeader: FunctionComponent<ProfileHeaderProps> = ({ profile, setShareModal, setContactModal, isPublic, setLoading }) => {
    const { addSuccess } = useNotifications()
    const [favorite, setFavorite] = useState<boolean>(profile.favorite)
    const [contactDate, setContactDate] = useState<string>()

    const toggleFavorite = () => {
        if (setLoading) {
            setLoading(true)
        }

        if (favorite) {
            removeSpecialistProfileFromFavorites(profile.specialistId).then(() => {
                setFavorite(false)
                addSuccess('Specialist removed from Bookmarked Talent successfully.')

                if (setLoading) {
                    setLoading(false)
                }
            })
        } else {
            addSpecialistProfileToFavorites(profile.specialistId).then(() => {
                setFavorite(true)
                addSuccess('Specialist added to Bookmarked Talent successfully.')

                if (setLoading) {
                    setLoading(false)
                }
            })
        }
    }

    const shareProfile = () => {
        setShareModal(true)
    }

    const contactSales = () => {
        setContactModal(true)
    }

    useEffect(() => {
        if (profile?.contactedAt) {
            setContactDate(convertTimestampToDateString(profile?.contactedAt, 'dd.MM.yyyy'))
        }
    }, [profile])

    return (
        <Fragment>
            {isPublic ? (
                <Logo type='logoFull' />
            ) : (
                <BackLink
                    path={getAppPath('VIRTUAL_BENCH')}
                    text='Back to List'
                    style={css`
                        display: flex;
                        width: 100px;
                    `}
                />
            )}
            <ProfileNameRow>
                <FlexContainer>
                    <TitleH3>{profile.nickname}</TitleH3>
                    {!isPublic && (
                        <IconButton
                            onClick={toggleFavorite}
                            size={32}
                            name='bookmark'
                            title='Favorite'
                            variant='outlined'
                            pressed={favorite}
                        />
                    )}
                </FlexContainer>
                {!isPublic && (
                    <FlexContainer>
                        <Button
                            variant='link'
                            icon={shareIcon}
                            onClick={shareProfile}
                            style={css`
                                min-width: 150px;
                                justify-content: center;
                            `}
                        >
                            Share Profile
                        </Button>
                        {contactDate && (
                            <FlexBox
                                alignItems='center'
                                css={css`
                                    min-width: 170px;
                                    margin-right: 24px;
                                `}
                            >
                                <Icon name='flag'></Icon>
                                <GreyParagraph
                                    css={css`
                                        margin: 0 0 0 10px;
                                    `}
                                >
                                    Booked on: {contactDate}
                                </GreyParagraph>
                            </FlexBox>
                        )}
                        <Button
                            onClick={contactSales}
                            style={css`
                                min-width: 150px;
                            `}
                        >
                            Book Now
                        </Button>
                    </FlexContainer>
                )}
            </ProfileNameRow>

            <GeneralProfileDataContainer>
                <RolesContainer>
                    <RolesTitle>Current Roles:</RolesTitle>
                    <RoleContainer>
                        <PrimaryRole>{profile.role}</PrimaryRole>
                        <TalentBadge>{profile.seniority}</TalentBadge>
                    </RoleContainer>

                    {profile.secondaryRole && (
                        <RoleContainer>
                            <SecondaryRole>{profile.secondaryRole}</SecondaryRole>
                            <TalentBadge>{profile.secondarySeniority}</TalentBadge>
                        </RoleContainer>
                    )}
                </RolesContainer>

                <GeneralData>
                    {profile.experience && (
                        <GeneralDataBlock>
                            <GreySmall>Experience: </GreySmall>
                            <ZeroMarginH6>{profile.experience}</ZeroMarginH6>
                        </GeneralDataBlock>
                    )}
                </GeneralData>
            </GeneralProfileDataContainer>
        </Fragment>
    )
}

export { ProfileHeader }
