/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Fragment } from 'react'
import { COLOR_PALETTE } from '../../GlobalStyle'
import { distinctFilter } from '../../utils/filters'
import { TextHighlight } from '../TextHighlight'

interface SkillsListProps {
    skills: Array<string>
    searchWords?: Array<string>
}

const SkillsList = ({ skills, searchWords = [] }: SkillsListProps) => {
    return (
        <Fragment>
            {skills &&
            skills.filter(distinctFilter).map((skill, index, list) => (
                <span
                    key={`${skill}-${index}`}
                    css={css`
                            break-word: avoid;
                        `}
                >
                        <span
                            css={css`
                                color: ${COLOR_PALETTE.violet_darker};
                                line-height: 20px;
                            `}
                        >
                            <TextHighlight searchWords={searchWords} text={skill} />
                        </span>
                    {index !== list.length - 1 && (
                        <span
                            css={css`
                                    color: ${COLOR_PALETTE.grey};
                                    margin: 0 10px;
                                    display: inline-block;
                                    font-size: 10px;
                                    text-align: center;
                                `}
                        >
                                &#9679;
                            </span>
                    )}
                    </span>
            ))}
        </Fragment>
    )
}

export { SkillsList }
