/** @jsx jsx */
import { jsx } from '@emotion/core'
import Highlighter from 'react-highlight-words'
import { COLOR_PALETTE } from '../GlobalStyle'

interface TextHighlightProps {
    searchWords: Array<string>
    text: string
}

const highlightStyle = {
    backgroundColor: COLOR_PALETTE.yellow_light,
    borderRadius: 2,
}

const TextHighlight = ({ searchWords, text }: TextHighlightProps) => (
    <Highlighter highlightStyle={highlightStyle} searchWords={searchWords} textToHighlight={text || ''} autoEscape={true} />
)

export { TextHighlight }
