/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Link } from 'react-router-dom'
import { BackLink } from '../../components/BackLink'
import { Button } from '../../components/Button'
import { ResponsiveWrapper } from '../../components/layout/ResponsiveWrapper'

const RecoverPasswordEmailSent = () => {
    return (
        <ResponsiveWrapper withLogo>
            <BackLink path='/' text='Back to Login' />
            <h1>E-mail sent</h1>
            <p>Recovery e-mail has been sent so you can create new password.</p>
            <Link to='/'>
                <Button>Login</Button>
            </Link>
        </ResponsiveWrapper>
    )
}

export { RecoverPasswordEmailSent }
