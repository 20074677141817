import { useContext, useEffect } from 'react'
import { getCountries, getSpecialistRoles } from '../api/api'
import { ReduxContext } from './Store'

const useSpecialistRoles = () => {
    const {
        actions: { permanentDataSet },
        selectors: {
            permanentData: { specialistRoles },
        },
    } = useContext(ReduxContext)
    useEffect(() => {
        if (specialistRoles === null) {
            getSpecialistRoles()
                .then(data => {
                    permanentDataSet({ name: 'specialistRoles', data })
                })
                .finally()
        }
    }, [permanentDataSet, specialistRoles])

    return specialistRoles
}

const useCountries = () => {
    const {
        actions: { permanentDataSet },
        selectors: {
            permanentData: { countries },
        },
    } = useContext(ReduxContext)
    useEffect(() => {
        if (countries === null) {
            getCountries()
                .then(data => {
                    permanentDataSet({ name: 'countries', data })
                })
                .finally()
        }
    }, [permanentDataSet, countries])

    return countries
}

export { useSpecialistRoles, useCountries }
