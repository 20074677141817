/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Fragment, FunctionComponent } from 'react'
import { Divider } from '../../../components/Divider'
import { FlexBox } from '../../../components/layout/FlexBoxHelpers'
import { Tooltip, TooltipIcon } from '../../../components/Tooltip'
import { mqMin } from '../../../GlobalStyle'

type HeaderWithActionsProps = {
    header: string
}

const HeaderWithActions: FunctionComponent<HeaderWithActionsProps> = ({ header, children }) => (
    <FlexBox
        justifyContent='space-between'
        alignItems='flex-start'
        css={css`
            margin-bottom: 16px;
            flex-direction: column;
            ${mqMin[1]} {
                flex-direction: row;
                align-items: center;
            }
        `}
    >
        <h4
            css={css`
                margin-bottom: 16px;
            `}
        >
            {header}
        </h4>
        <div
            css={css`
                margin-bottom: 16px;
                flex-shrink: 0;
            `}
        >
            {children}
        </div>
    </FlexBox>
)

type SectionHeaderWithActionsProps = HeaderWithActionsProps & {
    divider?: boolean
    tooltip?: string
}

const SectionHeaderWithActions: FunctionComponent<SectionHeaderWithActionsProps> = ({ header, divider, tooltip, children }) => {
    return (
        <Fragment>
            <FlexBox justifyContent='space-between'>
                <h6
                    css={css`
                        margin-bottom: 0px;
                        line-height: 32px;
                    `}
                >
                    {header}
                    {tooltip && (
                        <Tooltip content={tooltip}>
                            <TooltipIcon>?</TooltipIcon>
                        </Tooltip>
                    )}
                </h6>
                <div>{children}</div>
            </FlexBox>
            {divider && <Divider />}
        </Fragment>
    )
}

export { HeaderWithActions, SectionHeaderWithActions }
