import React, { FunctionComponent, ReactNode, useMemo } from 'react'
import {
    AddToast,
    AppearanceTypes,
    Options,
    RemoveAllToasts,
    RemoveToast,
    ToastProvider,
    UpdateToast,
    useToasts,
} from 'react-toast-notifications'
import { Notification, NotificationContent } from './Notification'

const NotificationProvider: FunctionComponent = ({ children }) => (
    <ToastProvider placement='top-center' components={{ Toast: Notification }}>
        {children}
    </ToastProvider>
)

type ExtendedAddToastOptions = Omit<Options, 'appearance'> & {
    appearance?: AppearanceTypes
    actionText?: string
    onActionClick?: () => void
}

type ExtendedAddToast = (content: ReactNode, options?: ExtendedAddToastOptions, callback?: (id: string) => void) => void

type AddToastCreator = (addToast: AddToast, appearance: AppearanceTypes) => ExtendedAddToast

const createAddToast: AddToastCreator = (addToast, appearance) => (content, options, callback) => {
    const { actionText, onActionClick, ...addToastOptions } = options || {}

    const finalContent = (
        <NotificationContent actionText={actionText} onActionClick={onActionClick} appearance={appearance} children={content} />
    )
    const contentLength = typeof content === 'string' ? content.length : 42
    const finalContentLength = contentLength + (actionText?.length || 0)
    const timeout = Math.min(Math.max(finalContentLength * 50, 4000), 10000)
    const finalOptions = { autoDismissTimeout: timeout, autoDismiss: true, ...addToastOptions }
    return addToast(finalContent, { appearance, ...finalOptions }, callback)
}

type UseNotificationsHook = () => {
    addNotification: ExtendedAddToast
    addError: ExtendedAddToast
    addInfo: ExtendedAddToast
    addWarning: ExtendedAddToast
    addSuccess: ExtendedAddToast
    removeNotification: RemoveToast
    removeAllNotifications: RemoveAllToasts
    notificationStack: Array<{
        content: ReactNode
        id: string
        appearance: AppearanceTypes
    }>
    updateNotification: UpdateToast
}

const useNotifications: UseNotificationsHook = () => {
    // fix for unspecified appearance bg error, and facade notifications
    const { addToast, removeToast, removeAllToasts, updateToast, toastStack } = useToasts()

    const methods = useMemo(() => {
        return {
            addNotification: createAddToast(addToast, 'info'),
            addError: createAddToast(addToast, 'error'),
            addInfo: createAddToast(addToast, 'info'),
            addWarning: createAddToast(addToast, 'warning'),
            addSuccess: createAddToast(addToast, 'success'),
            removeNotification: removeToast,
            removeAllNotifications: removeAllToasts,
            updateNotification: updateToast,
        }
    }, [addToast, removeAllToasts, removeToast, updateToast])
    return useMemo(() => {
        return {
            ...methods,
            notificationStack: toastStack,
        }
    }, [methods, toastStack])
}

export { NotificationProvider, useNotifications }
